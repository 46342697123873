import React, { useState, useEffect } from "react";
import email from "../assets/images/email.svg";
import "../assets/css/common.scss";
import { useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useParentServices from "../hooks/web-services-hooks/useParentServices";
import { IJobSummryModel } from "../models/IJobSummaryModel";
import moment from "moment";

const JobSummarySheet = () => {
  const params = useParams();
  const { auth } = useAuth();
  const ParentServices = useParentServices();

  const [details, setDetails] = useState<IJobSummryModel>({
    homeAddress: "",
    homeCity: "",
    homeZipCode: "",
    startDate: null,
    endDate: null,
    startTime: "",
    endTime: "",
    note: "",
    terms: false,
    kids: [{ gender: "", age: 0 }],
    userId: {
      _id: "",
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    ParentServices.jobDetails(params.id).then((res: any) => {
      setDetails(res.data[0]);
    });
  }, []);

  return (
    <>
      <div className="box-outside-view">
        <div className="container bg-white pb-5 px-md-5 rounded padding-top-56">
          <p className="page-subtitle text-center text-uppercase mt-3 mb-2">
            JOB INFORMATION
          </p>
          <p className="page-sub-desc  col-xl-6 col-lg-9 col-12 px-xl-4 px-lg-3 px-md-2 px-0 mx-auto job-desc">
            Below are the details about the Babysitting Job
          </p>

          <form className="row py-3 my-3 px-lg-5 mx-auto">
            <>
              <div className="col-md-6 my-1">
                <div className="form-group">
                  <label className="form-label">
                    Family Looking For Babysitter
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={details.userId?.lastName}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="form-group">
                  <label className="form-label">
                    City Babysitting Job is in
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={details.homeCity}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="form-group">
                  <label className="form-label">Start Date</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Start Date"
                    // value={details.startDate?.toString().split("T")[0]}
                    value={moment(details.startDate).format("MM-DD-YYYY")}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="form-group">
                  <label className="form-label">Start Time</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Start Time"
                    value={
                      moment(details.startTime).isValid()
                        ? moment(details.startTime).format("hh:mm A")
                        : details.startTime
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="form-group">
                  <label className="form-label">End Date</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="End Date"
                    // value={details.endDate?.toString().split("T")[0]}
                    value={moment(details.endDate).format("MM-DD-YYYY")}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 my-1">
                <div className="form-group">
                  <label className="form-label">End Time</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="End Time"
                    value={
                      moment(details.endTime).isValid()
                        ? moment(details.endTime).format("hh:mm A")
                        : details.endTime
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="col-12 my-md-3 my-1 px-sm-2 px-0">
                <div className="col-md-9 col-12 my-2 mx-sm-auto mx-0 d-flex">
                  <div className="col-3"></div>
                  <div className="col-4 me-2 ms-0 dark-word">Girl or Boy</div>
                  <div className="col-4 mx-2  dark-word">Age</div>
                </div>
                {details.kids.map((kidsVal, index) => (
                  <>
                    <div className="col-md-9 col-12 my-2 mx-sm-auto mx-0 d-flex px-0">
                      <div className="col-3 d-flex justify-content-center  dark-word">
                        Kid #{index + 1}
                      </div>
                      <div className="col-4 me-2 ms-0">
                        <div className="form-group">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Girl or Boy"
                            value={kidsVal.gender}
                            readOnly
                          />
                        </div>
                        <div className="d-none">
                          <p className="error-msg m-0"> error msg</p>
                        </div>
                      </div>
                      <div className="col-4 mx-md-1">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Age"
                            value={kidsVal.age}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="col-md-9 col-12 mx-auto d-flex justify-content-center text-center dark-word">
                Additional Information from the Parent
              </div>
              <div className="col-md-10 col-12 mx-sm-auto mt-2 justify-content-center text-center">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={5}
                    value={details.note}
                    readOnly
                  ></textarea>
                </div>
              </div>
            </>
          </form>
        </div>
      </div>
    </>
  );
};

export default JobSummarySheet;
