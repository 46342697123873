import OurStory from "./components/OurStory";
import Home from "./components/Home";
import Register from "./views/BSRegistration";
import "./assets/css/common.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Login from "./views/Login";
import _404 from "./views/404";
import Layout from "./components/Layout";
import PersistLogin from "./components/PersistLogin";
import Forgotpassword from "./components/ForgotPassword";
import TermsAndConditions from "./views/Terms-Conditions";
import ContactUs from "./views/ContactUs";
import JobSummarySheet from "./views/JobSummarySheet";
import Profile from "./views/Profile";
import PreferencesForm from "./components/Parentregistration/PreferencesForm";
import ResetPassword from "./views/ResetPassword";
import ParentRegistration from "./views/ParentRegistration";
import HowItWorks from "./components/HowItWorks";
import InterestedBabySitterList from "./components/InterestedBabySitterList";
import PostJobs from "./components/Parentregistration/JobInfoForm";
import EditProfile from "./views/EditProfile";
import AboutBabySitter from "./views/AboutBabySitter";
import EditGuardianInfo from "./views/EditGuardianInfo";
import Jobs from "./views/Jobs";
import Payment from "./components/Payment";
import ChangeSubscription from "./components/ChangeSubscription";
import RequireAuth from "./components/RequireAuth";
import LogoutOnlyRoutes from "./components/LogoutOnlyRoutes";
import ChatPage from "./views/ChatPage";
import ConfirmAccount from "./views/ConfirmAccount";
import VerificationPending from "./views/VerificationPending";
import HowitWorksVideo from "./components/HowitWorksVideo";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/about" element={<OurStory />} />
        <Route path="/work" element={<HowitWorksVideo/>}/>
        {/* <Route
          path="/how-it-works"
          element={<HowItWorks isStandALone={true} />}
        /> */}
        <Route element={<LogoutOnlyRoutes />}>
          <Route path="/register" element={<Register />} />
          <Route path="/parent-register" element={<ParentRegistration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Route>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/confirm-email/:token" element={<ConfirmAccount />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* Protected Routes For BabySitter*/}
        <Route element={<PersistLogin />}>
          <Route path="/" element={<Home />} />
          <Route path="/pending-verification" element={<VerificationPending />} />
          <Route element={<RequireAuth />}>
            <Route
              path="/post-a-job"
              element={<PostJobs userId="" handleStepChange={() => 3} />}
            />
            <Route
              path="/interested-sitters"
              element={<InterestedBabySitterList />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/job-summary/:id" element={<JobSummarySheet />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/about-me" element={<AboutBabySitter />} />
            <Route path="/edit-guardian-info" element={<EditGuardianInfo />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/payment" element={<Payment />} />
            <Route
              path="/manage-subscription"
              element={<ChangeSubscription />}
            />
            <Route path="/message-center" element={<ChatPage />} />
            <Route
              path="/edit-preferences"
              element={
                <PreferencesForm
                  userId=""
                  handleStepChange={() => {}}
                  edit={true}
                />
              }
            />
          </Route>
        </Route>

        {/* 404 */}
        <Route path="*" element={<_404 />} />
      </Route>
    </Routes>
  );
}

export default App;
