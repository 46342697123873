import { svcconfig, fileuploadconfig } from "../../helpers/api-services";
import { MAX_RESULTS_PER_PAGE } from "../../helpers/constants";
import useAuth from "../useAuth";
import useAxiosPrivate from "../useAxiosPrivate";

const BABY_SITTER_URL = process.env.REACT_APP_API_URL + "/sitter";
const JOB_URL = process.env.REACT_APP_API_URL + "/jobinfo";
const JOB_ACTION_URL = process.env.REACT_APP_API_URL + "/jobapplication";

const useBabySitterServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const getBabySitterAboutInfo = (id: string) => {
    const apiURL = `${BABY_SITTER_URL}/getsitteraboutinfo/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const updateBabySitterAboutInfo = (aboutData: Object) => {
    const apiURL = `${BABY_SITTER_URL}/editaboutInfo/${auth._id}`;
    return axiosPrivate.patch(apiURL, aboutData, {
      headers: fileuploadconfig(auth.token),
    });
  };

  const getAvailableJobs = () => {
    const apiURL = `${JOB_URL}/availableJobs/${auth._id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const getAppliedJobs = (page: number) => {
    const apiURL = `${JOB_URL}/appliedJobs/${auth._id}?page=${page}&limit=${MAX_RESULTS_PER_PAGE}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const getHiredJobs = (page: number) => {
    const apiURL = `${JOB_URL}/hiredJobs/${auth._id}?page=${page}&limit=${MAX_RESULTS_PER_PAGE}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const applyForJob = (data: object) => {
    const apiURL = `${JOB_ACTION_URL}/apply`;
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const parentServices = {
    applyForJob,
    getBabySitterAboutInfo,
    updateBabySitterAboutInfo,
    getAvailableJobs,
    getAppliedJobs,
    getHiredJobs,
  };

  return parentServices;
};

export default useBabySitterServices;
