import React, { useEffect, useRef, useState } from "react";
import Upload from "../../assets/images/file-upload.svg";
import { IAboutBabySitter } from "../../models/IBSRegistrationModel";
import CrossIcon from "../../assets/images/cross-icon.svg";
import AlertToasts from "../../services/notification-services/notifications";
import "../../assets/css/filedragdrop.scss";
import {
  encryptData,
  handleErrorMessage,
  updateErrorFields,
} from "../../helpers/utilities";
import useRegistrationServices from "../../hooks/web-services-hooks/useRegistrationServices";
import useAuth from "../../hooks/useAuth";
import useBabySitterServices from "../../hooks/web-services-hooks/useBabySitterServices";
import { useNavigate } from "react-router-dom";

type Props = {
  userId: string;
  handleStepChange: Function;
  edit?: boolean;
};

const AboutForm = ({ handleStepChange, userId, edit }: Props) => {
  const [aboutBabySitter, setAboutBabySitter] = useState<IAboutBabySitter>({
    babySittingTraining: null,
    chargeForAdditionalKid: "",
    chargeForOneKid: "",
    firstAidTraining: null,
    hasDrivingLicense: null,
    howGetToBabySitting: "",
    profilePicture: null,
    profileDescription: "",
    step: 2,
    travelTobabysit: "",
    unweightedGpa: "",
    userId,
  });
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const { auth } = useAuth();

  const inputRef: any = useRef(null);

  const RegistrationServices = useRegistrationServices();
  const BabySitterServices = useBabySitterServices();

  const navigate = useNavigate();

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImage({
        preview: URL.createObjectURL(e.dataTransfer.files[0]),
        raw: e.dataTransfer.files[0].name,
      });
      setAboutBabySitter({
        ...aboutBabySitter,
        profilePicture: e.dataTransfer.files[0],
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    updateErrorFields(invalidFields, setInvalidFields, "profilePicture");
    if (e.target.files && e.target.files[0]) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0].name,
      });
      setAboutBabySitter({
        ...aboutBabySitter,
        profilePicture: e.target.files[0],
      });
    }
  };

  const getBabySitterInfo = async () => {
    try {
      const response = await BabySitterServices.getBabySitterAboutInfo(
        auth._id
      );
      if (response.data.success) {
        delete response.data.data["__v"];
        setAboutBabySitter({...response.data.data, unweightedGpa: response.data.data.unweightedGpa.toFixed(1)});
        setImage({
          ...image,
          preview: response.data.data.profilePicture,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const submitStep2 = () => {
    setLoader(true);
    if (edit) {
      BabySitterServices.updateBabySitterAboutInfo(aboutBabySitter)
        .then((res) => {
          if (res.data.success) {
            AlertToasts.success("Information updated successfully");
            if (aboutBabySitter.profilePicture) {
              if (typeof aboutBabySitter.profilePicture == "string") {
                localStorage.setItem(
                  "profileImage",
                  encryptData(aboutBabySitter.profilePicture)
                );
              } else {
                localStorage.setItem(
                  "profileImage",
                  encryptData(
                    URL.createObjectURL(aboutBabySitter.profilePicture)
                  )
                );
              }
              navigate("/");
            }
          }
        })
        .catch((e) => {
          handleErrorMessage(e);
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      RegistrationServices.babySitterStep2Submit(aboutBabySitter)
        .then((res) => {
          if (res.status === 200) {
            handleStepChange(2);
            // sessionStorage.setItem(
            //   "aboutBabySitter",
            //   JSON.stringify(aboutBabySitter)
            // );
          }
        })
        .catch((e) => {
          handleErrorMessage(e);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const formValidation = () => {
    let validationResult: boolean = true;
    let step2InvalidFields: string[] = [];
    if (aboutBabySitter) {
      Object.keys(aboutBabySitter).forEach((key: string) => {
        if (
          Object.hasOwn(aboutBabySitter, key) &&
          (aboutBabySitter[key as keyof typeof aboutBabySitter] === "" ||
            aboutBabySitter[key as keyof typeof aboutBabySitter] === null )
        ) {
          validationResult = false;
          step2InvalidFields.push(key);
        }
      });
    }
    setInvalidFields(step2InvalidFields);
    if (validationResult) {
      submitStep2();
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (auth._id) {
      getBabySitterInfo();
    } 
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className={edit ? "container bg-white pb-5 px-md-5 rounded pt-5" : ""}
      >
        <div className="row text-center mt-5">
          <div className="col-12">
            <h1 className="form-title">
              {edit ? "Edit About You" : "About You"}
            </h1>
          </div>
          <div className="col-12 text-center mb-3">
            <p className="page-discription p-0 m-0">
              The first thing we need to do is get you registered as a
            </p>
            <p className="page-discription p-0 m-0">babysitter in the area.</p>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 mx-auto">
            <div className="row g-3">
              <div className=" col-12 col-lg-6 form-group">
                <label className="form-label">
                  Do you have any Babysitter Training Certificates? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  value={
                    aboutBabySitter.babySittingTraining === null
                      ? ""
                      : aboutBabySitter.babySittingTraining
                      ? 1
                      : 0
                  }
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      babySittingTraining: parseInt(e.target.value) > 0,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "babySittingTraining"
                    );
                  }}
                  autoComplete="off"
                >
                  <option value={""} selected disabled>
                    Select Option
                  </option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("babySittingTraining")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  Do You Have First Aid Training? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      firstAidTraining: parseInt(e.target.value) > 0,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "firstAidTraining"
                    );
                  }}
                  value={
                    aboutBabySitter.firstAidTraining === null
                      ? ""
                      : aboutBabySitter.firstAidTraining
                      ? 1
                      : 0
                  }
                  autoComplete="off"
                >
                  <option value={""} selected disabled>
                    Select Option
                  </option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("firstAidTraining") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6  form-group">
                <label className="form-label">
                  How Will You Get To The Babysitting Jobs? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      howGetToBabySitting: e.target.value,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "howGetToBabySitting"
                    );
                  }}
                  value={aboutBabySitter.howGetToBabySitting}
                  autoComplete="off"
                >
                  <option selected disabled value={""}>
                    Select Option
                  </option>
                  <option value="I will drive myself">
                    I will drive myself
                  </option>
                  <option value="My parents will drive me">
                    My parents will drive me
                  </option>
                  <option value="I need the parents I'm babysitting for to pick me up and drop me off">
                    I need the parents I’m babysitting for to pick me up and
                    drop me off
                  </option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("howGetToBabySitting")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  What’s Your Unweighted GPA? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      unweightedGpa: parseFloat(e.target.value).toFixed(1),
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "unweightedGpa"
                    );
                  }}
                  value={aboutBabySitter.unweightedGpa}
                  autoComplete="off"
                >
                  <option selected disabled value={""}>
                    Select Option
                  </option>
                  <option value="4.0">4.0/4.0</option>
                  <option value="3.9">3.9/4.0</option>
                  <option value="3.8">3.8/4.0</option>
                  <option value="3.7">3.7/4.0</option>
                  <option value="3.6">3.6/4.0</option>
                  <option value="3.5">3.5/4.0</option>
                  <option value="3.4">3.4/4.0</option>
                  <option value="3.3">3.3/4.0</option>
                  <option value="3.2">3.2/4.0</option>
                  <option value="3.1">3.1/4.0</option>
                  <option value="3.0">3.0/4.0</option>
                  <option value="2.9">2.9/4.0</option>
                  <option value="2.8">2.8/4.0</option>
                  <option value="2.7">2.7/4.0</option>
                  <option value="2.6">2.6/4.0</option>
                  <option value="2.5">2.5/4.0</option>
                  <option value="2.4">2.4/4.0</option>
                  <option value="2.3">2.3/4.0</option>
                  <option value="2.2">2.2/4.0</option>
                  <option value="2.1">2.1/4.0</option>
                  <option value="2.0">2.0/4.0</option>
                  <option value="1.9"> Below 2.0/4.0</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("unweightedGpa") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  Do you have a driver's license? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      hasDrivingLicense: parseInt(e.target.value) > 0,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "hasDrivingLicense"
                    );
                  }}
                  value={
                    aboutBabySitter.hasDrivingLicense == null
                      ? ""
                      : aboutBabySitter.hasDrivingLicense
                      ? 1
                      : 0
                  }
                  autoComplete="off"
                >
                  <option selected disabled value="">
                    Select Option
                  </option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("hasDrivingLicense") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  How far will you travel to babysit? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      travelTobabysit: parseInt(e.target.value),
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "travelTobabysit"
                    );
                  }}
                  value={aboutBabySitter.travelTobabysit}
                  autoComplete="off"
                >
                  <option selected disabled value={""}>
                    Select Option
                  </option>
                  <option value="5">5 Miles</option>
                  <option value="10">10 Miles</option>
                  <option value="15">15 Miles</option>
                  <option value="20">20 Miles</option>
                  <option value="30">30 Miles</option>
                  <option value="40">40 Miles</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("travelTobabysit") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  How much do you charge per hour for one kid? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      chargeForOneKid: parseInt(e.target.value),
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "chargeForOneKid"
                    );
                  }}
                  value={aboutBabySitter.chargeForOneKid}
                  autoComplete="off"
                >
                  <option selected disabled value="">
                    Select Option
                  </option>
                  <option value="8">$8 per hour</option>
                  <option value="10">$10 per hour</option>
                  <option value="12">$12 per hour</option>
                  <option value="15">$15 per hour</option>
                  <option value="18">$18 per hour</option>
                  <option value="20">$20 per hour</option>
                  <option value="25">$25 per hour</option>
                  <option value="30">$30 per hour</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("chargeForOneKid") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  How much do you charge for each additional kid? *
                </label>
                <select
                  id="inputState"
                  className="form-select"
                  onChange={(e) => {
                    setAboutBabySitter({
                      ...aboutBabySitter,
                      chargeForAdditionalKid: parseInt(e.target.value),
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "chargeForAdditionalKid"
                    );
                  }}
                  value={aboutBabySitter.chargeForAdditionalKid}
                  autoComplete="off"
                >
                  <option selected disabled value="">
                    Select Option
                  </option>
                  <option value="0">$0 per hour</option>
                  <option value="2">$2 per hour</option>
                  <option value="4">$4 per hour</option>
                  <option value="6">$6 per hour</option>
                  <option value="8">$8 per hour</option>
                  <option value="10">$10 per hour</option>
                  <option value="15">$15 per hour</option>
                  <option value="18">$18 per hour</option>
                  <option value="20">$20 per hour</option>
                  <option value="25">$25 per hour</option>
                  <option value="30">$30 per hour</option>
                </select>
                <div
                  className={`${
                    invalidFields.includes("chargeForAdditionalKid")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
              <div className="col-md-11 form-group mx-auto">
                <label className="form-label">
                  Tell parents a little about you and why they should pick you
                  to babysit their kids *
                </label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={aboutBabySitter.profileDescription}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (input.length <= 200) {
                      setAboutBabySitter({
                        ...aboutBabySitter,
                        profileDescription: e.target.value,
                      });
                    }
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "profileDescription"
                    );
                  }}
                  autoComplete="off"
                ></textarea>
                <div
                  className={`${
                    invalidFields.includes("profileDescription") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> {`This Field is required`}</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5 mb-1 font-style font-16 fw-600">
              Upload Profile Picture *
            </div>
            <form
              id="profile-picture-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              className="col-12 col-md-7 col-lg-4 mx-auto text-center mb-5 "
            >
              {/* <span>Upload Profile Picture</span> */}
              <input
                accept="image/*"
                ref={inputRef}
                type="file"
                value=""
                id="input-file-upload"
                // multiple={true}
                onChange={handleChange}
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={dragActive ? "drag-active" : ""}
              >
                {image.preview ? (
                  <>
                    <div className="p-1 preview-view position-relative">
                      <div className="p-1 cross-pos">
                        <div
                          className="cross-bg"
                          onClick={() => {
                            setAboutBabySitter({
                              ...aboutBabySitter,
                              profilePicture: null,
                            });
                            URL.revokeObjectURL(image.preview);
                            setImage({ ...image, preview: "", raw: "" });
                          }}
                        >
                          <img src={CrossIcon} className="p-1 cross-style" />
                        </div>
                      </div>
                      <img
                        src={image.preview}
                        alt="dummy"
                        className="preview-img"
                      />
                    </div>
                  </>
                ) : (
                  <div className="p-5">
                    <img src={Upload} />
                    <div>
                      <span className="img-upload-text font-theme fw-500">
                        Click to upload
                      </span>
                      <span className="img-drag-drop-text ms-1">or</span>
                      <span className="img-drag-drop-text">
                        {" "}
                        drag and drop{" "}
                      </span>
                      <p className="img-drag-drop-text m-0 text-uppercase">
                        Svg ,Png, Jpg{" "}
                        <span className="text-lowercase">or </span>GIF
                      </p>
                      <span className="img-drag-drop-text">(max.800*400)</span>
                    </div>
                  </div>
                )}
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
              {image.raw ? (
                <>
                  <p className="img-upload-text">{image.raw}</p>
                </>
              ) : (
                <></>
              )}
              <div
                className={`${
                  invalidFields.includes("profilePicture")
                    ? "text-center my-3"
                    : "d-none"
                }`}
              >
                <p className="error-msg m-0"> {`This Field is required`}</p>
              </div>
            </form>
            {/* <div
            className={`${
              invalidFields.includes("profilePicture") ? "" : "d-none"
            }`}
          >
            <p className="error-msg m-0"> {`This Field is required`}</p>
          </div> */}

            <div className="d-flex justify-content-center mt-2">
              <button
                className={`btn btn-theme mt-4 mb-2  ${
                  loader ? "btn-payment-loader disabled" : ""
                }`}
                onClick={formValidation}
              >
                {loader ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>{edit ? "Update" : "Next"}</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutForm;
