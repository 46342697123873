import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../assets/css/auth.scss";
import "../assets/css/auth.scss";
import { handleErrorMessage, validateEmail } from "../helpers/utilities";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import AlertToasts from "../services/notification-services/notifications";
import ResetPasswordmodal from "./Resetpasswordmodal";

const Forgotpassword = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const authServices = useAuthServices();
  const location = useLocation();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>(
    location.state?.error || ""
  );

  const handleReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      emailRef.current?.value.trim().length &&
      validateEmail(emailRef.current?.value.trim())
    ) {
      try {
        const response = await authServices.forgotPassword(
          emailRef.current.value
        );
        setValidationMessage("");
        if (response.data.success) {
          setShowConfirmation(true);
        } else {
          AlertToasts.error("User not found");
        }
      } catch (e) {
        setValidationMessage("");
        handleErrorMessage(e);
      }
    } else {
      setValidationMessage("Please enter valid email address");
    }
  };

  const closeModal = () => {
    if (emailRef.current) {
      emailRef.current.value = "";
      setShowConfirmation(false);
    }
  };

  return (
    <div className="box-outsider-auth login-box">
        <div className="container col-lg-5 col-md-9 col-sm-11 col-12 bg-white pb-5 px-md-5 rounded padding-top-56">
        <h1 className="text-center text-uppercase mt-3 mb-sm-4 mb-2">
          Forgot Password
        </h1>
        {validationMessage ? (
          <p className="error-msg text-center"> {validationMessage}</p>
        ) : (
          <p className="subtitle">Please enter your details.</p>
        )}
        <form className="login-form" onSubmit={handleReset}>
          <div className="form-group">
            <input
              type="email"
              ref={emailRef}
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter your email address"
            />
          </div>
          <button type="submit" className="btn btnlogin">
            Submit
          </button>
        </form>
        {showConfirmation ? (
          <ResetPasswordmodal
            show={showConfirmation}
            handleClose={() => {
              closeModal();
            }}
            email={emailRef.current?.value || ""}
          />
        ) : (
          <></>
        )}
        </div>
      </div>
  );
};

export default Forgotpassword;
