import React, { useEffect } from "react";
import blankimg from "../assets/images/blankimg.svg";
import useAuth from "../hooks/useAuth";
import useChat from "../hooks/useChat";
import { IParticipant } from "../models/IChatModel";
import AlertToasts from "../services/notification-services/notifications";
import moment from "moment";

type Participant = {
  participant: IParticipant;
};

const ChatCard = ({ participant }: Participant) => {
  const { auth } = useAuth();
  const {
    twilioClient,
    updateMessagesArray,
    participants,
    addNewMessageToConversation,
    setChannelArray,
    currentRoomId,
    openUserChat,
  } = useChat();

  const getChannelMessages = async (channel: any) => {
    try {
      const messages: any = await channel.getMessages(1000);
      const participantPosition = participants.findIndex(
        (w) => w.room === participant.room
      );
      if (
        participantPosition !== -1 &&
        messages.items &&
        messages.items.length
      ) {
        updateMessagesArray(
          messages.items.map((x: any) => x.state),
          participant.room
        );
      }
    } catch (e) {
      console.error(e);
      AlertToasts.error("Something went wrong");
    }
  };

  const joinChannel = async (channel: any) => {
    setChannelArray(participant.room, channel);
    if (channel.channelState.status !== "joined") {
      try {
        await channel.join();
      } catch (e) {
        AlertToasts.error("Not able to join the chat channels");
      }
    } else {
      getChannelMessages(channel);
    }
    channel.on("messageAdded", (message: any) =>
      addNewMessageToConversation(message, participant.room)
    );
  };

  const getChannel = async () => {
    console.log(participant);
    try {
      const channel = await twilioClient.getChannelByUniqueName(
        participant.room
      );
      joinChannel(channel);
    } catch (err) {
      try {
        const channel = await twilioClient.createChannel({
          uniqueName: participant.room,
          friendlyName: participant.room,
        });
        await joinChannel(channel);
        await channel.sendMessage(
          String(
            `We thank you both for using HighSchoolBabysitters.com.  Please use this message board to talk about additional details about the babysitting job.`
          ).trim()
        );
        openUserChat(participant.room);
      } catch (e) {
        console.error(e);
        AlertToasts.error("Unable to load chats, please refresh the page");
      }
    }
  };

  useEffect(() => {
    if (twilioClient) {
      twilioClient.on("channelJoined", async (channel: any) => {
        try {
          await getChannelMessages(channel);
        } catch (e) {
          console.error(e);
        }
      });

      getChannel();
    }
  }, []);

  return (
    <li className="cursor-pointer border-bottom">
      <a
        className={`d-flex justify-content-between px-2 py-3 person-name 
      ${currentRoomId === participant.room ? "active" : ""}
      `}
      >
        <div className="d-flex col-12 flex-row">
          <div className="col-2 mt-2 me-2 user-name">
            <p className="m-0">
              {auth.role === "parent"
                ? `${participant.babySitterFullName.slice(0, 1)}`
                : `${participant.parentFullName.slice(0, 1)}`}
            </p>
          </div>
          <div className="col-8 d-block py-2">
            <p className="title-sec-message">
              {auth.role === "parent"
                ? participant.babySitterFullName.split(" ")[0]
                : participant.parentFullName.split(" ")[0]}
                -
                {moment(participant?.jobId.startDate).format("MM/DD/YYYY")}
            </p>
            <p className="sec-chat-message text-muted text-truncate">
              {participant.messages.length ? (
                <React.Fragment>{`${
                  // auth.role === "parent" &&
                  // participant.messages[participant.messages.length - 1]
                  //   .author === auth.email
                  participant.messages[participant.messages.length - 1]
                    .author === participant.parentEmail
                    ? participant.parentFullName.split(" ")[0]
                    : participant.babySitterFullName.split(" ")[0]
                }: ${
                  participant.messages[participant.messages.length - 1].body
                }`}</React.Fragment>
              ) : (
                <React.Fragment>Start a conversation</React.Fragment>
              )}
            </p>
          </div>
          {participant.unreadMessages ? (
            <div className="col-2 d-flex align-items-center">
              <p className="text-white fw-500 unread-message">
                {/* {participant.unreadMessages} */}
              </p>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
      </a>
    </li>
  );
};

export default ChatCard;
