export const svcconfig = (token?: string, twilioToken?: string) => {
  const authToken = token || sessionStorage.getItem("token");
  return {
    Pragma: "no-cache",
    "Content-Type": "application/json",
    "accessToken": `Bearer ${authToken}`,
    "authToken": `Bearer ${twilioToken}`,
  };
};

export const fileuploadconfig = (token?: string) => {
  const authToken = token || sessionStorage.getItem("token");
  return {
    Pragma: "no-cache",
    "Content-Type": "multipart/form-data",
    "accessToken": `Bearer ${authToken}`,
  };
};
