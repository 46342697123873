import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../assets/images/logo.svg";
import "../assets/css/common.scss";
import { useLocation, useNavigate } from "react-router-dom";

type IProps = {
  handleClose: any;
  show: boolean;
};
const Thankyoumodal = ({ handleClose, show }: IProps) => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
        }}
        size="sm"
        className="delete-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0 border-0">
          <img
            src={logo}
            className="col-12 d-flex justify-content-center mx-auto"
          />
        </Modal.Header>
        <Modal.Body className="padding-small">
          <div className="mt-2 mx-auto text-unset">
            Thank you for Contacting us, we will get back to you soon
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-yes d-flex justify-content-center"
            onClick={() => {
              handleClose();
              navigate(from);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Thankyoumodal;
