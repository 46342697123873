import React, { useEffect, useRef, useState } from "react";
import heart from "../assets/images/heart.svg";
import people from "../assets/images/people.svg";
import clock from "../assets/images/clock.svg";
import location from "../assets/images/location.svg";
import fillheart from "../assets/images/fillheart.svg";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { IAppliedJob, IAvailableJob, IJobListing } from "../models/IJobListing";
import { getDateDifference, getHourDiff, handleErrorMessage } from "../helpers/utilities";
import useBabySitterServices from "../hooks/web-services-hooks/useBabySitterServices";
import moment from "moment";
import BSSpinner from "./BSSpinner";
import { MAX_RESULTS_PER_PAGE } from "../helpers/constants";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AlertToasts from "../services/notification-services/notifications";

enum JobListingType {
  AVAILABLE_JOBS,
  APPLIED_JOBS,
  ACTIVE_JOBS,
}

interface IJobListingPages {
  availableJobsPageNumber: number;
  appliedJobsPageNumber: number;
  hiredJobPageNumber: number;
}

const JobListing = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [page, setPage] = useState<IJobListingPages>({
    appliedJobsPageNumber: 1,
    availableJobsPageNumber: 1,
    hiredJobPageNumber: 1,
  });
  const [jobs, setJobs] = useState<IJobListing>({
    appliedJobs: [],
    availableJobs: [],
    hired: [],
    loadingAppliedJobs: true,
    loadingAvailableJobs: true,
    loadingHiredJobs: true,
  });
  const [loader, setLoader] = useState({
    apply: false,
    withdrawn: false,
    id: "",
  });
  const pageCountRef = useRef({
    appliedJobsTotalPages: 0,
    hiredJobsTotalPages: 0,
  });

  const BabySitterServices = useBabySitterServices();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const currentDate = moment();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
    type: string
  ) => {
    switch (type) {
      case "AVAILABLE_JOBS":
        setPage((prev) => {
          return { ...prev, availableJobsPageNumber: value };
        });
        break;
      case "APPLIED_JOBS":
        setPage((prev) => {
          return { ...prev, appliedJobsPageNumber: value };
        });
        fetchJobs(type, value);
        break;
      case "ACTIVE_JOBS":
        setPage((prev) => {
          return { ...prev, hiredJobPageNumber: value };
        });
        fetchJobs(type, value);
        break;
    }
  };

  const fetchJobs = async (type: string, pageNo: number) => {

    switch (type) {
      case "AVAILABLE_JOBS":
        try {
          const response = await BabySitterServices.getAvailableJobs();
          if (response.data.data) {

            const currentDate = moment(moment().format("YYYY-MM-DD"));

            console.log("Available Jobs: Current Date <<<<<<", currentDate);

            setJobs((prev) => {

              return {
                ...prev,
                availableJobs: response.data.data.filter(
                  (w: any) => {
                    const endDate = moment(moment(w?.job?.endDate).format("YYYY-MM-DD"));

                    console.log("Available Jobs: note <<<<<<", w?.job?.note);

                    console.log("Available Jobs: End Date <<<<<<", endDate);
                    console.log("Available Jobs: End Date is before <<<<<<", endDate.isBefore(currentDate));
                    console.log("Available Jobs: End Date is before boolean <<<<<<", !endDate.isBefore(currentDate));

                    return w !== null && !endDate.isBefore(currentDate);
                  }
                ),
                loadingAvailableJobs: false,
              };
            });
          }
        } catch (e) {
          handleErrorMessage(e);
          setJobs((prev) => {
            return {
              ...prev,
              loadingAvailableJobs: false,
            };
          });
        }
        break;
      case "APPLIED_JOBS":
        try {
          const response = await BabySitterServices.getAppliedJobs(pageNo);
          pageCountRef.current.appliedJobsTotalPages = getTotalPages(
            response.data.totalCount
          );
          if (response.data.data) {

            const currentDate = moment(moment().format("YYYY-MM-DD"));

            console.log("Applied Jobs: Current Date <<<<<<", currentDate);


            setJobs((prev) => {
              return {
                ...prev,
                appliedJobs: response.data.data.filter(
                  (w: any) => {
                    const endDate = moment(moment(w?.job?.jobId?.endDate).format("YYYY-MM-DD"));

                    console.log("Applied Jobs: note <<<<<<", w?.job?.note);

                    console.log("Applied Jobs: End Date <<<<<<", endDate);
                    console.log("Applied Jobs: End Date is before <<<<<<", endDate.isBefore(currentDate));
                    console.log("Applied Jobs: End Date is before boolean <<<<<<", !endDate.isBefore(currentDate));

                    return w !== null && !endDate.isBefore(currentDate);
                  }
                ),
                loadingAppliedJobs: false,
              };
            });
          }
        } catch (e) {
          handleErrorMessage(e);
          setJobs((prev) => {
            return {
              ...prev,
              loadingAppliedJobs: false,
            };
          });
        }
        break;
      case "ACTIVE_JOBS":
        try {
          const response = await BabySitterServices.getHiredJobs(pageNo);
          pageCountRef.current.hiredJobsTotalPages = getTotalPages(
            response.data.totalCount
          );
          if (response.data.data) {
            let filteredJobs = response.data.data.filter(
              (curJob: any) => {
                // new Date(curJob.job.jobId.startDate) >= new Date()

                const currentDate = moment(moment().format("YYYY-MM-DD"));
                const endDate = moment(moment(curJob.job.jobId.endDate).format("YYYY-MM-DD"));
                console.log(currentDate)
                console.log(curJob.job.jobId.endDate)
                console.log(endDate)

                return !endDate.isBefore(currentDate);

                // const startDate = new Date(curJob.job.jobId.startDate);
                // const today = new Date();
                // today.setHours(0, 0, 0, 0);
                // return startDate >= today;
              });
            setJobs((prev) => {
              return {
                ...prev,
                hired: filteredJobs,
                loadingHiredJobs: false,
              };
            });
          }
        } catch (e) {
          handleErrorMessage(e);
          setJobs((prev) => {
            return {
              ...prev,
              loadingHiredJobs: false,
            };
          });
        }
        break;
    }
  };

  const getTotalPages = (totalResults: number) => {
    return Math.ceil(totalResults / MAX_RESULTS_PER_PAGE);
  };

  const applyForJob = async (job: any, isWithdraw: boolean) => {
    if (isWithdraw) {
      //Code to take the application back
      setLoader({ ...loader, withdrawn: true, id: job.jobId._id });
      try {
        const jobInfo = {
          jobId: job.jobId._id,
          parentId: job.parentId._id,
          sitterId: auth._id,
          jobApplicationStatus: "withdraw",
        };
        const response = await BabySitterServices.applyForJob(jobInfo);
        if (response.data.success) {
          await fetchJobs("AVAILABLE_JOBS", 1);
          await fetchJobs("APPLIED_JOBS", page.appliedJobsPageNumber);
          setLoader({ ...loader, withdrawn: false, id: job.jobId._id });
          AlertToasts.success("Job has been withdrawn");
        }
      } catch (e) {
        handleErrorMessage(e);
        setLoader({ ...loader, withdrawn: false, id: job.jobId._id });
      }
    } else {
      setLoader({ ...loader, apply: true, id: job._id });
      try {
        const jobInfo = {
          jobId: job._id,
          parentId: job.userId._id,
          sitterId: auth._id,
          jobApplicationStatus: "applied",
        };
        const response = await BabySitterServices.applyForJob(jobInfo);
        if (response.data.success) {
          await fetchJobs("AVAILABLE_JOBS", 1);
          await fetchJobs("APPLIED_JOBS", page.appliedJobsPageNumber);
          setLoader({ ...loader, apply: false, id: job._id });
          AlertToasts.success("Job has been applied successfully");
        }
      } catch (e) {
        handleErrorMessage(e);
        setLoader({ ...loader, apply: false, id: job._id });
      }
    }
  };

  useEffect(() => {
    Object.values(JobListingType).forEach((jobType) => {
      if (typeof jobType === "string") {
        fetchJobs(jobType as keyof typeof JobListingType, 1);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className="job-page">
        <p className="page-title text-center text-uppercase mt-3 mb-4">
          JOBS FOR YOU
        </p>
        {/* ------------- Available Jobs -------------*/}
        <div className="container bg-white pb-5 px-md-5 rounded padding-top-58">
          <p className="sub-title mt-3 mb-2">Available Jobs</p>
          {jobs.loadingAvailableJobs ? (
            <React.Fragment>
              <BSSpinner />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {jobs.availableJobs.length ? (
                <React.Fragment>
                  {jobs.availableJobs
                    .slice(
                      MAX_RESULTS_PER_PAGE * (page.availableJobsPageNumber - 1),
                      MAX_RESULTS_PER_PAGE * page.availableJobsPageNumber
                    )
                    .map((jobItem, index) => {
                      let job: IAvailableJob = jobItem.job;
                      return (
                        <React.Fragment key={index}>
                          <div className="col-12 d-lg-flex d-unset details px-0">
                            <div className="col-lg-5 col-12 d-flex">
                              <div className="col-6 d-flex align-items-center">
                                {job.userId.lastName} Family
                              </div>
                              <div className="col-6 d-flex date-mobile">
                                Start Date{" "}
                                {moment(job.startDate).format("MM/DD/YYYY")}{" "}
                              </div>
                              <div
                                className="col-lg-3 col-6 d-flex justify-content-lg-start justify-content-end link-desktop cursor-pointer"
                                onClick={() =>
                                  navigate(`/job-summary/${job._id}`)
                                }
                              >
                                <a>Details </a>
                              </div>
                            </div>

                            <div className="col-lg-7 col-12 d-flex">
                              <div className="col-lg-6 col-6 d-flex date-desktop">
                                Start Date{" "}
                                {moment(job.startDate).format("MM/DD/YYYY")}{" "}
                              </div>
                              {loader.apply && loader.id === job._id ? (
                                <span className="ms-2 long-txt text-transorm-none ">
                                  Applying to the job.....
                                </span>
                              ) : (
                                <React.Fragment>
                                  <div
                                    className="col-md-6 col-10 d-flex text-lg-start text-end align-items-center justify-content-start cursor-pointer"
                                    onClick={() => {
                                      applyForJob(job, false);
                                    }}
                                  >
                                    <img
                                      src={heart}
                                      className="heart-img"
                                      alt=""
                                    />

                                    <span className="ms-2 long-txt text-transorm-none ">
                                      I’m interested in this job!
                                    </span>
                                  </div>
                                </React.Fragment>
                              )}

                              <div
                                className="col-lg-5 col-md-6 col-2 d-flex justify-content-lg-start justify-content-end link-mobile"
                                onClick={() =>
                                  navigate(`/job-summary/${job._id}`)
                                }
                              >
                                <a>Details</a>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 d-flex sub-details justify-content-lg-start justify-content-center px-0">
                            <div className="col-lg-4 col-auto d-flex ps-md-5">
                              <img src={people} alt="" />
                              <span className="px-2">
                                {" "}
                                {job.kids.length}{" "}
                                {job.kids.length > 1 ? "Children" : "Child"}
                              </span>
                            </div>
                            <div className="col-lg-5 col-auto d-flex px-md-4">
                              <img src={clock} alt="" />
                              <span className="px-2">
                                {getDateDifference(job.endDate, job.startDate) == 1 ? `${getHourDiff(job.endTime, job.startTime)} hours` : `${getDateDifference(job.endDate, job.startDate)} Days`}
                              </span>
                            </div>
                            <div className="col-lg-3 col-auto d-flex">
                              <div className="d-flex">
                                <img src={location} alt="" />
                                <span className="px-2">
                                  {jobItem.distance.toFixed(1)} miles from home
                                </span>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  <div className="col-12 d-flex justify-content-center align-items-center mt-0 px-0 pagination-page-number">
                    <Pagination
                      count={getTotalPages(jobs.availableJobs.length)}
                      defaultPage={1}
                      // siblingCount={-2}
                      siblingCount={getTotalPages(jobs.availableJobs.length) > 5 ? -2 : 0}
                      boundaryCount={1}
                      page={page.availableJobsPageNumber}
                      onChange={(e, page) =>
                        handleChange(
                          e,
                          page,
                          JobListingType[JobListingType.AVAILABLE_JOBS]
                        )
                      }
                      showFirstButton
                      showLastButton
                      size="small"
                      className="paginate-btn"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="theme-light-bg py-3 mt-4 rounded">
                    <h4 className="font-style text-center">
                      No Jobs Available
                    </h4>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        {/* ------------- Applied -------------*/}
        <div className="container bg-white pb-5 px-md-5 rounded padding-top-58 margin-top-18">
          <p className="sub-title mt-3 mb-2">Applied</p>
          {jobs.loadingAppliedJobs ? (
            <React.Fragment>
              <BSSpinner />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {jobs.appliedJobs.length ? (
                <React.Fragment>
                  {jobs.appliedJobs.map((jobItem, index) => {
                    let job: IAppliedJob = jobItem.job;
                    return (
                      <React.Fragment key={index}>
                        <div className="col-12 d-lg-flex d-unset details px-0">
                          <div className="col-lg-5 col-12 d-flex">
                            <div className="col-6 d-flex align-items-center">
                              {job.parentId.lastName} Family
                            </div>
                            <div className="col-6 d-flex date-mobile">
                              Start Date{" "}
                              {moment(job.jobId.startDate).format("MM/DD/YYYY")}{" "}
                            </div>
                            <div className="col-lg-3 col-6 d-flex justify-content-lg-start justify-content-end link-desktop cursor-pointer">
                              <a
                                onClick={() =>
                                  navigate(`/job-summary/${job.jobId._id}`)
                                }
                              >
                                Details
                              </a>
                            </div>
                          </div>

                          <div className="col-lg-7 col-12 d-flex">
                            <div className="col-lg-5 col-6 d-flex date-desktop">
                              Start Date{" "}
                              {moment(job.jobId.startDate).format("MM/DD/YYYY")}{" "}
                            </div>
                            {loader.withdrawn && loader.id === job.jobId._id ? (
                              <span className="ms-2 long-txt text-transorm-none ">
                                Withdrawing the job.....
                              </span>
                            ) : (
                              <React.Fragment>
                                <div
                                  className="col-md-7 col-10 d-flex text-lg-start align-items-center text-end justify-content-start cursor-pointer"
                                  onClick={() => {
                                    applyForJob(job, true);
                                  }}
                                >
                                  <img
                                    src={fillheart}
                                    className="heart-img"
                                    alt=""
                                  />
                                  <span className="ms-2 long-txt text-transorm-none ">
                                    I’m no longer interested in this job
                                  </span>
                                </div>
                              </React.Fragment>
                            )}

                            <div className="col-md-5 col-2 d-flex justify-content-lg-start justify-content-end link-mobile">
                              <a
                                onClick={() =>
                                  navigate(`/job-summary/${job.jobId._id}`)
                                }
                              >
                                Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-flex sub-details justify-content-lg-start justify-content-center px-0">
                          <div className="col-lg-4 col-auto d-flex ps-md-5">
                            <img src={people} alt="" />
                            <span className="px-2">
                              {job.jobId.kids.length} Children
                            </span>
                          </div>
                          <div className="col-lg-5 col-auto d-flex px-md-4">
                            <img src={clock} alt="" />
                            <span className="px-2">
                              {getHourDiff(
                                job.jobId.endTime,
                                job.jobId.startTime
                              )}{" "}
                              hours
                            </span>
                          </div>
                          <div className="col-lg-3 col-auto d-flex">
                            <div className="d-flex">
                              <img src={location} alt="" />
                              <span className="px-2">
                                {jobItem.distance.toFixed(1)} miles from home
                              </span>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                  <div className="col-12 d-flex justify-content-center align-items-center mt-0 px-0 pagination-page-number">
                    <Pagination
                      count={pageCountRef.current.appliedJobsTotalPages}
                      defaultPage={1}
                      siblingCount={pageCountRef.current.appliedJobsTotalPages > 5 ? -2 : 0}
                      boundaryCount={1}
                      page={page.appliedJobsPageNumber}
                      onChange={(e, page) =>
                        handleChange(
                          e,
                          page,
                          JobListingType[JobListingType.APPLIED_JOBS]
                        )
                      }
                      showFirstButton
                      showLastButton
                      size="small"
                      className="paginate-btn"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="theme-light-bg py-3 mt-4 rounded">
                    <h4 className="font-style text-center">No Applied Jobs</h4>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
        {/* ------------- Hired -------------*/}
        <div className="container bg-white pb-5 px-md-5 rounded padding-top-58 margin-top-18">
          <p className="sub-title mt-3 mb-2">Hired</p>
          {jobs.loadingHiredJobs ? (
            <React.Fragment>
              <BSSpinner />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {jobs.hired.length ? (
                <React.Fragment>
                  {jobs.hired.map((jobItem, index) => {
                    let job: IAppliedJob = jobItem.job;
                    return (
                      <div
                        key={index}
                      // className={`${
                      //   new Date(jobItem.job.jobId.startDate) >= new Date()
                      //     ? ""
                      //     : "d-none"
                      // }`}
                      >
                        <div className="col-12 d-lg-flex d-unset details px-0">
                          <div className="col-lg-5 col-12 d-flex">
                            <div className="col-6 d-flex align-items-center">
                              {job.parentId.lastName} Family
                            </div>
                            <div className="col-6 d-flex date-mobile">
                              Start Date{" "}
                              {moment(job.jobId.startDate).format("MM/DD/YYYY")}{" "}
                            </div>
                            <div className="col-lg-3 col-6 d-flex justify-content-lg-start justify-content-end link-desktop cursor-pointer">
                              <a
                                onClick={() =>
                                  navigate(`/job-summary/${job.jobId._id}`)
                                }
                              >
                                Details
                              </a>
                            </div>
                          </div>

                          <div className="col-lg-7 col-12 d-flex">
                            <div className="col-lg-6 col-6 d-flex date-desktop">
                              Start Date{" "}
                              {moment(job.jobId.startDate).format("MM/DD/YYYY")}{" "}
                            </div>
                            <div
                              className="col-md-6 col-10 d-flex text-lg-start text-end align-items-center justify-content-lg-end pe-4 text-transorm-none message-family cursor-pointer"
                              onClick={() => navigate("/message-center")}
                            >
                              Message Family
                            </div>
                            <div className="col-lg-5 col-md-6 col-2 d-flex justify-content-lg-start justify-content-end link-mobile">
                              <a
                                onClick={() =>
                                  navigate(`/job-summary/${job.jobId._id}`)
                                }
                              >
                                Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-flex sub-details justify-content-lg-start justify-content-center px-0">
                          <div className="col-lg-4 col-auto d-flex ps-md-5">
                            <img src={people} alt="" />
                            <span className="px-2">
                              {job.jobId.kids.length} Children
                            </span>
                          </div>
                          <div className="col-lg-4 col-auto d-flex px-md-4">
                            <img src={clock} alt="" />
                            <span className="px-2">
                              {getHourDiff(
                                job.jobId.endTime,
                                job.jobId.startTime
                              )}{" "}
                              hours
                            </span>
                          </div>
                          <div className="col-lg-4 col-auto d-flex px-lg-5">
                            <div className="d-flex">
                              <img src={location} alt="" />
                              <span className="px-2">
                                {jobItem.distance.toFixed(1)} miles from home
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-12 d-flex justify-content-center align-items-center mt-0 px-0 pagination-page-number">
                    <Pagination
                      count={pageCountRef.current.hiredJobsTotalPages}
                      defaultPage={1}
                      siblingCount={pageCountRef.current.hiredJobsTotalPages > 5 ? -2 : 0}
                      boundaryCount={1}
                      page={page.hiredJobPageNumber}
                      onChange={(e, page) =>
                        handleChange(
                          e,
                          page,
                          JobListingType[JobListingType.ACTIVE_JOBS]
                        )
                      }
                      showFirstButton
                      showLastButton
                      size="small"
                      className="paginate-btn"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="theme-light-bg py-3 mt-4 rounded">
                    <h4 className="font-style text-center">No Hired Jobs</h4>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobListing;
