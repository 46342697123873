import { useEffect, useState } from "react";
import ProfileImg from "../assets/images/sitter_profile.png";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router";
import Deletemodal from "./Deletemodal";
import { decryptData } from "../helpers/utilities";
import useBabySitterServices from "../hooks/web-services-hooks/useBabySitterServices";

type IDeleteModelInfo = {
  show: boolean;
  action: string;
  id: string;
};

const BabySitterProfilePage = () => {
  const { auth } = useAuth();
  const BabysitterServices = useBabySitterServices();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [deleteModelInfo, setDeleteModelInfo] = useState<IDeleteModelInfo>({
    show: false,
    action: "job",
    id: "",
  });

  const deleteAccount = (userId: string) => {
    setDeleteModelInfo({
      ...deleteModelInfo,
      action: "account",
      show: true,
      id: userId,
    });
  };

  useEffect(() => {
    // let profileImg = localStorage.getItem("profileImage");
    //   setProfilePicture(decryptData(profileImg || ""));
    if (auth.token) {
      BabysitterServices.getBabySitterAboutInfo(auth._id)
        .then((res) => {
            setProfilePicture(res.data.data.profilePicture);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  },[]);

  return (
    <>
      <div className="box-outside-view mt-3">
        <div
          className="container bg-white pb-5 px-lg-5 rounded mt-5 padding-top-71"
        >
          <div className="col-12 d-lg-flex px-lg-5 mb-4 mx-auto">
            <div className="col-xl-2 col-lg-3 d-lg-flex justify-content-lg-start col-sm-6 col-10 mx-lg-0 mx-auto">
              <img
                src={profilePicture !== "" ? profilePicture : ProfileImg}
                className="d-flex justify-content-center mx-auto profile-pic"
                alt=""
              />
            </div>
            <div className="col-lg d-lg-flex ms-lg-5 justify-content-lg-start align-items-center mt-0 col-sm-6 col-10 mx-lg-0 mx-auto">
              <p className="page-subtitle mt-3 mb-2 col-lg-8 text-lg-start text-center">
                {`${auth.firstName} ${auth.lastName}`}
              </p>
            </div>
          </div>
          <div className="col-12 d-lg-flex filter-container px-lg-5 mt-lg-0 mt-3 mx-lg-0 mx-auto w-75">
            <div className="col-lg-auto col-12 d-flex justify-content-lg-start justify-content-center my-3 me-lg-2">
              <button className="btn btn-theme-light w-100" onClick={() => navigate("/message-center")}>Messages</button>
            </div>
            <div className="col-lg-auto col-12 d-flex justify-content-lg-start justify-content-center my-3 mx-lg-2 ">
              <button
                className="btn btn-theme-light w-100"
                onClick={() => navigate("/edit-profile")}
              >
                Edit Your Information
              </button>
            </div>
            <div className="col-lg-auto col-12 d-flex justify-content-lg-start justify-content-center my-3 mx-lg-2 ">
              <button
                className="btn btn-theme-light w-100"
                onClick={() => {
                  navigate("/about-me");
                }}
              >
                Edit About You Information
              </button>
            </div>
          </div>

          <div className="col-12 d-xl-flex filter-container px-lg-5 mt-lg-0 mt-3 mx-lg-0 mx-auto w-75">
            <div className="col-lg-auto  col-12 d-flex justify-content-lg-start justify-content-center my-3 me-lg-2 ">
              <button
                className="btn btn-theme-light w-100"
                onClick={() => {
                  navigate("/jobs");
                }}
              >
                View Your Job Board
              </button>
            </div>
            <div className="col-lg-auto  col-12 d-flex justify-content-lg-start justify-content-center my-3 mx-lg-2 ">
              <button
                className="btn btn-theme-light w-100"
                onClick={() => {
                  navigate("/edit-guardian-info");
                }}
              >
                View Your Parent/Guardian Information
              </button>
            </div>
            <div className="col-lg-auto col-12 d-flex justify-content-lg-start justify-content-center my-3 mx-lg-2 ">
              <button
                className="btn btn-theme-light w-100"
                onClick={() => deleteAccount(auth._id)}
              >
                Delete Your Account
              </button>
            </div>
          </div>
        </div>
      </div>
      {deleteModelInfo.show ? (
        <Deletemodal
          handleClose={() =>
            setDeleteModelInfo({ ...deleteModelInfo, show: false })
          }
          details={deleteModelInfo}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default BabySitterProfilePage;
