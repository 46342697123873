import { createContext, ReactNode, useState } from "react";
import { IAuthContextModel, IAuthModel } from "../models/IAuthContextModel";

type ChildrenType = {
  children: ReactNode;
};

const initialAuthState: IAuthModel = {
  _id: "",
  createdAt: "",
  dateOfBirth: new Date(),
  email: "",
  firstName: "",
  gender: "",
  homeAddress: "",
  homeCity: "",
  homeZipCode: "",
  lastName: "",
  password: "",
  phoneNo: "",
  role: "",
  token: "",
  updatedAt: "",
  isBsParentVerified: false,
  isUserVerified: false
};

const AuthContext = createContext<IAuthContextModel>({
  auth: initialAuthState,
  setAuth: () => {},
  persist: false,
  setPersist: () => {},
});

export const AuthProvider = ({ children }: ChildrenType) => {
  const localPersistValue: string = localStorage.getItem("persist") || "";

  const [auth, setAuth] = useState<IAuthModel>(initialAuthState);
  const [persist, setPersist] = useState<boolean>(
    !localPersistValue ? false : JSON.parse(localPersistValue) ? true : false
  );

  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
