import { fileuploadconfig, svcconfig } from "../../helpers/api-services";
import useAuth from "../useAuth";
import useAxiosPrivate from "../useAxiosPrivate";

const BABY_SITTER_URL = process.env.REACT_APP_API_URL + "/sitter";

const useRegistrationServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const babySitterStep1Submit = (step1Data: Object) => {
    const apiURL = `${BABY_SITTER_URL}/registration`;
    return axiosPrivate.post(apiURL, step1Data, { headers: svcconfig() });
  };

  const babySitterStep2Submit = (step2Data: Object) => {
    const apiURL = `${BABY_SITTER_URL}/aboutInfo`;
    //Setting Profile picture empty
    return axiosPrivate.post(apiURL, step2Data, {
      headers: fileuploadconfig(),
    });
  };

  const babySitterStep3Submit = (step3Data: Object) => {
    const apiURL = `${BABY_SITTER_URL}/parentInfo`;
    return axiosPrivate.post(apiURL, step3Data, { headers: svcconfig() });
  };

  const editRegistration = (data: Object, id: string) => {
    const apiURL = `${BABY_SITTER_URL}/editregistration/${id}`;
    return axiosPrivate.patch(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const registrationServices = {
    babySitterStep1Submit,
    babySitterStep2Submit,
    babySitterStep3Submit,
    editRegistration,
  };

  return registrationServices;
};

export default useRegistrationServices;
