import React from 'react'
import JobListing from '../components/JobListing'

const Jobs = () => {
  return (
    <div>
        <JobListing />
    </div>
  )
}

export default Jobs
