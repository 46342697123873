import React from 'react'
import AboutForm from '../components/BabySitterRegistration/AboutYou'

const AboutBabySitter = () => {
  return (
    <React.Fragment>
        <AboutForm userId={''} handleStepChange={() => {}} edit={true} /> 
    </React.Fragment>     
  )
}

export default AboutBabySitter
