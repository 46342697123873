import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../assets/css/common.scss";

type Props = {
    show: boolean;
    handleClose: Function;
    email: string;
}

const ResetPasswordmodal = ({show, handleClose, email}: Props) => {
    
  return(
    <>
    <Modal
     show={show} onHide={() => {handleClose()}}
     className=" delete-modal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Body className="sendemail-body mt-2">
            <div className="tick-animation">
                <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                >
                    <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                </svg>
            </div>
            <div className="sendemail mt-2 mx-auto text-unset">
                Password link sent successfully
                <p className="mb-1 forgot-emailid">
                    Please check your email <br/> {email}
                </p>
            </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <Button className="btn btn-yes" onClick={() => {handleClose()}}>Ok</Button>
        </Modal.Footer>
    </Modal>
    </>
)}

export default ResetPasswordmodal;

