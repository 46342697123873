import React, { useEffect, useState } from "react";
import "../assets/css/babysitterList.scss";
import useParentServices from "../hooks/web-services-hooks/useParentServices";
import useAuth from "../hooks/useAuth";
import ProfileImg from "../assets/images/sitter_profile.png";
import { useNavigate } from "react-router-dom";
import NoSitterImg from "../assets/images/no-sitters.svg";
import { handleErrorMessage, isPastDate } from "../helpers/utilities";
import BSSpinner from "./BSSpinner";
import usePaymentServices from "../hooks/web-services-hooks/usePaymentServices";
import useChatServices from "../hooks/web-services-hooks/useChatServices";
import moment from "moment";

const InterestedBabySitterList = () => {
  const [sitterData, setSitterData] = useState([]);
  const [loader, setLoader] = useState<Boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<Boolean>(false);
  const navigate = useNavigate();
  const ParentServices = useParentServices();
  const PaymentServices = usePaymentServices();

  const { auth } = useAuth();
  const ChatServices = useChatServices();

  useEffect(() => {
    setLoader(true);
    ParentServices.interestedSitters(auth._id)
      .then((res: any) => {
        if (res.status === 200) {

          const currentDate = moment(moment().format("MM-DD-YYYY"));
          
          const filteredSitterData = res?.data?.data?.filter((sitter: any) => {
            const endDate = moment(moment(sitter?.jobId?.endDate).format("MM-DD-YYYY"));
            return !endDate.isBefore(currentDate);
          })
          console.log(filteredSitterData);

          setSitterData(filteredSitterData);
        }
      })
      .catch((e) => {
        handleErrorMessage(e);
      })
      .finally(() => {
        setLoader(false);
      });
    PaymentServices.currentPlan(auth._id).then((res) => {
      if (res.status === 200 && res.data.data.length) {
        let subscriptionData = res.data.data[0];
        if (
          !subscriptionData.isCancelled &&
          (subscriptionData.subscriptionType === "Monthly" ||
            subscriptionData.subscriptionType === "Yearly")
        ) {
          setIsSubscribed(true);
        } else if (
          subscriptionData.isCancelled &&
          !isPastDate(subscriptionData.subsriptionEndDate)
        ) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
      }
    });
  }, []);

  const createChatRoom = async (
    sitterId: string,
    sitterEmail: string,
    sitterFullName: string,
    jobId: string
  ) => {
    try {
      const applicationResponse = await ParentServices.hireBabySitter({
        jobId,
        parentId: auth._id,
        sitterId,
        jobApplicationStatus: "hired",
      });
      const response = await ChatServices.storeUserChannelData({
        parentEmail: auth.email,
        parentId: auth._id,
        babySitterEmail: sitterEmail,
        babySitterId: sitterId,
        parentFullName: `${auth.firstName}  ${auth.lastName}`,
        babySitterFullName: sitterFullName,
        jobId: jobId,
        room: crypto.randomUUID(),
      });
      if (response.data.success) {
        navigate("/message-center");
      }
      setLoader(false);
    } catch (e) {
      handleErrorMessage(e);
    }
  };

  return (
    <>
      <div className="container babysitterList">
        <p className="text-center page-title my-5 text-uppercase">
          Interested Babysitters
        </p>

        <div className="bg-white p-4 p-sm-5 rounded">
          {loader ? (
            <BSSpinner />
          ) : sitterData.length ? (
            <div>
              {sitterData.map((data: any, index: any) => (
                <div className="card mt-3 mb-5" key={index}>
                  <div className="card-header py-3">
                    <div className="row align-items-center">
                      <h5 className="col-12 col-sm my-0 py-0 text-center text-sm-start">
                        {data.sitterId.firstName}
                      </h5>
                      <p className="col-12 col-sm text-sm-end text-center my-0 py-0">
                        {moment(data.jobId.startDate).format("MM/DD/YYYY")} -
                        Job Date
                      </p>
                    </div>
                  </div>
                  <div className="card-body px-md-4">
                    <div className="row mb-3">
                      <div className="col-12 col-md-2 mx-sm-3 mb-3 mb-sm-0 text-center">
                        <img
                          src={
                            data.sitterInfo.profilePicture !== ""
                              ? data.sitterInfo.profilePicture
                              : ProfileImg
                          }
                          className="img-fluid sitter-img"
                          alt="Profile"
                        />
                      </div>
                      <div className="col-12 col-md">
                        <h5 className="card-title">
                          About {data.sitterId.firstName}
                        </h5>
                        <p className="card-text">
                          {data.sitterInfo.profileDescription}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm my-3 my-sm-0">
                        <h5 className="card-title">Babysitter details</h5>
                        <div className="card-text">
                          <ul>
                            <li>
                              Age :{" "}
                              {new Date().getFullYear() -
                                data.sitterId.dateOfBirth
                                  .toString()
                                  .split("T")[0]
                                  .split("-")[0]}
                            </li>
                            {data.sitterInfo.firstAidTraining ? (
                              <li>Does have first aid training</li>
                            ) : (
                              <li>Does Not Have first aid training</li>
                            )}
                            {data.sitterInfo.hasDrivingLicense ? (
                              <li>Has Driver's License</li>
                            ) : (
                              <li>Does Not Have Driver's License</li>
                            )}

                            {data.sitterInfo.babySittingTraining ? (
                              <li>Does have babysitter training courses</li>
                            ) : (
                              <li>Does not have babysitter training courses</li>
                            )}

                            <li>
                              {parseFloat(data.distance).toFixed(1)} miles from
                              your babysitting address
                            </li>
                            <li>
                              Has a{" "}
                              {data.sitterInfo.unweightedGpa
                                .toString()
                                .includes(".")
                                ? data.sitterInfo.unweightedGpa
                                : `${data.sitterInfo.unweightedGpa}.0`}
                              /4.0 GPA in highschool
                            </li>
                            <li>
                              Rate is ${data.sitterInfo.chargeForOneKid}/hr for
                              the first kid and $
                              {data.sitterInfo.chargeForAdditionalKid}/hr for
                              each additional kid
                            </li>
                            <li>
                              {data.sitterInfo.howGetToBabySitting}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex col-12 col-sm justify-content-sm-end align-items-sm-end justify-content-center align-items-center mb-3">
                        <button
                          className="card-btn"
                          onClick={() => {
                            isSubscribed
                              ? createChatRoom(
                                data.sitterId._id,
                                data.sitterId.email,
                                `${data.sitterId.firstName}  ${data.sitterId.lastName}`,
                                data.jobId._id
                              )
                              : navigate("/payment", {
                                state: {
                                  sitterId: data.sitterId._id,
                                  jobId: data.jobId._id,
                                  sitterEmail: data.sitterId.email,
                                  fullName: `${data.sitterId.firstName}  ${data.sitterId.lastName}`,
                                },
                              });
                          }}
                        >
                          Choose This Babysitter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="row justify-content-center align-items-center py-4 my-4">
              <div className="col-12 text-center py-3">
                <div className="not-interested-list mx-auto">
                  <img
                    src={NoSitterImg}
                    className="not-interested-img"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 px-4 my-5">
                <p className="interested-subtitle text-center m-0 p-0">
                  We have notified the babysitters in your area, but none of them
                  have clicked that they are interested in the job yet. If it's
                  been a day or more since you posted the job, you may be able to
                  find more eligible babysitters if you change some of your
                  babysitter preferences and re-post the job.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InterestedBabySitterList;
