import MessageCenter from '../components/MessageCenter'
import { ChatProvider } from '../context/ChatProvider'

const ChatPage = () => {
  return (
    <ChatProvider>
        <MessageCenter />
    </ChatProvider>
  )
}

export default ChatPage