import { Navigate, useLocation } from "react-router-dom";
import BabySitterProfilePage from "../components/BabySitterProfilePage";
import ParentProfile from "../components/ParentProfile";
import useAuth from "../hooks/useAuth";

const Profile = () => {

    const {auth} = useAuth();
    const location = useLocation();

  return (
    <div>
      {!auth.token ? <Navigate to="/login" state={{ from: location }} replace /> : auth.role == "sitter" ? <BabySitterProfilePage /> : <ParentProfile />}
    </div>
  );
};

export default Profile;
