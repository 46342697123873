import React, { useEffect, useState } from "react";
import { ChatProvider } from "../context/ChatProvider";
import { handleErrorMessage } from "../helpers/utilities";
import useChat from "../hooks/useChat";
import useChatServices from "../hooks/web-services-hooks/useChatServices";
import BSSpinner from "./BSSpinner";
import ConversationScreen from "./ConversationScreen";
import ChatList from "./ChatList";
import { Client } from "@twilio/conversations";

const Chat = require("twilio-chat");

const MessageCenter = () => {
  const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 767);
  const [showChatItem, setShowChatItem] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const ChatServices = useChatServices();
  const {
    twilioToken,
    twilioClient,
    setTwilioToken,
    setTwilioClient,
    setConversationClient,
  } = useChat();

  const updateMedia = () => {
    setDesktop(window.innerWidth > 767);
  };

  const getChatToken = async () => {
    try {
      const response = await ChatServices.generateTwilioChatToken();
      let token = response.data.token;
      if (token) {
        setTwilioToken(token);
        setIsLoading(false);
      }
    } catch (e) {
      handleErrorMessage(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChatToken();
  }, []);

  useEffect(() => {
    const bindTwilioListener = async () => {
      try {
        const client = await Chat.Client.create(twilioToken);
        const clientConversations = new Client(twilioToken);

        clientConversations.on("stateChanged", (state) => {
          if (state === "initialized") {
            console.log("User Initalized");
          }
        });

        setTwilioClient(client);
        setConversationClient(clientConversations);

        client.on("tokenAboutToExpire", async () => {
          getChatToken();
        });

        client.on("tokenExpired", async () => {
          getChatToken();
        });

        if (!client.reachabilityEnabled) {
          // reachability function is disabled for the client
        }

        // listen to user reachability status updates
        client.on("userUpdated", (user: any, updateReasons: any) => {
          if (updateReasons.includes("reachabilityOnline")) {
            // user reachability status was updated
          }

          if (updateReasons.includes("reachabilityNotifiable")) {
            // user notifications status was updated
          }
        });
      } catch (e) {
        handleErrorMessage(e);
      }
    };
    if (twilioToken) {
      bindTwilioListener();
    }
  }, [twilioToken]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    window.scrollTo(0, 0);
    return () => window.removeEventListener("resize", updateMedia);
  }, [isDesktop]);

  return (
    <React.Fragment>
      {isLoading || !twilioClient ? (
        <React.Fragment>
          <BSSpinner />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {isDesktop ? (
            <div className="box-outside-view">
              <p className="page-title text-center text-uppercase mt-3 mb-sm-5 mb-2">
                Message center
              </p>
              <div className="container bg-white pb-5 px-md-5 rounded mt-sm-4 mt-2 padding-50-44">
                <div className="container px-lg-2 px-0">
                  <div className="row mx-0 px-0 desktop-chat">
                    <div className="col-md-12 px-0">
                      <div className="card border-0 chatsection" id="chat3">
                        <div className="card-body p-0">
                          <div className="row mx-0 px-0">
                            <ChatList />
                            <ConversationScreen />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="box-outside-view">
              <p className="page-title text-center text-uppercase mt-3 mb-sm-5 mb-2">
                Message center
              </p>
              <div className="bg-white pb-md-5 px-md-5 rounded mt-sm-4 mt-2 py-2">
                <div className="container">
                  <div className="row mx-0 px-0 desktop-chat">
                    <div className="col-md-12 px-0">
                      <div className="card border-0 chatsection" id="chat3">
                        <div className="card-body p-0">
                          <div className="row mx-0 px-0">
                            {showChatItem ? (
                              <ChatList setShowChatItem={setShowChatItem} />
                            ) : (
                              <ConversationScreen
                                setShowChatItem={setShowChatItem}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MessageCenter;
