import { fileuploadconfig, svcconfig } from "../../helpers/api-services";
import useAuth from "../useAuth";
import useAxiosPrivate from "../useAxiosPrivate";

const BASE_URL = process.env.REACT_APP_API_URL;
const PARENT_URL = BASE_URL + "/parent";
const JOB_URL = BASE_URL + "/jobinfo";

const useParentServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const parentStep1Registration = (step1Data: Object) => {
    const apiURL = `${PARENT_URL}/registration`;
    return axiosPrivate.post(apiURL, step1Data, { headers: svcconfig() });
  };

  const parentStep2Registration = (step2Data: Object) => {
    const apiURL = `${PARENT_URL}/sitterPreferences`;
    return axiosPrivate.post(apiURL, step2Data, { headers: svcconfig() });
  };

  const parentStep3Registration = (step3Data: Object) => {
    const apiURL = `${PARENT_URL}/jobinformation`;
    return axiosPrivate.post(apiURL, step3Data, { headers: svcconfig() });
  };

  const allJobs = (id: any) => {
    const apiURL = `${JOB_URL}/jobsforyou/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const jobDetails = (id: any) => {
    const apiURL = `${JOB_URL}/jobdetails/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const interestedSitters = (id: string) => {
    const apiURL = `${JOB_URL}/interestedBabySitter/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const editRegistration = (data: any, id: string) => {
    const apiURL = `${PARENT_URL}/editregistration/${id}`;
    return axiosPrivate.patch(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const getPreferences = (id: string) => {
    const apiURL = `${PARENT_URL}/sitterPreferences/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const editPreferences = (data: any, id: string) => {
    const apiURL = `${PARENT_URL}/editpreferences/${id}`;
    return axiosPrivate.patch(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const deleteJob = (id: string) => {
    const apiURL = `${PARENT_URL}/deleteJob/${id}`;
    return axiosPrivate.patch(apiURL, {}, { headers: svcconfig(auth.token) });
  };

  const parentProfile = (id: string) => {
    const apiURL = `${PARENT_URL}/profile/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const hireBabySitter = (jobData: Object) => {
    const apiURL = `${BASE_URL}/jobapplication/hire`;
    return axiosPrivate.post(apiURL, jobData, { headers: svcconfig() });
  };

  const parentServices = {
    parentStep1Registration,
    parentStep2Registration,
    parentStep3Registration,
    jobDetails,
    allJobs,
    interestedSitters,
    editRegistration,
    getPreferences,
    editPreferences,
    parentProfile,
    deleteJob,
    hireBabySitter
  };

  return parentServices;
};

export default useParentServices;
