import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/common.scss";
import { IBabySitterSafetyInfo } from "../../models/IBSRegistrationModel";
import email from "../../assets/images/email.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AlertToasts from "../../services/notification-services/notifications";
import {
  handleErrorMessage,
  updateErrorFields,
  updateMultipleErrorFields,
  validateEmail,
} from "../../helpers/utilities";
import useRegistrationServices from "../../hooks/web-services-hooks/useRegistrationServices";
import useBabySitterServices from "../../hooks/web-services-hooks/useBabySitterServices";
import useAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  handleStepChange: Function;
  userId: string;
  edit?: boolean;
};

const SafetyInfoForm = ({ handleStepChange, userId, edit }: Props) => {
  const [safetyInfo, setSafetyInfo] = useState<IBabySitterSafetyInfo>({
    parentEmail: "",
    parentFirstName: "",
    parentLastName: "",
    parentPhoneNo: "",
    step: 3,
    userId,
  });
  const [sitterEmail, setSitterEmail] = useState({
    email:""
  });
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const { auth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const previousInputValues = useRef<IBabySitterSafetyInfo>(safetyInfo);

  const RegistrationServices = useRegistrationServices();
  const BabySitterServices = useBabySitterServices();

  const submitStep3 = () => {
    setLoader(true);
    if (edit) {
      let updatedInfo: any = {};
      Object.keys(safetyInfo).forEach((key) => {
        if (
          safetyInfo[key as keyof typeof safetyInfo] !==
          previousInputValues.current[key as keyof typeof safetyInfo]
        ) {
          updatedInfo[key] = safetyInfo[key as keyof typeof safetyInfo];
        }
      });
      if (Object.keys(updatedInfo).length) {
        BabySitterServices.updateBabySitterAboutInfo(updatedInfo)
          .then((res) => {
            if (res.data.success) {
              AlertToasts.success("Information updated successfully");
              navigate("/");
            }
          })
          .catch((e) => {
            handleErrorMessage(e);
          }).finally(() => {
            setLoader(false);
          });;
      }
    } else {
      RegistrationServices.babySitterStep3Submit({
        ...safetyInfo,
        parentPhoneNo: safetyInfo.parentPhoneNo.slice(1),
      })
        .then((res) => {
          if (res.status === 200) {
            handleStepChange(3);
            sessionStorage.setItem("safetyInfo", JSON.stringify(safetyInfo));
            AlertToasts.info("Verification emails have been sent to your email address and to your parent/guardian's email address.  Please verify your email address and ask your parent/guardian to verify their email address to fully activate your account.");
          }
        })
        .catch((e) => {
          handleErrorMessage(e);
        }).finally(() => {
          setLoader(false);
        });;
    }
  };

  const formValidation = () => {
    let validationResult: boolean = true;
    let step3InvalidFields: string[] = [];

    if (safetyInfo) {
      Object.keys(safetyInfo).forEach((key: string) => {
        if (
          Object.hasOwn(safetyInfo, key) &&
          (safetyInfo[key as keyof typeof safetyInfo] === "" ||
            safetyInfo[key as keyof typeof safetyInfo] === null ||
            safetyInfo[key as keyof typeof safetyInfo] === 0)
        ) {
          validationResult = false;
          step3InvalidFields.push(key);
        }
      });
    }

    if (!validateEmail(safetyInfo.parentEmail)) {
      validationResult = false;
      step3InvalidFields.push("parentEmail");
    }

    if (safetyInfo.parentEmail && (safetyInfo.parentEmail.toLowerCase() === sitterEmail.email.toLowerCase())) {
      validationResult = false;
      step3InvalidFields.push("sameEmail")
    } 

    if (safetyInfo.parentPhoneNo.length !== 11) {
      //Field includes country code sign as well, that makes total length 11
      validationResult = false;
      step3InvalidFields.push("parentPhoneNo");
    }

    if (safetyInfo.parentPhoneNo.length === 11 && safetyInfo.parentPhoneNo.charAt(1) == "0") {
      validationResult = false;
      step3InvalidFields.push("invalid-parentPhoneNo");
    }

    setInvalidFields(step3InvalidFields);

    if (validationResult) {
      submitStep3();
    }
  };

  const getBabySitterAboutInfo = async () => {
    try {
      const response = await BabySitterServices.getBabySitterAboutInfo(
        auth._id
      );
      if (response.data.success) {
        const parentInfo = response.data.data;
        const safetyInfoFields = {
          parentEmail: parentInfo.parentEmail,
          parentFirstName: parentInfo.parentFirstName,
          parentLastName: parentInfo.parentLastName,
          parentPhoneNo: "1" + parentInfo.parentPhoneNo,
          userId: auth._id,
          step: 3,
        };
        previousInputValues.current = safetyInfoFields;
        setSafetyInfo(safetyInfoFields);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const currentStepData: string | null = sessionStorage.getItem("safetyInfo");
    if (auth && auth._id) {
      getBabySitterAboutInfo();
    } else if (currentStepData) {
      setSafetyInfo(JSON.parse(currentStepData));
    }

    let data: string|null = sessionStorage.getItem("registrationForm");
    setSitterEmail({...sitterEmail, email: data && JSON.parse(data).email});
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div
        className={edit ? "container bg-white pb-5 px-md-5 rounded pt-5" : ""}
      >
        <div className="row text-center mt-5">
          <div className="col-12">
            <h1 className="form-title">{edit ? "Your Safety Info" : "Safety Info"} </h1>
          </div>
          <div className="col-12 text-center">
            <p className="page-discription m-0 p-0">
              Our goal is to keep you safe. We want your parents to know
            </p>
            <p className="page-discription m-0 p-0">
              where you are and who you are babysitting for
            </p>
          </div>
        </div>
        <form className="row gy-3 my-3 mx-auto">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Parent-Guardian First Name *</label>
              <input
                type="text"
                className="form-control"
                disabled={edit}
                placeholder="First Name"
                value={safetyInfo.parentFirstName}
                onChange={(e) => {
                  let input = e.target.value;
                  if (/^[a-zA-Z]*$/.test(input)) {
                    setSafetyInfo({
                      ...safetyInfo,
                      parentFirstName: input,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "parentFirstName"
                    );
                  }
                }}
              />
              <div
                className={`${
                  invalidFields.includes("parentFirstName") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter first name</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Parent-Guardian Last Name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                disabled={edit}
                value={safetyInfo.parentLastName}
                onChange={(e) => {
                  let input = e.target.value;
                  if (/^[a-zA-Z]*$/.test(input)) {
                    setSafetyInfo({
                      ...safetyInfo,
                      parentLastName: input,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "parentLastName"
                    );
                  }
                }}
              />
              <div
                className={`${
                  invalidFields.includes("parentLastName") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter last name</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group email-group">
              <label className="form-label">Parent-Guardian Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="example@gmail.com"
                value={safetyInfo.parentEmail}
                disabled={edit}
                onChange={(e) => {
                  setSafetyInfo({ ...safetyInfo, parentEmail: e.target.value });
                  updateMultipleErrorFields(
                    invalidFields,
                    setInvalidFields,
                    ["parentEmail", "sameEmail"]
                  );
                }}
                autoComplete="new-email"
              />
              <div className="email-icon">
                <img src={email} />
              </div>
              <div
                className={`${
                  invalidFields.includes("parentEmail") || invalidFields.includes("sameEmail")? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">{` ${invalidFields.includes("parentEmail") ? "Please enter email address" : "Parent/guardian's email should be different then Sitter's email "} `}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group cstm-input">
              <label className="form-label">Parent-Guardian Cell Phone *</label>
              <PhoneInput
                disabled={edit}
                countryCodeEditable={false}
                country={"us"}
                onlyCountries={["us"]}
                value={safetyInfo.parentPhoneNo}
                onChange={(phone) => {
                  setSafetyInfo({ ...safetyInfo, parentPhoneNo: phone });
                  updateMultipleErrorFields(
                    invalidFields,
                    setInvalidFields,
                    ["parentPhoneNo", "invalid-parentPhoneNo"]
                  );
                }}
              />
              <div
                className={`${
                  invalidFields.includes("parentPhoneNo") || invalidFields.includes("invalid-parentPhoneNo") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">{
                 invalidFields.includes("parentPhoneNo") ? 
                  'Please enter valid Phone Number' : 'Contact number should not starts with zero'}
                </p>
              </div>
            </div>
          </div>
        </form>
        <div className={`d-flex justify-content-center mt-5 ${edit ? "d-none" : ""}`}>
          <button className="btn btn-theme mb-3" onClick={formValidation}>
          {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <React.Fragment>{edit ? "Update" : "Next"}</React.Fragment>
            )}
          </button>
        </div>
        <p className="m-auto text-center page-discription">{location.pathname === "/register" ? "" : "This information has been verified by your parent / guardian and cannot be changed.  The only way to update it is to delete your account and re-register." }</p>
      </div>
    </React.Fragment>
  );
};

export default SafetyInfoForm;
