import { svcconfig } from "../../helpers/api-services";
import useAuth from "../useAuth";
import useAxiosPrivate from "../useAxiosPrivate";
import useChat from "../useChat";

const CHAT_URL = process.env.REACT_APP_API_URL + "/chat";

const useChatServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const {twilioToken} = useChat();

  const generateTwilioChatToken = () => {
    let apiURL = `${CHAT_URL}/token/${auth.email}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const storeUserChannelData = (data: any) => {
    let apiURL = `${CHAT_URL}/store`;
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const getParticipantData = (data: any) => {
    let apiURL = `${CHAT_URL}/room`;
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const getParticipantsList = (data: any) => {
    let apiURL = `${CHAT_URL}/userChats`;
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const sendNotificationToOfflineUser = (data: any) => {
    let apiURL = `${CHAT_URL}/timer`;
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token, twilioToken) });
  }

  const chatServices = {
    generateTwilioChatToken,
    storeUserChannelData,
    getParticipantData,
    getParticipantsList,
    sendNotificationToOfflineUser
  };

  return chatServices;
};

export default useChatServices;
