import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";


const LogoutOnlyRoutes = () => {
  const { auth } = useAuth();

  return  auth?.token ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default LogoutOnlyRoutes;
