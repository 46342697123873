import { useState, useEffect } from "react";
import people from "../assets/images/people.svg";
import clock from "../assets/images/clock.svg";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import "../assets/css/table.scss";

import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useParentServices from "../hooks/web-services-hooks/useParentServices";
import { getHourDiff, isPastDate } from "../helpers/utilities";
import Deletemodal from "./Deletemodal";
import { MAX_RESULTS_PER_PAGE } from "../helpers/constants";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import usePaymentServices from "../hooks/web-services-hooks/usePaymentServices";
import BSSpinner from "./BSSpinner";
import AlertToasts from "../services/notification-services/notifications";
import React from "react";
import moment from "moment";

type IDeleteModelInfo = {
  show: boolean;
  action: string;
  id: string;
  setJobData: Function;
};
const ParentProfile = () => {
  const [page, setPage] = useState<number>(1);
  const [jobData, setJobData] = useState([]);
  const [isBSList, setIsBSList] = useState<Boolean>(false);
  const [deleteModelInfo, setDeleteModelInfo] = useState<IDeleteModelInfo>({
    show: false,
    action: "job",
    id: "",
    setJobData: setJobData,
  });
  const [activeJob, setActiveJob] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState<Boolean>(false);
  const [loader, setLoader] = useState<Boolean>(false);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const ParentServices = useParentServices();
  const PaymentServices = usePaymentServices();
  const AuthServices = useAuthServices();

  const manageSubscription = () => {
    if (isSubscribed) {
      navigate("/manage-subscription");
    } else {
      AlertToasts.info("No Active Subscription plans");
    }
  };

  useEffect(() => {
    setLoader(true);
    ParentServices.parentProfile(auth._id).then((res) => {
      setJobData(res.data.data[1]);
    });
    PaymentServices.currentPlan(auth._id).then((res) => {
      //If plan is Monthly or Yearly response will be array of obj. otherwise it retures blank array
      if (res.status === 200 && res.data.data.length > 0) {
        let subscriptionData = res.data.data[0];
        if (
          !subscriptionData.isCancelled &&
          (subscriptionData.subscriptionType === "Monthly" ||
            subscriptionData.subscriptionType === "Yearly")
        ) {
          setIsSubscribed(true);
        } else if (
          subscriptionData.isCancelled &&
          !isPastDate(subscriptionData.subsriptionEndDate)
        ) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
      }
    });
    ParentServices.interestedSitters(auth._id).then((res: any) => {
      if (res.status === 200 && res.data.data.length) {
        setIsBSList(true);
      }
    });
    setLoader(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoader(true);
    // alert(new Date());
    let active = jobData.filter((job: any, id: any) => {

      const currentDate = moment(moment().format("MM-DD-YYYY"));
      const endDate = moment(moment(job?.endDate).format("MM-DD-YYYY"));
      return job?.isActive === true && !endDate.isBefore(currentDate);
    });
    setActiveJob(active);
    setLoader(false);
  }, [jobData]);

  const deleteAccount = (userId: string) => {
    setDeleteModelInfo({
      ...deleteModelInfo,
      action: "account",
      show: true,
      id: userId,
    });
  };

  const deleteJob = (jobId: string) => {
    setDeleteModelInfo({ ...deleteModelInfo, show: true, id: jobId });
  };

  return (
    <>
      <div className="table-page box-outside-view job-page">
        <div className="container bg-white pb-5 px-md-5 rounded mt-5 padding-top-58">
          <p className="sub-title mt-3 mb-2 d-flex justify-content-md-start justify-content-center">
            {`${auth.firstName} ${auth.lastName}`}
          </p>

          <div className="col-12 d-md-flex filter-container mx-md-0 mx-auto w-100 text-center">
            <div className="col-md-auto col-12 d-md-flex mt-3 mb-1 me-md-2">
              <button
                className="btn btn-theme-light justify-content-center d-md-block d-flex mx-md-0 mx-auto w-100"
                onClick={() => navigate("/message-center")}
              >
                Messages
              </button>
            </div>
            {isSubscribed ? (
              <div className="col-md-auto col-12 d-md-flex mt-3 mb-1 mx-md-2 mx-auto">
                <a
                  className="btn btn-theme-light justify-content-center d-md-block d-flex mx-md-0 mx-auto w-100"
                  onClick={manageSubscription}
                >
                  Manage Subscription
                </a>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <div className="col-md-auto col-12 d-md-flex mt-3 mb-1 mx-md-2 mx-auto">
              <button
                className="btn btn-theme-light justify-content-center d-md-block d-flex mx-md-0 mx-auto  w-100"
                onClick={() => navigate("/edit-preferences")}
              >
                Edit Babysitter Preferences
              </button>
            </div>
          </div>
          <div className="col-12 d-lg-flex filter-container mx-md-0 mx-auto  w-100">
            <div className="col-lg-6 col-12 d-md-flex mx-md-0 mx-auto">
              <button
                className="btn btn-theme-light justify-content-center d-lg-block d-flex my-3 ms-md-0  me-md-2 mx-auto  w-100"
                onClick={() => {
                  navigate("/post-a-job");
                }}
              >
                Post A Job
              </button>
              <button
                className="btn btn-theme-light justify-content-center d-lg-block d-flex my-3 mx-md-2 mx-auto w-100"
                onClick={() => navigate("/interested-sitters")}
              >
                View Interested Sitters
              </button>
            </div>
            <div className="col-lg-6 col-12 d-md-flex mx-md-0 mx-auto">
              <button
                className="btn btn-theme-light justify-content-center d-lg-block d-flex my-3 me-md-2 mx-auto w-100"
                onClick={() => navigate("/edit-profile")}
              >
                Edit Your Information
              </button>
              <button
                className="btn btn-theme-light justify-content-center d-lg-block d-flex my-3 mx-sm-2 mx-auto w-100"
                onClick={() => deleteAccount(auth._id)}
              >
                Delete Your Account
              </button>
            </div>
          </div>
          <p className="font-16 font-style font-black-dark">
            Active Babysitting Job Postings
          </p>

          {loader ? (
            <BSSpinner />
          ) : (
            activeJob
              .slice(
                MAX_RESULTS_PER_PAGE * (page - 1),
                MAX_RESULTS_PER_PAGE * page
              )
              .map((details: any, index: any) =>
                details.isActive ? (
                  <div key={index}>
                    <div className="col-12 d-flex d-unset details px-0">
                      <div className="col-md-4 col-6 d-flex">
                        {/* {details.startDate.split("T")[0].replaceAll("-", "/")} - */}
                        {moment(details.startDate).format("MM/DD/YYYY")} - Job
                        Date
                      </div>
                      <div className="col-md-8 col-6 d-md-flex">
                        <div
                          className="col-md-6 col-12 d-flex justify-content-md-center justify-content-end cursor-pointer profile-heading"
                          onClick={() =>
                            navigate(`/job-summary/${details._id}`)
                          }
                        >
                          <a>Job Details</a>
                        </div>
                        <div
                          className="col-md-6 col-12 d-flex justify-content-md-center justify-content-end cursor-pointer profile-heading"
                          onClick={() => deleteJob(details._id)}
                        >
                          <a>Delete This Job </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex sub-details justify-content-start px-0">
                      <div className="col-5 d-flex px-lg-5 px-sm-3 align-items-center">
                        <img src={people} alt="" />
                        <span className="px-2">
                          {details.kids.length} Children
                        </span>
                      </div>
                      <div className="col-4 d-flex align-items-center justify-content-md-start justify-content-end">
                        <img src={clock} alt="" />
                        <span className="px-2">
                          {getHourDiff(details.endTime, details.startTime)}{" "}
                          hours
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )
          )}
          <div className="col-12 d-flex justify-content-center px-0">
            <Pagination
              count={Math.ceil(activeJob.length / MAX_RESULTS_PER_PAGE)}
              defaultPage={1}
              page={page}
              onChange={(e, page) => setPage(page)}
              showFirstButton
              showLastButton
              size="small"
              className="paginate-btn"
            />
          </div>
        </div>
      </div>

      {deleteModelInfo.show ? (
        <Deletemodal
          handleClose={() =>
            setDeleteModelInfo({ ...deleteModelInfo, show: false })
          }
          details={deleteModelInfo}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ParentProfile;
