import { svcconfig } from "../../helpers/api-services";
import useAuth from "../useAuth";
import useAxiosPrivate from "../useAxiosPrivate";

const AUTH_URL = process.env.REACT_APP_API_URL + "/auth";

const useAuthServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const userLogin = (credentials: Object) => {
    const apiURL = `${AUTH_URL}/login`;
    return axiosPrivate.post(apiURL, credentials, { headers: svcconfig() });
  };

  const userLogOut = () => {
    const apiURL = `${AUTH_URL}/logout/${auth._id}`;
    return axiosPrivate.post(
      apiURL,
      {},
      { headers: svcconfig(auth.token), withCredentials: true }
    );
  };

  const forgotPassword = (email: string) => {
    const apiURL = `${AUTH_URL}/forgotPassword`;
    return axiosPrivate.post(apiURL, { email }, { headers: svcconfig() });
  };

  const validateResetPasswordLink = (token: string) => {
    const apiURL = `${AUTH_URL}/verifyToken`;
    return axiosPrivate.post(apiURL, { token }, { headers: svcconfig() });
  };

  const resetPassword = (credentials: Object) => {
    const apiURL = `${AUTH_URL}/updatePassword`;
    return axiosPrivate.post(
      apiURL,
      { ...credentials },
      { headers: svcconfig() }
    );
  };

  const resendVerificationLink = (data: Object) => {
    const apiURL = `${AUTH_URL}/resendverification`;
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const verifyEmailAddress = (token: string) => {
    const apiURL = `${AUTH_URL}/verifyaccount/${token}`;
    return axiosPrivate.post(apiURL, {}, { headers: svcconfig(auth.token) });
  };

  const contactUs = (credentials: Object) => {
    const apiURL = `${AUTH_URL}/contactUs`;
    return axiosPrivate.post(apiURL, credentials, { headers: svcconfig() });
  };

  const deleteAccount = (id: string) => {
    const apiURL = `${AUTH_URL}/deleteAccount/${id}`;
    return axiosPrivate.patch(apiURL, {}, { headers: svcconfig(auth.token) });
  };

  const authServices = {
    userLogin,
    userLogOut,
    forgotPassword,
    validateResetPasswordLink,
    resetPassword,
    contactUs,
    deleteAccount,
    resendVerificationLink,
    verifyEmailAddress
  };

  return authServices;
};

export default useAuthServices;
