import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/auth.scss";
import { handleErrorMessage } from "../helpers/utilities";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import AlertToasts from "../services/notification-services/notifications";

const ConfirmationMail = () => {

  const AuthServices = useAuthServices();
  const {token} = useParams();
  const navigate = useNavigate();

  const verifyAccount = async() => {
    if(token)
    {
      try {
        const response = await AuthServices.verifyEmailAddress(token);
        if(response.data.success)
        {
          AlertToasts.success("Your Email Address has been verified");
          navigate("/");
        }
      }
      catch(e)
      {
        handleErrorMessage(e);
      }
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <h1 className="text-center page-title my-5 text-uppercase">
            Thanks For Confirming
          </h1>
        </div>
        <div className="row align-items-center justify-content-center px-2">
          <div className="col-12 col-md-6 mx-2 mx-md-0">
            <div className="bg-white">
              <div className="row col-12 mt-3 align-items-center">
                <p className="text-center font-style conf-mail-title mb-4 mt-3">
                  Please click on the confirm button below in order to complete the email address verification
                </p>
              </div>
              <div className="row justify-content-center align-items-center login-box"></div>

              <div className="row justify-content-center mt-2 mb-2">
                <button className="btn btn-theme w-auto mb-3" onClick={verifyAccount}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationMail;
