import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../assets/images/logo.svg";
import "../assets/css/common.scss";
import usePaymentServices from '../hooks/web-services-hooks/usePaymentServices';
import { handleErrorMessage } from '../helpers/utilities';
import AlertToasts from "../services/notification-services/notifications";
import moment from "moment";
import { useNavigate } from "react-router-dom";

type Props = {
    show: boolean,
    handleClose: Function,
    subscriptionDetails: any
}

const CancelSubscriptionModal = ({show, handleClose, subscriptionDetails} : Props) => {

    const PaymentServices = usePaymentServices();
    const navigate = useNavigate();

    const cancelSubscription = async() => {
        try {
            const response = await PaymentServices.cancelSubscription();
            if(response.data.success)
            {
                AlertToasts.info("Subscription Cancelled");
                handleClose();
                navigate("/");
            }
        }
        catch(e)
        {
            handleErrorMessage(e);
        }
    }

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      className="delete-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="p-0 border-0">
        <img
          src={logo}
          className="col-12 d-flex justify-content-center mx-auto"
        />
      </Modal.Header>
      <Modal.Body className="padding-small">
        <div className="mt-2 mx-auto text-unset">
          Are you sure you want to Cancel your ongoing Subscription?{" "}
        </div>
        <div className="mt-3 mx-auto font-16">
           Your current plan will be valid till {moment(subscriptionDetails.subsriptionEndDate).format("LLL")} and after that you need to buy a new plan in order to get new baby sitters
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="btn btn-no"
          onClick={() => {
            handleClose();
          }}
        >
          No
        </Button>
        <Button
          className="btn btn-yes"
          onClick={() => {
            cancelSubscription();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CancelSubscriptionModal