// import React from "react";
// import "../assets/css/common.scss";
// import "../components/storyPage.scss";
// import about_1 from "../assets/images/about.png";
// import testimonial from "../assets/images/testimonial.png";

// const OurStory = () => {
//   // Update meta description directly
//   document.title = "Babysitting Created by and for Highschoolers";

//   const metaDescription = document.querySelector('meta[name="description"]');
//   if (metaDescription) {
//     metaDescription.setAttribute(
//       "content",
//       "We created HighSchoolBabysitters.com to connect highschoolers searching for babysitting jobs and parents of young children in need of child care."
//     );
//   }
//   return (
//     <div>
//       <div className="parent-layout">
//         <p className="page-title text-center text-uppercase mt-3 mb-4">
//           Our Story
//         </p>
//         <div>
//           <div className="row layout-box ">
//             <div className="col-12 col-md-8">
//               <p className="text-uppercase section-first-title">
//                 created by a highschooler for highschoolers
//               </p>
//               <p className="description-style pb-sm-4 pb-0">
//                 HighschoolBabysitters.com was created to connect highschoolers
//                 looking for babysitting jobs and parents of little kids trying
//                 to find highschool babysitters.{" "}
//               </p>
//             </div>
//             <div className="d-md-block d-none col-md-4 position-relative">
//               <div className="circle-one">
//                 <img
//                   src={about_1}
//                   alt="Your SVG"
//                   className="circle-img-one img-thumbail"
//                 />
//               </div>
//             </div>
//           </div>

//           <div className="d-block d-md-none position-relative">
//             <div className="circle-one">
//               <img
//                 src={about_1}
//                 alt="Your SVG"
//                 className="mobile-circle img-thumbail"
//               />
//             </div>
//           </div>

//           <div className="row layout-box box-two-top">
//             <div className="col-md-4 d-md-block d-none position-relative">
//               <div className="circle-two">
//                 <img
//                   src={testimonial}
//                   alt="Your SVG"
//                   className="circle-img img-thumbail"
//                 />
//               </div>
//             </div>
//             <div className="col-12 col-md-8">
//               <p className="text-uppercase section-first-title">Meet aspen</p>
//               <p className="description-style pb-sm-4 pb-0 ms-auto">
//                 {" "}
//                 I am a Sophomore in high school in Colorado. I want to thank you
//                 for using HighSchoolBabysitters.com. I made this website for all
//                 of you. I hope it can help all my friends find more babysitting
//                 jobs and give parents of little kids a way to find high school
//                 babysitters in their home towns.{" "}
//               </p>
//             </div>
//           </div>
//           <div className="d-block d-md-none position-relative">
//             <div className="circle-two">
//               <img
//                 src={testimonial}
//                 alt="Your SVG"
//                 className="mobile-circle img-thumbail"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

    // <div >
    //  <div className="parent-layout">
    //     <p className='page-title text-center text-uppercase mt-3 mb-4'>Our Story</p>
    //     <div>
    //         <div className="row layout-box ">
    //             <div className="col-12 col-md-8">

    //                 <p className='text-uppercase section-first-title'>created by a highschooler for highschoolers</p>
    //                 <p className='description-style pb-4'>HighschoolBabysitters.com was created to connect highschoolers looking for babysitting jobs and parents of little kids trying to find highschool babysitters.  </p>

    //                </div>
    //                 <div className="d-md-block d-none col-md-4 position-relative">
    //                <div className="circle-one">
    //               <img src={circle} alt="Your SVG"  className='circle-img-one'/>
    //             </div>
    //             </div>
    //         </div>

    //         <div  className="d-block d-md-none position-relative">
    //                <div className='circle-one'>
    //               <img src={circle} alt="Your SVG"  style={{height:"120px",width:"120px"}}/>
    //             </div>
    //             </div>

    //         <div className="row layout-box" style={{marginTop:"123px"}}>

    //             <div className="col-md-4 d-md-block d-none position-relative">
    //             <div className='circle-two'>
    //               <img src={circle} alt="Your SVG"  className='circle-img'/>
    //             </div>
    //             </div>
    //     <div className='col-12 col-md-8'>
    //                 <p className='text-uppercase section-first-title'>Meet aspen</p>
    //                 <p  className="description-style pb-4"> I am a Sophmore in Highschool in Evergreen Colorado.  I want to thank you for using HighschoolBabysitters.com.  I made this website for all of you.  I hope it can help my friends find more babysitting jobs and give parents of little kids find more highschool babysitters in their home towns. </p>
    //                 </div>
    //         </div>
    //         <div className="d-block d-md-none position-relative">
    //             <div className='circle-two'>
    //               <img src={circle} alt="Your SVG"  style={{height:"120px",width:"120px"}}/>
    //             </div>
    //             </div>
    //     </div>
    //  </div>
    // </div>
//   );
// };

// export default OurStory;

// New Code start from this
import React from 'react'
import circle from '../assets/images/circle.svg'
import "../assets/css/common.scss";
import "../components/storyPage.scss";
import about_1 from "../assets/images/about.png";
import testimonial from "../assets/images/girl_img_about.png";

const OurStory = () => {
  return (
    <div >
     <div className="about-us-parent-layout">
        <p className='page-title text-center text-uppercase mt-4 mb-lg-5 mb-4  about_tittle'>About us</p>
        <div>
            <div className="row layout-box  d-flex justify-content-between set_image_gap_mobile ourstory_card">
                <div className="col-12 col-md-8 px-sm-5 px-0 py-3  first_card">

                    <p className='section-first-title'>Created by a Highschooler For Highschoolers</p>
                    <p className='description-style pb-sm-4 pb-0 px-sm-0 px-3 text-sm-start text-center'>HighschoolBabysitters.com was created to connect highschoolers looking for babysitting jobs and parents of little kids trying to find highschool babysitters.  </p>

                   </div>
                    <div className="d-md-block d-none col-md-4 position-relative">
                   <div className="circle-one">
                  <img src={about_1} alt="Your SVG"  className='circle-img-one'/>
                </div>
                </div>
            </div>

            <div  className="d-block d-md-none position-relative">
                   <div className='circle-one'>
                  <img src={about_1} alt="Your SVG" className='img_size_mobile' width={'120px'} height={'120px'}
                  />
                </div>
                </div>

            <div className="row layout-box margin-bottom-about-us set_margin_top_image_mobile aspen ourstory_card2">

                <div className="col-md-4 d-md-block d-none position-relative p-0">
                <div className='circle-two'>
                  <img src={testimonial} alt="Your SVG"  className='circle-img'/>
                </div>
                </div>
        <div className='col-12 col-md-8 px-sm-5 px-0 py-3 first_card'>
                    <p className='section-first-title'>Meet Aspen</p>
                    <p className="description-style text-sm-start text-center d-md-block d-none">As a current Senior at Evergreen High School, I am actively involved both at my school and in my community. I have always had a love for business and as I have gotten older that interest has turned into my own company, HighSchoolBabysitters.com! Since I launched High School Babysitters in 2023 I have been passionate about providing others with the same resources I was given to learn about business and grow their own companies. In the future I am hoping to continue to help others expand their knowledge and I hope to explore my interests in the intersection of law and business when I head to college.  <p className='d-sm-none d-sm-none description-style setbreakbetween text-sm-start text-center'>Since I launched High School Babysitters in 2023 I have been passionate about providing others with the same resources I was given to learn about business and grow their own companies. In the future I am hoping to continue to help others expand their knowledge and I hope to explore my interests in the intersection of law and business when I head to college. </p></p>
                    <p className="description-style  text-sm-start text-center d-md-none d-block">As a current Senior at Evergreen High School, I am actively involved both at my school and in my community. I have always had a love for business and as I have gotten older that interest has turned into my own company, HighSchoolBabysitters.com! <span className='d-sm-block d-none'>Since I launched High School Babysitters in 2023 I have been passionate about providing others with the same resources I was given to learn about business and grow their own companies. In the future I am hoping to continue to help others expand their knowledge and I hope to explore my interests in the intersection of law and business when I head to college. </span> <p className='d-sm-none d-sm-none description-style setbreakbetween text-sm-start text-center'>Since I launched High School Babysitters in 2023 I have been passionate about providing others with the same resources I was given to learn about business and grow their own companies. In the future I am hoping to continue to help others expand their knowledge and I hope to explore my interests in the intersection of law and business when I head to college. </p></p>

                    </div>
            </div>
               <div className="d-block d-md-none position-relative">
                <div className='circle-two'>
                  <img src={testimonial} alt="Your SVG" className='img_size_mobile' width={'120px'} height={'120px'}/>
                </div>
                </div>
        </div>
     </div>
    </div>

      

      



  )
}

export default OurStory

// Updated Code Start from this
// import React from 'react'
// import circle from '../assets/images/circle.svg'
// import "../assets/css/common.scss";
// import "../components/storyPage.scss";
// import about_1 from "../assets/images/about.png";
// import testimonial from "../assets/images/girl_img_about.png";

// function OurStory() {
//   return (
//     <div >
//      <div className="about-us-parent-layout">
//         <p className='page-title text-center text-uppercase mt-4 mb-lg-5 mb-4  about_tittle'>About us</p>
//         <div>
//             {/* <div className="row layout-box d-flex justify-content-between set_image_gap_mobile ourstory_card">
//                 <div className="col-12 col-md-7 px-sm-5 px-0 py-3 first_card">

//                     <p className='section-first-title'>Created by a Highschooler For Highschoolers</p>
//                     <p className='description-style pb-sm-4 pb-0 text-sm-start text-center'>HighschoolBabysitters.com was created to connect highschoolers looking for babysitting jobs and parents of little kids trying to find highschool babysitters.  </p>

//                    </div>
//                     <div className="d-md-block d-none col-md-4 position-relative">
//                    <div className="circle-one">
//                   <img src={about_1} alt="Your SVG"  className='circle-img-one'/>
//                 </div>
//                 </div>
//             </div>

//             <div  className="d-block d-md-none position-relative">
//                    <div className='circle-one'>
//                   <img src={about_1} alt="Your SVG" className='img_size_mobile' width={'120px'} height={'120px'}
//                   />
//                 </div>
//                 </div> */}
//                 <div className='container'>
//                   <div className='row bg-white layout-box margin-bottom-about-us set_margin_top_image_mobile aspen ourstory_card2'>
//                     <div className='col-md-8 px-sm-5 px-0 py-3 first_card'>
//                     <p className='section-first-title'>Created by a Highschooler For Highschoolers</p>
//                     <p className='description-style pb-sm-4 pb-0 text-sm-start text-center'>HighschoolBabysitters.com was created to connect highschoolers looking for babysitting jobs and parents of little kids trying to find highschool babysitters.  </p>
//                     </div>
//                     <div className='col-md-4'>
//                     <div className="d-md-block d-none col-md-4 position-relative">
//                    <div className="circle-circle-one">
//                   <img src={about_1} alt="Your SVG"  className='circle-img-one'/>
//                 </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='row bg-white layout-box margin-bottom-about-us set_margin_top_image_mobile aspen ourstory_card2'>
//                 <div className="col-md-4 d-md-block d-none position-relative p-0">
//                 <div className='circle-circle-two'>
//                   <img src={testimonial} alt="Your SVG"  className='circle-img'/>
//                 </div>
//                 </div>
//                 <div className='col-12 col-md-8 px-sm-5 px-0 py-3 first_card'>
//                     <p className='section-first-title'>Meet Aspen</p>
//                     <p  className="description-style  text-sm-start text-center">As a current Senior at Evergreen High School, I am actively involved both at my school and in my community. I have always had a love for business and as I have gotten older that interest has turned into my own company, HighSchoolBabysitters.com! Since I launched High School Babysitters in 2023 I have been passionate about providing others with the same resources I was given to learn about business and grow their own companies. In the future I am hoping to continue to help others expand their knowledge and I hope to explore my interests in the intersection of law and business when I head to college. </p>
//                     </div>
//                 </div>

//             {/* <div className="row layout-box margin-bottom-about-us set_margin_top_image_mobile aspen ourstory_card2">

//                 <div className="col-md-4 d-md-block d-none position-relative p-0">
//                 <div className='circle-two'>
//                   <img src={testimonial} alt="Your SVG"  className='circle-img'/>
//                 </div>
//                 </div>
//         <div className='col-12 col-md-8 px-sm-5 px-0 py-3 first_card'>
//                     <p className='section-first-title'>Meet Aspen</p>
//                     <p  className="description-style  text-sm-start text-center">As a current Senior at Evergreen High School, I am actively involved both at my school and in my community. I have always had a love for business and as I have gotten older that interest has turned into my own company, HighSchoolBabysitters.com! Since I launched High School Babysitters in 2023 I have been passionate about providing others with the same resources I was given to learn about business and grow their own companies. In the future I am hoping to continue to help others expand their knowledge and I hope to explore my interests in the intersection of law and business when I head to college. </p>
//                     </div>
//             </div>
//                <div className="d-block d-md-none position-relative">
//                 <div className='circle-two'>
//                   <img src={testimonial} alt="Your SVG" className='img_size_mobile' width={'120px'} height={'120px'}/>
//                 </div>
//                 </div> */}
//         </div>
//      </div>
//     </div>
//     </div>
//   )
// }

// export default OurStory;
