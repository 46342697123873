import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "../assets/css/common.scss";
import {
  handleErrorMessage,
  updateErrorFields,
  updateMultipleErrorFields,
} from "../helpers/utilities";
import useAuth from "../hooks/useAuth";
import useChatServices from "../hooks/web-services-hooks/useChatServices";
import usePaymentServices from "../hooks/web-services-hooks/usePaymentServices";
import { IPaymentModel } from "../models/IPaymentModel";
import AlertToasts from "../services/notification-services/notifications";
import ReactDatePicker from "./ReactDatePicker";
import moment from "moment";

const Payment = () => {
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [loader, setLoader] = useState<Boolean>(false);
  const [cardData, setCardData] = useState<IPaymentModel>({
    paymentType: "",
    cardFirstName: "",
    cardLastName: "",
    cardNo: "",
    cardExpiry: "",
    cardCvv: "",
    cardZipCode: "",
  });
  const PaymentServices = usePaymentServices();
  const ChatServices = useChatServices();
  const { auth } = useAuth();
  const cardNumValidator =
    /^((4\d{3})|(5[1-5]\d{2})|(6011)|(34\d{1})|(37\d{1}))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7][\d\s-]{15}$/;

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = () => {
    setLoader(true);
    let inputData: any = {};
    /**Static Data For Sandbox: Testing - temporary */
    // inputData.cardCvv = "314";
    // inputData.cardNo = "4242424242424242";
    // inputData.cardExpiry = "08/2023";

    /**Dynamic Data For Production */
    inputData.cardCvv = cardData.cardCvv;
    inputData.cardNo = cardData.cardNo;
    inputData.cardExpiry = moment(cardData.cardExpiry).format("MM/YYYY");

    inputData.sitterId = location.state?.sitterId;
    inputData.jobId = location.state?.jobId;
    inputData.paymentType = cardData.paymentType;
    inputData.cardFirstName = cardData.cardFirstName;
    inputData.cardLastName = cardData.cardLastName;
    inputData.cardZipCode = cardData.cardZipCode;

    PaymentServices.checkOut(inputData)
      .then(async (res: { status: number }) => {
        if (res.status === 200) {
          AlertToasts.success("Payment done successfully");
          try {
            const response = await ChatServices.storeUserChannelData({
              parentEmail: auth.email,
              parentId: auth._id,
              babySitterEmail: location.state?.sitterEmail,
              babySitterId: location.state?.sitterId,
              parentFullName: `${auth.firstName}  ${auth.lastName}`,
              babySitterFullName: location.state?.fullName,
              jobId: location.state?.jobId,
              room: crypto.randomUUID(),
            });
            if (response.data.success) {
              navigate("/message-center");
            }
            setLoader(false);
          } catch (e) {
            handleErrorMessage(e);
          }
        }
      })
      .catch((e: any) => handleErrorMessage(e))
      .finally(() => setLoader(false));
  };

  const formValidation = () => {
    let validationResult: Boolean = true;
    let ErrorFields: string[] = [];
    if (cardData) {
      Object.keys(cardData).forEach((key: string) => {
        if (
          Object.hasOwn(cardData, key) &&
          (cardData[key as keyof typeof cardData] === "" ||
            cardData[key as keyof typeof cardData] === null)
        ) {
          validationResult = false;
          ErrorFields.push(key);
        }
      });
    }
    if (cardData.cardNo.length !== 19) {
      validationResult = false;
      ErrorFields.push("invalid-cardNo");
    }
    if (cardData.cardCvv.length < 3) {
      validationResult = false;
      ErrorFields.push("invalid-cardCVV");
    }
    if (
      !cardData.cardZipCode.length ||
      cardData.cardZipCode.length > 6 ||
      cardData.cardZipCode.length < 5
    ) {
      validationResult = false;
      ErrorFields.push("cardZipCode");
    }
    setInvalidFields(ErrorFields);
    if (validationResult) {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="box-outside-view">
        <p className="page-title text-center text-uppercase mt-3 mb-sm-4 mb-2">
          PAYMENT
        </p>
        <div className="container bg-white pb-5 px-md-5 rounded padding-top-56">
          <p className="page-subtitle text-center text-uppercase mt-3 mb-2">
            GOOD NEWS, WE FOUND
          </p>
          <p className="page-subtitle text-center text-uppercase mt-3 mb-4">
            YOU A BABYSITTER!
          </p>
          <p className="page-sub-desc col-xl-6 col-lg-9 col-sm-8 px-xl-4 px-lg-3 px-md-2 px-1 mx-auto">
            After your payment we will text the babysitter you chose and set up
            a chat in our message system that will allow you to exchange
            information and discuss any details about your upcoming babysitting
            job.
          </p>
          <h3 className="font-20 fw-600 font-style text-center my-4">
            Choose a Payment Option
          </h3>
          <div className="row mx-0 px-0 ctsm-radio justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-11">
              <div className="form-check ">
                <input
                  className="form-check-input col-auto me-md-5  me-2"
                  type="radio"
                  name="flexRadioDefault"
                  value="oneTime"
                  id="flexRadioDefault1"
                  checked={cardData.paymentType === "oneTime"}
                  onChange={(e: any) => {
                    setCardData({ ...cardData, paymentType: e.target.value });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "paymentType"
                    );
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  One time $10.00 Match Fee
                </label>
              </div>
            </div>
            <div className="col-xl-7 col-lg-9 col-md-11 my-3">
              <div className="form-check ">
                <input
                  className="form-check-input col-auto me-md-5  me-2"
                  type="radio"
                  name="flexRadioDefault"
                  value="monthly"
                  id="flexRadioDefault2"
                  checked={cardData.paymentType === "monthly"}
                  onChange={(e: any) => {
                    setCardData({ ...cardData, paymentType: e.target.value });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "paymentType"
                    );
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  6 Months of Unlimited Matches $10.00 / Month for 6 Months
                </label>
              </div>
            </div>
            <div className="col-xl-7 col-lg-9 col-md-11">
              <div className="form-check ">
                <input
                  className="form-check-input col-auto me-md-5  me-2"
                  type="radio"
                  name="flexRadioDefault"
                  value="yearly"
                  id="flexRadioDefault3"
                  checked={cardData.paymentType === "yearly"}
                  onChange={(e: any) => {
                    setCardData({ ...cardData, paymentType: e.target.value });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "paymentType"
                    );
                  }}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  1 year of Unlimited Matches $5.00 / Month for 12 Months
                </label>
              </div>
              <div
                className={`${
                  invalidFields.includes("paymentType") ? "" : "d-none"
                }`}
              >
                <p className="error-msg m-0 text-center mt-3">
                  {" "}
                  Choose any of the Payment Options.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row py-3 my-3 px-lg-5 mx-auto">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label mt-3">Name on Card - First</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="cardFirstName"
                  value={cardData.cardFirstName}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (/^[a-zA-Z]*$/.test(input)) {
                      setCardData({
                        ...cardData,
                        cardFirstName: input,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "cardFirstName"
                      );
                    }
                  }}
                />
                <div
                  className={`${
                    invalidFields.includes("cardFirstName") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> This field is required. </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label mt-3">Name on Card - Last</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  name="cardLastName"
                  value={cardData.cardLastName}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (/^[a-zA-Z]*$/.test(input)) {
                      setCardData({
                        ...cardData,
                        cardLastName: input,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "cardLastName"
                      );
                    }
                  }}
                />
                <div
                  className={`${
                    invalidFields.includes("cardLastName") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> This field is required</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label mt-3">Credit Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="xxxx xxxx xxxx xxxx"
                  name="cardNo"
                  value={cardData.cardNo}
                  onChange={(e) => {
                    if (e.target.value.length < 20) {
                      let cardNo = e.target.value
                        .replace(/[^0-9]/gi, "")
                        .replace(/(.{4})/g, "$1 ")
                        .trim();
                      setCardData({
                        ...cardData,
                        cardNo,
                      });
                      updateMultipleErrorFields(
                        invalidFields,
                        setInvalidFields,
                        ["cardNo", "invalid-cardNo"]
                      );
                    }
                  }}
                />
                <div
                  className={`${
                    invalidFields.includes("cardNo") ||
                    invalidFields.includes("invalid-cardNo")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <p className="error-msg m-0">
                    {" "}
                    {`${
                      invalidFields.includes("cardNo")
                        ? "This field is required"
                        : invalidFields.includes("invalid-cardNo")
                        ? "Enter valid card number"
                        : ""
                    }  `}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group date-picker-cutm">
                <label className="form-label mt-3">Expiration Date</label>
                <ReactDatePicker
                  className="form-control"
                  placeholderText="MM/YYYY"
                  showMonthYearPicker={true}
                  dateFormat="MM/yyyy"
                  minDate={new Date()}
                  selected={cardData.cardExpiry}
                  onChange={(e: any) => {
                    setCardData({ ...cardData, cardExpiry: e });
                    updateMultipleErrorFields(invalidFields, setInvalidFields, [
                      "cardExpiry",
                      "invalid-cardExpiry",
                    ]);
                  }}
                  onFocus={(e:any) => e.target.blur()}
                />
                <div
                  className={`${
                    invalidFields.includes("cardExpiry") ||
                    invalidFields.includes("invalid-cardExpiry")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <p className="error-msg m-0">{`${
                    invalidFields.includes("cardExpiry")
                      ? "This field is required"
                      : invalidFields.includes("invalid-cardExpiry")
                      ? "Enter valid expiry date."
                      : ""
                  } `}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label mt-3">CVV</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="***"
                  name="cardCvv"
                  value={cardData.cardCvv}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (/^[0-9]+$/.test(input) && input.length < 4) {
                      setCardData({
                        ...cardData,
                        cardCvv: input,
                      });
                      updateMultipleErrorFields(
                        invalidFields,
                        setInvalidFields,
                        ["cardCvv", "invalid-cardCVV"]
                      );
                    }
                  }}
                />
                <div
                  className={`${
                    invalidFields.includes("cardCvv") ||
                    invalidFields.includes("invalid-cardCVV")
                      ? ""
                      : "d-none"
                  }`}
                >
                  <p className="error-msg m-0">{`${
                    invalidFields.includes("cardCVV")
                      ? "This field is required"
                      : invalidFields.includes("invalid-cardCVV")
                      ? "Enter valid CVV."
                      : ""
                  } `}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label mt-3">Billing Zip Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter zipcode"
                  name="cardZipCode"
                  value={cardData.cardZipCode}
                  onChange={(e) => {
                    let zipCode = e.target.value;
                    if (/^[0-9]+$/.test(zipCode) && zipCode.length <= 6) {
                      setCardData({
                        ...cardData,
                        cardZipCode: zipCode,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "cardZipCode"
                      );
                    }
                  }}
                />
                <div
                  className={`${
                    invalidFields.includes("cardZipCode") ? "" : "d-none"
                  }`}
                >
                  <p className="error-msg m-0"> This field is required</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-7 col-12 d-flex px-0 mx-auto py-md-5 py-sm-2">
            <button
              className={`btn btn-theme mx-auto cursor-pointer ${
                loader ? "btn-payment-loader disabled" : ""
              }`}
              type="submit"
              onClick={formValidation}
            >
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Pay and introduce me to the babysitter"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
