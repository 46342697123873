import "../assets/css/chat.scss";
import { useEffect } from "react";
import useChatServices from "../hooks/web-services-hooks/useChatServices";
import useChat from "../hooks/useChat";
import { handleErrorMessage } from "../helpers/utilities";
import useAuth from "../hooks/useAuth";
import { IParticipant } from "../models/IChatModel";
import ChatCard from "./ChatCard";
import no_message_img from "../assets/images/nomessagefound.png";

type Props = {
  setShowChatItem?: Function;
};

const ChatList = ({ setShowChatItem }: Props) => {
  const ChatServices = useChatServices();

  const {
    twilioToken,
    setParticipants,
    participants,
    openUserChat,
    searchString,
    setSearchString,
  } = useChat();
  const { auth } = useAuth();

  useEffect(() => {
    const getChannelParticipants = async () => {
      try {
        const response = await ChatServices.getParticipantsList({
          email: auth.email,
          role: auth.role,
        });
        if (response.data.success && response.data.data.length) {
          const participantsList = response.data.data;
          let chats: IParticipant[] = [];

          participantsList.forEach((participant: any) => {
            chats.push({
              ...participant,
              messages: [],
              unreadMessages: 0,
              channelJoined: false,
            });
          });

          setParticipants(chats);
        }
      } catch (e) {
        handleErrorMessage(e);
      }
    };

    if (twilioToken) {
    }
    getChannelParticipants();
  }, []);

  const openChat = (participant: any) => {
    openUserChat(participant.room);
    if (setShowChatItem) {
      setShowChatItem(false);
    }
  };

  return (
    <>
      <div className="col-12 col-md-5 col-lg-4 col-xl-3 mb-4 mb-md-0 px-0">
        <div className="name-chatsection ">
          <div className="input-group py-4 px-3 border-bottom">
            <input
              type="search"
              className="form-control rounded"
              aria-label="Search"
              value={searchString}
              aria-describedby="search-addon"
              placeholder="Search Messages"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchString(e.target.value);
              }}
            />
          </div>

          <div className="message-scroll">
            <ul className="list-unstyled mb-0">
              {participants.length ? (
                participants.map((participant, index) => {
                  if (searchString === "") {
                    return (
                      <div key={index} onClick={() => openChat(participant)}>
                        <ChatCard key={index} participant={participant} />
                      </div>
                    );
                  } else {
                    const chatIndex = participant.messages.findIndex((w) =>
                      w.body
                        .toLowerCase()
                        .includes(searchString.toLowerCase().trim())
                    );
                    if (chatIndex > -1) {
                      return (
                        <div key={index} onClick={() => openChat(participant)}>
                          <ChatCard participant={participant} />
                        </div>
                      );
                    }
                  }
                })
              ) : (
                <div className="d-md-flex align-items-center justify-content-center mt-5 pt-5 flex-column">
                  <img
                    src={no_message_img}
                    alt="no messages"
                    className="img-thumbnail h-75 w-75 bg-transparent border-0 d-flex mx-auto"
                  />
                  <p className="d-flex justify-content-center no-messages-placeholder opacity-100 font-16 mb-1">
                    No Messages Found
                  </p>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatList;
