import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from "../assets/images/logo.svg";
import "../assets/css/common.scss";
import useParentServices from "../hooks/web-services-hooks/useParentServices";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { handleErrorMessage } from "../helpers/utilities";
import AlertToasts from "../services/notification-services/notifications";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";

type IProps = {
  details: any;
  handleClose: Function;
};

const Deletemodal = ({ details, handleClose }: IProps) => {
  const { auth, setAuth } = useAuth();
  const ParentServices = useParentServices();
  const AuthServices = useAuthServices();
  const navigate = useNavigate();

  const handleConfirmation = async () => {
    if (details.action === "job") {
      ParentServices.deleteJob(details.id)
        .then((res) => {
          if (res.status === 200) {
            ParentServices.parentProfile(auth._id).then((res) => {
              details.setJobData(res.data.data[1]);
              handleClose();
            });
            AlertToasts.success("Job has been deleted");
          }
        })
        .catch((e: any) => {
          handleErrorMessage(e);
        });
    } else {
      AuthServices.deleteAccount(details.id).then((res) => {
        if (res.status === 200) {
          AuthServices.userLogOut()
            .then((res: any) => {
              setAuth({});
              navigate("/login");
              handleClose();
            })
            .catch((e) => {
              handleErrorMessage(e);
            });
          AlertToasts.success("Account has been deleted");
        }
      });
    }
  };
  return (
    <>
      <Modal
        show={details.show}
        onHide={() => {
          handleClose();
        }}
        className="delete-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0 border-0">
          <img
            src={logo}
            className="col-12 d-flex justify-content-center mx-auto"
          />
        </Modal.Header>
        <Modal.Body className="padding-small">
          <div className="mt-2 mx-auto text-unset">
            Are you sure you want to delete{" "}
            {details.action === "job" ? "this job ?" : "your account ?"}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button
            className="btn btn-no"
            onClick={() => {
              handleClose();
            }}
          >
            No
          </Button>
          <Button
            className="btn btn-yes"
            onClick={() => {
              handleConfirmation();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Deletemodal;
