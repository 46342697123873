import BabySitterRegistrationFrom from "../components/BabySitterRegistration/BabySitterRegistrationFrom";
import RegistrationFrom from "../components/Parentregistration/RegistrationFrom";
import useAuth from "../hooks/useAuth";

const EditProfile = () => {
  const { auth } = useAuth();
  return (
    <>
      {auth.role === "parent" ? (
        <RegistrationFrom handleStepChange={() => {}} edit={true}/>
      ) : (
        <BabySitterRegistrationFrom handleStepChange={() => {}} edit={true}/>
      )}
    </>
  );
};

export default EditProfile;
