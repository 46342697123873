import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/auth.scss";
import openEye from "../assets/images/open-eye.svg";
import closedEye from "../assets/images/closed-eye.svg";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import jwt_decode from "jwt-decode";
import { handleErrorMessage, isStrongPassword } from "../helpers/utilities";
import AlertToasts from "../services/notification-services/notifications";

type ResetPasswordCredentials = {
  password: string;
  confirmPassword: string;
};

type ResetPasswordVisibility = {
  password: Boolean;
  confirmPassword: Boolean;
};

const ResetPassword = () => {
  const { token } = useParams();
  const authServices = useAuthServices();
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState<ResetPasswordCredentials>({
    password: "",
    confirmPassword: "",
  });
  const [visibility, setVisibility] = useState<ResetPasswordVisibility>({
    password: false,
    confirmPassword: false,
  });
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  const validateToken = async () => {
    setLoader(true);
    try {
      if (token) {
        await authServices.validateResetPasswordLink(token);
      } else {
        navigate("/forgot-password");
      }
    } catch (e: any) {
      if (!e?.response?.data?.success) {
        navigate("/forgot-password", {
          state: {
            error:
              "The Link for the reset password has been either expired or invalid, please try again",
          },
        });
      }
    }
    finally{
      setLoader(false);
    }
  };

  const checkVisibility = (filed: string) => {
    if (filed === "password") {
      setVisibility({ ...visibility, password: !visibility.password });
    }
    if (filed === "confirmPassword") {
      setVisibility({
        ...visibility,
        confirmPassword: !visibility.confirmPassword,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      isStrongPassword(credentials.password.trim()) &&
      credentials.password === credentials.confirmPassword
    ) {
      const decodedToken: any = jwt_decode(token || "");
      await validateToken();
      try {
        await authServices.resetPassword({
          email: decodedToken.email,
          ...credentials,
        });
        AlertToasts.success("Password updated successfully");
        navigate("/login");
      } catch (e) {
        console.error(e);
        handleErrorMessage(e);
        setValidationMessage("");
      }
    } else {
      if (!credentials.password.trim().length) {
        setValidationMessage("Please enter password");
      } else if (!credentials.confirmPassword.trim().length) {
        setValidationMessage("Please enter confirm password");
      } else if (!isStrongPassword(credentials.confirmPassword.trim())) {
        setValidationMessage(
          "Password must contain one numeric, one lowercase, one uppercase and one special character and must be at lease 8 digit long"
        );
      } else if (credentials.password !== credentials.confirmPassword) {
        setValidationMessage("Password and confirm password are not matched");
      }
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <div className="box-outsider-auth login-box">
        <div className="container col-lg-5 col-md-9 col-sm-11 col-12 bg-white pb-5 px-md-5 rounded padding-top-56">
        <h1 className="text-center text-uppercase mt-3 mb-sm-4 mb-2">
          Reset Password
        </h1>
        {validationMessage ? (
          <p className="error-msg text-center"> {validationMessage}</p>
        ) : (
          <p className="subtitle">Please enter your details.</p>
        )}
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group password-group">
            <input
              type={visibility.password ? "text" : "password"}
              className="form-control"
              id="exampleInputPassword1"
              aria-describedby="passwordHelp"
              placeholder="New password"
              value={credentials.password}
              onChange={(e) => {
                setCredentials({ ...credentials, password: e.target.value });
              }}
            />

            <div
              className="login-password-icon"
              onClick={() => checkVisibility("password")}
            >
              {visibility.password ? (
                <img src={closedEye} />
              ) : (
                <img src={openEye} />
              )}
            </div>
          </div>
          <div className="form-group password-group">
            <input
              type={visibility.confirmPassword ? "text" : "password"}
              className="form-control"
              id="exampleInputPassword2"
              aria-describedby="passwordHelp"
              placeholder="Confirm password"
              value={credentials.confirmPassword}
              onChange={(e) => {
                setCredentials({
                  ...credentials,
                  confirmPassword: e.target.value,
                });
              }}
            />
            <div
              className="login-password-icon"
              onClick={() => checkVisibility("confirmPassword")}
            >
              {visibility.confirmPassword ? (
                <img src={closedEye} />
              ) : (
                <img src={openEye} />
              )}
            </div>
          </div>
          <button type="submit" className="btn btnlogin">
            Submit
          </button>
        </form>
        </div>
      </div>
  );
};

export default ResetPassword;
