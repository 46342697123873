import { useEffect, useState } from "react";
import verifyemail from "../assets/images/VerifyEmail.svg";
import { handleErrorMessage } from "../helpers/utilities";
import useAuth from "../hooks/useAuth";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import AlertToasts from "../services/notification-services/notifications";
import { useNavigate } from "react-router";
import React from "react";
import Deletemodal from "./Deletemodal";

type IDeleteModelInfo = {
  show: boolean;
  action: string;
  id: string;
};

const AccountVerification = () => {
  const [deleteModelInfo, setDeleteModelInfo] = useState<IDeleteModelInfo>({
    show: false,
    action: "job",
    id: "",
  });

  const { auth } = useAuth();
  const AuthServices = useAuthServices();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.role === "parent" && auth.isUserVerified) {
      navigate("/");
    } else if (
      auth.role === "sitter" &&
      auth.isBsParentVerified &&
      auth.isUserVerified
    ) {
      navigate("/");
    }
  }, [auth]);

  const emailVerificationText = () => {
    if (auth.role === "parent") {
      return "Please verify the link shared to your email address in order to access your account.";
    } else {
      if (!auth.isUserVerified && !auth.isBsParentVerified) {
        return "Please verify the link shared to your email address and your parent/guardian's email address in order to access your account";
      } else if (!auth.isBsParentVerified) {
        return "We are still waiting for your Parent/Guardian to verify your account.  Please ask your parent/guardian to verify your account.";
      } else {
        return "Please verify the link shared to your email address email address in order to access your account";
      }
    }
  };

  const resendVerificationLink = async (role: string) => {
    try {
      const response = await AuthServices.resendVerificationLink({
        id: auth._id,
        email: auth.email,
        sendTo: role
      });
      if (response.data.success) {
        AlertToasts.info("Verification link sent.");
      }
    } catch (e) {
      handleErrorMessage(e);
    }
  };

  const deleteAccount = (userId: string) => {
    setDeleteModelInfo({
      ...deleteModelInfo,
      action: "account",
      show: true,
      id: userId,
    });
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row py-5 mt-md-5 bg-white">
          <div className="col-12 pt-5  d-flex justify-content-center align-items-center">
            <img src={verifyemail} className="verifyemail" alt="Profile" />
          </div>
          <div className="col-12 mb-5 my-5">
            <p className="text-center account-verf-text mx-4 fw-600 m-0 p-0">
              Email Address verification is pending.{" "}
            </p>
            <p className="text-center account-verf-text mx-4 fw-600 m-0 p-0">
              {emailVerificationText()}
            </p>
          </div>
          <div className="row justify-content-center d-flex mt-2 mb-2 mx-0 px-0">
            {!auth.isBsParentVerified && auth.role === "sitter" ? (
              <button
                className="col-lg-3 col-md-4 col-10 btn btn-theme mt-lg-0 mt-2 mx-4"
                onClick={() => resendVerificationLink("guardian")}
              >
                Resend Email to Parent/Guardian
              </button>
            ) : (
              <></>
            )}
            {!auth.isUserVerified ? (
              <React.Fragment>
                <button
                  className="col-lg-3 col-md-4 col-10 btn btn-theme mt-lg-0 mt-2 mx-4"
                  onClick={() => resendVerificationLink("user")}
                >
                  Resend Email to {auth.email}
                </button>
              </React.Fragment>
            ) : (
              <></>
            )}
            <button
              className="col-lg-3 col-md-4 col-10 btn btn-theme mt-lg-0 mt-2 mx-4"
              onClick={() => {
                deleteAccount(auth._id);
              }}
            >
              Delete account and Re-Register
            </button>
          </div>
        </div>
      </div>
      {deleteModelInfo.show ? (
        <Deletemodal
          handleClose={() =>
            setDeleteModelInfo({ ...deleteModelInfo, show: false })
          }
          details={deleteModelInfo}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default AccountVerification;
