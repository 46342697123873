import Send from "../assets/images/send.svg";
import "../assets/css/chat.scss";
import useChat from "../hooks/useChat";
import React, { useEffect, useRef, useState } from "react";
import { IMessageBody, IParticipant } from "../models/IChatModel";
import moment from "moment";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import place_img from "../assets/images/placeholder.png";
import backicon from "../assets/images/backicon.svg";
import useChatServices from "../hooks/web-services-hooks/useChatServices";
import { handleErrorMessage } from "../helpers/utilities";

type Props = {
  setShowChatItem?: Function;
};

const ConversationScreen = ({ setShowChatItem }: Props) => {
  const {
    singleUserMessages,
    currentChannel,
    currentRoomId,
    participants,
    setParticipants,
  } = useChat();
  const { auth } = useAuth();
  const participant: IParticipant | undefined = participants.find(
    (w) => w.room === currentRoomId
  );

  const [messageText, setMessageText] = useState<string>("");
  const [activityCheckerFlag, setactivityCheckerFlag] =
    useState<boolean>(false);

  let scrollDiv = useRef<any>(null);

  const navigate = useNavigate();
  const ChatServices = useChatServices();

  const handleSendMessage = async () => {
    if (messageText && messageText.trim()) {
      currentChannel.sendMessage(String(messageText).trim());
      setMessageText("");
      scrollToBottom();
      if (!activityCheckerFlag) {
        setactivityCheckerFlag(true);
        ChatServices.sendNotificationToOfflineUser({
          recipientEmail:
            auth.role === "parent"
              ? participant?.babySitterEmail
              : participant?.parentEmail,
          recipientName:
            auth.role === "parent"
              ? `${participant?.babySitterFullName}`
              : `${participant?.parentFullName}`,
          recipientId:
            auth.role === "parent"
              ? participant?.babySitterId
              : participant?.parentId,
          userName: `${auth.firstName} ${auth.lastName}`,
          userRole: auth.role,
          userEmail: auth.email,
          chatURL: "/message-center",
        });
        setTimeout(() => {
          setactivityCheckerFlag(false);
        }, 60000);
      }
    }
  };

  const scrollToBottom = () => {
    const scrollHeight = scrollDiv.current.scrollHeight;
    const height = scrollDiv.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  useEffect(() => {
    if (scrollDiv.current) {
      scrollToBottom();
    }
    if (participant?.messages && participant.messages.length && currentRoomId) {
      currentChannel.updateLastConsumedMessageIndex(
        participant.messages.length - 1
      );
      const participantIndex = participants.findIndex(
        (w) => w.room === participant.room
      );
      setParticipants(
        Object.values({
          ...participants,
          [participantIndex]: { ...participant, unreadMessages: 0 },
        })
      );
    }
  }, [singleUserMessages]);

  useEffect(() => {
    if (currentRoomId) {
      setactivityCheckerFlag(false);
    }
  }, [currentRoomId]);

  const backEvent = () => {
    if (setShowChatItem) {
      setShowChatItem(true);
    }
  };

  return (
    <React.Fragment>
      {currentRoomId &&
      singleUserMessages &&
      Object.keys(singleUserMessages).length ? (
        <div className="col-12 col-md-7 col-lg-8 col-xl-9 message-box px-0 py-0">
          <div className="fixed-header-chat">
            <div className="px-md-3 pt-sm-3 pt-0 pb-0">
              <div className="d-block">
                <div className="d-block">
                  <div className="d-flex justify-content-center p-md-0 p-2 pb-1">
                    <button
                      className="col-2 d-md-none d-flex justify-content-center align-items-center box-shadow-none outline-0 rounded font-32 fw-900 bg-transparent pe-2 pt-2 border-0"
                      onClick={backEvent}
                    >
                      <img src={backicon} alt="" />
                    </button>
                    <div className="col-2 d-md-none d-flex align-items-center me-2 px-0 user-name chathead-img ">
                      <p className="m-0">
                        {auth.role === "parent"
                          ? `${participant?.babySitterFullName.slice(0, 2)}`
                          : `${participant?.parentFullName.slice(0, 2)}`}
                      </p>
                    </div>
                    <div className="col-md-12 col-7 messagebox-title d-flex align-items-center">
                      {auth.role === "parent"
                        ? participant?.babySitterFullName.split(" ")[0]
                        : participant?.parentFullName}
                    </div>
                  </div>
                  <div className="ps-md-0 pe-0 px-2 text-md-start text-center d-sm-flex col-12 box-mess-title">
                    <div className="sec-messagebox-title mb-1 w-auto">
                      {moment(participant?.jobId.startDate).format("LL")}
                    </div>
                    <a
                      className="link-job cursor-pointer"
                      onClick={() =>
                        navigate(`/job-summary/${participant?.jobId._id}`)
                      }
                    >
                      View Job
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr className="divider-chat message-devider" />
          </div>

          <div className="row maxheight-padding" ref={scrollDiv}>
            {Object.keys(singleUserMessages).map((time: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className="message-day">
                    <p>{time}</p>
                  </div>
                  <React.Fragment>
                    {singleUserMessages[time].map(
                      (message: IMessageBody, key: number) => {
                        return (
                          <div
                            key={key}
                            className={`col-12 d-flex flex-row ${
                              auth.email === message.author
                                ? "justify-content-end px-xm-3 px-0"
                                : "justify-content-start flex-reverse px-3"
                            } py-3`}
                          >
                            <div
                              className={`col-md-5 col-10 ${
                                auth.email === message.author ? "" : "order-2"
                              }`}
                            >
                              <p
                                className={`small p-2 me-md-3 me-1 mb-1 rounded-3 ${
                                  auth.email === message.author
                                    ? "send-mess"
                                    : "reciver-mess"
                                }`}
                              >
                                {message.body}
                                <span className="message-time ms-auto">
                                  {moment(message.timestamp).format("hh:mm a")}
                                </span>
                              </p>
                            </div>

                            <div className="mt-2 me-2 user-name">
                              <p className="m-0">
                                {auth.email === message.author
                                  ? auth.firstName.slice(0, 2)
                                  : auth.role === "sitter"
                                  ? participant?.parentFullName.slice(0, 2)
                                  : participant?.babySitterFullName.slice(0, 2)}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </React.Fragment>
                </React.Fragment>
              );
            })}
          </div>

          <div className="col-12 text-muted type-mess d-flex justify-content-start align-items-center px-md-3 px-2 pt-md-4 pt-2 pb-3 mt-2 bg-backround-color">
            <div className="col-1 me-2 user-name chathead-img">
              <p className="m-0">{auth.firstName.slice(0, 2)}</p>
            </div>
            <input
              type="text"
              value={messageText}
              onChange={(e) => {
                setMessageText(e.target.value);
              }}
              className="form-control form-control-lg chat-input-width"
              id="exampleFormControlInput2"
              placeholder="Type message"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleSendMessage();
                }
              }}
            />
            <div className="px-3" onClick={handleSendMessage}>
              <img src={Send} className="height-width-30" />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="col-12 col-md-7 col-lg-8 col-xl-9 d-md-flex flex-column align-items-center justify-content-center message-box px-0 py-0">
            <img
              src={place_img}
              alt="no messages"
              className="img-thumbnail bg-transparent border-0 d-flex"
            />
            <p className="d-flex justify-content-center no-messages-placeholder">
              No Messages here yet...
            </p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ConversationScreen;
