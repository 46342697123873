import React from 'react'

const _404 = () => {
  return (
    <h1 className='text-lg-center font-weight-bold'>
        404 Page not found
    </h1>
  )
}

export default _404
