// import { useState } from "react";
// import React from "react";
// import "../assets/css/common.scss";
// import Thumbnail from "../assets/images/thumbnail-img.jpg";

// const HowitWorksVideo = () => {
//   // Update meta description directly
//   document.title = "How HighSchoolBabysitters.com Works";

//   const metaDescription = document.querySelector('meta[name="description"]');
//   if (metaDescription) {
//     metaDescription.setAttribute(
//       "content",
//       "Both parents and highschoolers need to go through a few key steps to find a secure, local, well-suited match for babysitting."
//     );
//   }
//   const workVideo = require("../../src/assets/images/video.mp4");
//   return (
//     <div className="parent-layout">
//       <p className="page-title text-center text-uppercase mt-3 mb-4 video-pointer">
//         How It works
//       </p>
//       <div>
//         <div className="row layout-box">
//           <video controls poster={Thumbnail}>
//             <source src={workVideo} type="video/mp4"></source>
//           </video>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HowitWorksVideo;

//  new code start from here



import { useState } from "react";
import React from "react";
import "../assets/css/common.scss";
import Thumbnail from "../assets/images/thumbnail-img.jpg";

const HowitWorksVideo = () => {
  // Update meta description directly
  document.title = "How HighSchoolBabysitters.com Works";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "Both parents and highschoolers need to go through a few key steps to find a secure, local, well-suited match for babysitting."
    );
  }
  const workVideo = require("../../src/assets/images/video.mp4");
  return (
    <>
      <p className="page-title see-how-it-works-text mt-xl-0 mt-xxl-4 text-center text-uppercase video-pointer see-how-it-works-text work_tittle">
        See How it Works
      </p>
      <div>
        <div className="row px-0 workVideo m-auto layout-box p-0 ps-0 pe-0">
          <video controls poster={Thumbnail} className="p-0">
            <source src={workVideo} type="video/mp4"></source>
          </video>
        </div>
      </div>

      <div className="how-see-layout my-5 my-xxl-5 my-sm-0 pb-md-5">
        <div className="our-layout box-shadow-frequency-question frequeny-box-padding-top">
          <div className="col-md-12 question-box-alignment">
            <div className="row mx-0 px-0">
              <h2 className="text-start text-sm-center questions-title px-0">
                Frequently Asked Questions
              </h2>
              <div className="col-12 ms-0 question p-0">
                <h4>1. How do I know the sitters are trustworthy?</h4>
                <p>
                  Sitters come from your local high schools, and parents have
                  full oversight of all communication between sitters and
                  families, ensuring transparency and safety.
                </p>

                <h4>2. Are sitters trained in first aid?</h4>
                <p>
                  Some sitters have first aid or CPR training, which is listed
                  on their profiles.
                </p>

                <h4>3. How much do sitters charge?</h4>
                <p>
                  Rates vary based on the sitter’s experience and the number of
                  children.<br /> Rates are listed on each sitter's profile.
                </p>

                <h4>4. Can I schedule regular bookings?</h4>
                <p>
                  Yes, you can set up recurring bookings with your preferred
                  sitter for ongoing childcare needs.
                </p>

                <h4>5. Is a background check conducted on sitters?</h4>
                <p>
                  Since sitters are under 18, background checks aren't possible.
                  However, you can view details such as GPA, certifications, and
                  other information, and all communications are monitored by the
                  sitter’s parent or guardian.
                </p>

                <h4>6. How does safety work on this platform?</h4>
                <p>
                  Both sitter and family communications are monitored by parents
                  for transparency, ensuring safety for everyone involved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowitWorksVideo;
