import { useEffect, useState } from "react";
import email from "../assets/images/email.svg";
import "../assets/css/common.scss";
import { IContactUsModel } from "../models/IContactUs";
import PhoneInput from "react-phone-input-2";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import {
  handleErrorMessage,
  updateErrorFields,
  updateMultipleErrorFields,
  validateEmail,
} from "../helpers/utilities";
import Thankyoumodal from "../components/Thankyoumodal";

const ContactUs = () => {
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [showThanksModal, setShowThanksModal] = useState<boolean>(false);
  const [forminput, setFormInput] = useState<IContactUsModel>({
    firstName: "",
    lastName: "",
    city: "",
    zipCode: "",
    email: "",
    phoneNo: "",
    message: "",
  });
  const [loader, setLoader] = useState<boolean>(false);

  const ContactUs = useAuthServices();

  const handleSubmit = () => {
    setLoader(true);
    let contactInput: any = forminput;
    ContactUs.contactUs({
      ...contactInput,
      phoneNo: contactInput.phoneNo.slice(1),
    })
      .then((res: any) => {
        if (res.status === 200) {
          setShowThanksModal(true);
        }
      })
      .catch((e) => {
        handleErrorMessage(e);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const formValidation = () => {
    let validationResult: boolean = true;
    let InvalidFields: string[] = [];

    if (forminput) {
      Object.keys(forminput).forEach((key: string) => {
        if (
          Object.hasOwn(forminput, key) &&
          !forminput[key as keyof typeof forminput]
        ) {
          validationResult = false;
          InvalidFields.push(key);
        }
      });
    }

    if (!validateEmail(forminput.email)) {
      validationResult = false;
      InvalidFields.push("email");
    }

    if (forminput.phoneNo.length && forminput.phoneNo.charAt(1) == "0") {
      validationResult = false;
      InvalidFields.push("invalid-phoneNo");
    }

    if (forminput.phoneNo.length !== 11) {
      validationResult = false;
      InvalidFields.push("phoneNo");
    }

    if (!forminput.zipCode.length || forminput.zipCode.length > 6) {
      validationResult = false;
      InvalidFields.push("zipCode");
    }

    setInvalidFields(InvalidFields);

    if (validationResult) {
      handleSubmit();
    }
    else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="box-outside-view">
        <h1 className="page-title text-center text-uppercase mt-3 mb-sm-4 mb-2">
          Contact Us
        </h1>

        <div className="container bg-white pb-5 px-md-5 rounded padding-top-56">
          <div className="">
            <div className="row gy-3 my-3 mx-auto px-0">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={forminput.firstName}
                    onChange={(event) => {
                      let inputVal = event.target.value;
                      if (/^[a-zA-Z]*$/.test(inputVal)) {
                        setFormInput({
                          ...forminput,
                          firstName: inputVal,
                        });
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "firstName"
                        );
                      }
                    }}
                    placeholder="First Name"
                  />
                  <div
                    className={`${
                      invalidFields.includes("firstName") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">Please enter First Name</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={forminput.lastName}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (/^[a-zA-Z]*$/.test(inputVal)) {
                        setFormInput({
                          ...forminput,
                          lastName: inputVal,
                        });
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "lastName"
                        );
                      }
                    }}
                    placeholder="Last Name"
                  />
                  <div
                    className={`${
                      invalidFields.includes("lastName") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">Please enter Last Name</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={forminput.city}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (/^[a-zA-Z ]*$/.test(inputVal)) {
                        setFormInput({
                          ...forminput,
                          city: inputVal,
                        });
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "city"
                        );
                      }
                    }}
                    placeholder="City"
                  />
                  <div
                    className={`${
                      invalidFields.includes("city") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0"> Please enter valid City</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Zip Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    value={forminput.zipCode}
                    onChange={(e) => {
                      let input = e.target.value;
                      if (/^[0-9]+$/.test(input) && input.length <= 6) {
                        setFormInput({
                          ...forminput,
                          zipCode: input,
                        });
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "zipCode"
                        );
                      }
                    }}
                  />
                  <div
                    className={`${
                      invalidFields.includes("zipCode") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {" "}
                      Please enter valid Zip Code
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group email-group">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@gmail.com"
                    value={forminput.email}
                    name="email"
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      setFormInput({
                        ...forminput,
                        email: inputVal,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "email"
                      );
                    }}
                  />
                  <div className="email-icon">
                    <img src={email} />
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("email") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      Please enter valid Email Address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group cstm-input">
                  <label className="form-label">Cell Phone</label>

                  <PhoneInput
                    countryCodeEditable={false}
                    country={"us"}
                    onlyCountries={["us"]}
                    value={forminput.phoneNo}
                    onChange={(phone) => {
                      setFormInput({ ...forminput, phoneNo: phone });
                      updateMultipleErrorFields(
                        invalidFields,
                        setInvalidFields,
                        ["phoneNo", "invalid-phoneNo"]
                      );
                    }}
                  />
                  <div
                    className={`${
                      invalidFields.includes("phoneNo") || invalidFields.includes("invalid-phoneNo") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                    {`
                      ${invalidFields.includes("phoneNo") ? "Please enter valid Phone Number" : "Contact number should not starts with zero"}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-11">
                  <div className="mb-3 mt-5 form-group">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={10}
                      cols={4}
                      value={forminput.message}
                      onChange={(e) => {
                        setFormInput({
                          ...forminput,
                          message: e.target.value,
                        });
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "message"
                        );
                      }}
                    ></textarea>
                    <div
                      className={`${
                        invalidFields.includes("message") ? "" : "d-none"
                      } `}
                    >
                      <p className="error-msg m-0"> Please enter Message</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-1 mb-3 mx-0 px-0">
                <div className="col-12 d-flex justify-content-center text-center">
                  <button
                    type="submit"
                    className="btn btn-theme my-1"
                    onClick={formValidation}
                  >
                    {loader ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showThanksModal ? (
        <Thankyoumodal
          handleClose={() => {
            setShowThanksModal(false);
          }}
          show={showThanksModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactUs;
