import Geocode from "react-geocode";
import CryptoJS from "crypto-js";
import { debug } from "console";
import AlertToasts from "../services/notification-services/notifications";
import moment from "moment";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

export const validateEmail = (email: string) => {
  const re: RegExp =
    /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\.[a-zA-Z]\S+/;
  return re.test(email);
};

export const calculateGeoDistance = async (address: string) => {
  let coOrdinates = { lat: 0, lng: 0 };
  try {
    const geoCodeResult = await Geocode.fromAddress(address);
    const { lat, lng } = geoCodeResult.results[0].geometry.location;
    coOrdinates = { lat, lng };
  } catch (err) {
    console.error("geocode error", err);
  } finally {
    return coOrdinates;
  }
};

export const updateErrorFields = (
  fields: string[],
  setterFunction: Function,
  target: string
) => {
  if (fields.includes(target)) {
    setterFunction(fields.filter((x) => x != target));
  }
};

export const updateMultipleErrorFields = (
  fields: string[],
  setterFunction: Function,
  target: string[]
) => {
  setterFunction(fields.filter((x) => !target.includes(x)));
};

export const validPasswordErrorDisplay = (
  password: string,
  stateArray: string[],
  stateSetterFunction: Function
) => {
  if (password.length) {
    if (isStrongPassword(password)) {
      stateSetterFunction(stateArray.filter((x) => x != "weak-password"));
    } else {
      if (stateArray.findIndex((w) => w == "weak-password") === -1) {
        stateSetterFunction(stateArray.concat(["weak-password"]));
      }
    }
  }
};

export const isStrongPassword = (password: string) => {
  if (
    password.length > 7 &&
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,32}$/.test(password)
  ) {
    return true;
  }
  return false;
};

export const encryptData = (text: string) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(text),
    process.env.REACT_APP_ENC_KEY || ""
  ).toString();
};

export const decryptData = (text: string) => {
  const bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_ENC_KEY || "");
  if (bytes.toString()) {
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  }
  return "";
};

export const isSameDate = (date1: Date, date2: Date) => {
  const flag =
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();
  return flag;
};

export const handleErrorMessage = (exception: any) => {
  if (exception.response?.data?.error) {
    AlertToasts.error(exception.response?.data?.error);
  } else {
    AlertToasts.error("Something went wrong");
  }
  console.error(exception);
};

// export const getHourDiff = (endTime: string, startTime: string) => {
//   if (!endTime || !startTime) {
//     return 0;
//   }
//   let totalHours = parseInt(endTime) - parseInt(startTime);
//   let totalMin =
//     parseInt(endTime.split(":")[1]) - parseInt(startTime.split(":")[1]);

//   if (totalHours !== 0) {
//     totalHours = (totalHours * 60 + totalMin) / 60;
//   }
//   if (totalHours < 0) {
//     totalHours = 24 + totalHours;
//   }
//   if (totalHours === 0) {
//     totalHours = 24;
//   }

//   return totalHours.toFixed(1);
// };

export const getDateDifference = (endDate:Date, startDate:Date) => {
  let date1 = moment(endDate);
  let date2 = moment(startDate);
  let day_difference = date1.diff(date2, 'days') 

  //----- Alternate Way -------
  // let date1  = new Date(endDate);
  // let date2 = new Date(startDate);
  // let one_day = 1000 * 60 * 60 * 24
  // let time_difference  = date2.getTime() - date1.getTime();  
  // let day_difference = Math.abs(time_difference/one_day);

  return day_difference + 1
}

export const getHourDiff = (endTime: string, startTime: string) => {
  let end_time: any = new Date(endTime);
  let start_time: any = new Date(startTime);

  let diffInMilliseconds = Math.abs(end_time - start_time);
  let diffInHours: any = diffInMilliseconds / 3600000;
  let fixedHourDifference = diffInHours.toFixed(1);

  if (fixedHourDifference !== 0.0) {
    diffInHours = diffInMilliseconds / 3600000;
  }
  if (fixedHourDifference < 0) {
    diffInHours = 24 + diffInHours;
  }
  if (fixedHourDifference == 0.0) {
    diffInHours = 24;
  }

  return diffInHours.toFixed(1);
};

export const isPastDate = (date: Date) => {
  const currentDate = moment();
  const inputDate = moment(date);
  return inputDate.isBefore(currentDate);
};
