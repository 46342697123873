import { useEffect, useState } from "react";
import "../../assets/css/common.scss";
import { useLocation, useNavigate } from "react-router-dom";

import DatePicker from "react-date-picker";
import { IJobInfoForm, IKids } from "../../models/IParentRegistrationModel";
import CrossIcon from "../../assets/images/cross-icon.svg";
import { CoOrdinates } from "../../models/IBSRegistrationModel";
import {
  calculateGeoDistance,
  handleErrorMessage,
  updateErrorFields,
  updateMultipleErrorFields,
} from "../../helpers/utilities";
import useAuth from "../../hooks/useAuth";
import useParentServices from "../../hooks/web-services-hooks/useParentServices";
import AlertToasts from "../../services/notification-services/notifications";
import ReactDatePicker from "../ReactDatePicker";

type IProps = {
  userId: string;
  handleStepChange: Function;
};

const JobInfoForm = ({ handleStepChange, userId }: IProps) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [invalidData, setInvalidData] = useState<string[]>([]);
  const [postJob, setPostJob] = useState<Boolean>(false);
  const [jobInfo, setJobInfo] = useState<IJobInfoForm>({
    homeAddress: "",
    homeCity: "",
    homeZipCode: "",
    startDate: null,
    endDate: null,
    startTime: "",
    endTime: "",
    note: "",
    terms: false,
    kids: [{ gender: "", age: 0 }],
    userId: auth._id || userId,
    smsApproval: false,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const location = useLocation();
  const ParentServices = useParentServices();

  const addNewKidInput = (e: any) => {
    e.preventDefault();
    let allValidKids = true;
    let allInvalidFields: string[] = [];
    jobInfo.kids.forEach((kid, index) => {
      if (kid.gender == "") {
        allValidKids = false;
        allInvalidFields.push(`gender${index}`);
        setInvalidData(invalidData.concat(allInvalidFields));
      }
      if (kid.age == 0) {
        allValidKids = false;
        allInvalidFields.push(`age${index}`);
        setInvalidData(invalidData.concat(allInvalidFields));
      }
    });
    if (allValidKids) {
      setJobInfo({
        ...jobInfo,
        kids: jobInfo.kids.concat([{ gender: "", age: 0 }]),
      });
    }
  };

  const removeKidInput = (inputId: number) => {
    setJobInfo({
      ...jobInfo,
      kids: jobInfo.kids.filter((kid, index) => inputId !== index),
    });
  };

  const handleSubmit = async () => {
    setLoader(true);
    const coOrdinates: CoOrdinates = await calculateGeoDistance(
      `${jobInfo.homeAddress}, ${jobInfo.homeCity}, ${jobInfo.homeZipCode}`
    );
    ParentServices.parentStep3Registration({
      ...jobInfo,
      ...coOrdinates,
    })
      .then((res: any) => {
        if (res.status === 200) {
          if (postJob) {
            navigate("/profile");
            AlertToasts.success("Job posted successfully ");
          } else {
            handleStepChange(3);
            sessionStorage.setItem("JobInfoData", JSON.stringify(jobInfo));
            AlertToasts.info(
              "A Verification e-mail has been sent to your  email address, please verify the email in order to activate your account"
            );
          }
        }
      })
      .catch((e) => {
        handleErrorMessage(e);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const formValidation = async (e: any) => {
    let validationResult: boolean = true;
    let jobFormFields: string[] = [];
    const coOrdinates: CoOrdinates = await calculateGeoDistance(
 `${jobInfo.homeAddress}, ${jobInfo.homeCity}, ${jobInfo.homeZipCode}`    
 );

    if (jobInfo) {
      Object.keys(jobInfo).forEach((key: string) => {
        if (
          Object.hasOwn(jobInfo, key) &&
          !jobInfo[key as keyof typeof jobInfo]
        ) {
          validationResult = false;
          jobFormFields.push(key);
        }
      });
    }
    // ----- Checking for valid lat & long 
    if(jobInfo.homeAddress && (coOrdinates.lat == 0 && coOrdinates.lng == 0 )){
      validationResult = false;
      jobFormFields.push("invalid-address");
    }
    if (jobInfo.homeZipCode.length === 0 || jobInfo.homeZipCode.length > 6) {
      validationResult = false;
      jobFormFields.push("homeZipCode");
    }

    if (jobInfo) {
      jobInfo.kids.map((input, id) => {
        if (!jobInfo.kids[id].gender) {
          validationResult = false;
          jobFormFields.push("gender" + id);
        }
        if (!jobInfo.kids[id].age || jobInfo.kids[id].age === 0) {
          validationResult = false;
          jobFormFields.push("age" + id);
        }
      });
    }

    if (jobInfo.endDate && jobInfo.startDate) {
      if (jobInfo.endDate < jobInfo.startDate) {
        validationResult = false;
        jobFormFields.push("invalid-endDate");
      }
    }
    if (!jobInfo.terms) {
      validationResult = false;
      jobFormFields.push("terms");
    }

    if(location.pathname !== "/parent-register" && jobFormFields.includes("smsApproval")){
      jobFormFields = jobFormFields.filter(
        (x) =>
          !x.includes('smsApproval')
      );
      if(!jobFormFields.length){
      validationResult = true;
    }
    }

    setInvalidData(jobFormFields);

    if (validationResult) {
      handleSubmit();
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    let jobData: string | null = sessionStorage.getItem("JobInfoData");

    if (location.pathname === "/post-a-job") {
      setPostJob(true);
    } else if (jobData && location.pathname !== "/post-a-job") {
      setJobInfo(JSON.parse(jobData));
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="">
        <div
          className={
            postJob
              ? "container bg-white pb-5 px-md-5 rounded padding-top-56"
              : ""
          }
        >
          <div className="row text-center mt-5">
            <div className="col-12">
              <h1 className="form-title">Job Information</h1>
            </div>
            <div className="col-12 text-center">
              <p className="page-discription p-0 m-0">
                Tell us about the babysitting job.
              </p>
            </div>
          </div>
          <form className="row gy-3 my-3 mx-auto" >
            <div className="col-12 d-none d-sm-block">
              <div className="form-group">
                <label className="form-label">
                  Babysitting Address *{" "}
                  <p className="mb-1">
                    (We will not share this address with anyone. We use it to
                    tell the babysitters how far the job is from where they
                    live. You will share this address with the babysitter you
                    choose later in the process.){" "}
                  </p>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Babysitting Address"
                  name="homeAddress"
                  value={jobInfo.homeAddress}
                  onChange={(e) => {
                    setJobInfo({
                      ...jobInfo,
                      homeAddress: e.target.value,
                    });
                    updateMultipleErrorFields(
                      invalidData,
                      setInvalidData,
                      ["homeAddress","invalid-address"]
                    );
                  }}
                />
                <div
                  className={
                    invalidData.includes("homeAddress") || invalidData.includes("invalid-address") ? "" : "d-none"
                  }
                >
                  <p className="error-msg m-0"> {`${invalidData.includes("homeAddress") ? "Please enter valid Address" : "Please enter valid Address, City and Zip code"}`}</p>
                </div>
              </div>
            </div>
            <div className="col-12 d-sm-none d-block">
              <div className="form-group">
                <label className="form-label">Babysitting Address *</label>
                <textarea
                  rows={3}
                  className="form-control"
                  placeholder="Babysitting Address"
                  name="homeAddress"
                  value={jobInfo.homeAddress}
                  onChange={(e) => {
                    setJobInfo({
                      ...jobInfo,
                      homeAddress: e.target.value,
                    });
                    updateMultipleErrorFields(
                      invalidData,
                      setInvalidData,
                      ["homeAddress","invalid-address"]
                    );
                  }}
                />
                <div
                  className={
                    invalidData.includes("homeAddress") || invalidData.includes("invalid-address") ? "" : "d-none"
                  }
                >
                  <p className="error-msg m-0"> {`${invalidData.includes("homeAddress") ? "Please enter valid Address" : "Please enter valid Address, City and Zip code"}`}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">City *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name="homeCity"
                  value={jobInfo.homeCity}
                  onChange={(e) => {
                    let inputVal = e.target.value;
                    if (/^[a-zA-Z ]*$/.test(inputVal)) {
                      setJobInfo({
                        ...jobInfo,
                        homeCity: inputVal,
                      });
                      updateErrorFields(
                        invalidData,
                        setInvalidData,
                        "homeCity"
                      );
                    }
                  }}
                />
                <div
                  className={invalidData.includes("homeCity") ? "" : "d-none"}
                >
                  <p className="error-msg m-0"> This Field is required.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Zip Code *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip Code"
                  name="homeZipCode"
                  value={jobInfo.homeZipCode}
                  onChange={(e) => {
                    let inputVal = e.target.value;
                    if (/^[0-9]+$/.test(inputVal) && inputVal.length <= 6) {
                      setJobInfo({
                        ...jobInfo,
                        homeZipCode: inputVal,
                      });
                      updateErrorFields(
                        invalidData,
                        setInvalidData,
                        "homeZipCode"
                      );
                    } else if (!inputVal.length) {
                      setJobInfo({
                        ...jobInfo,
                        homeZipCode: "",
                      });
                    }
                  }}
                />
                <div
                  className={
                    invalidData.includes("homeZipCode") ? "" : "d-none"
                  }
                >
                  <p className="error-msg m-0"> This Field is required.</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 form-group date-picker-cutm date-icon-hide">
              <label className="form-label">Start Date *</label>
              <ReactDatePicker
                className="form-control"
                minDate={new Date()}
                showMonthDropdown={true}
                showYearDropdown={true}
                placeholderText="mm/dd/yyyy"
                selected={jobInfo.startDate}
                onChange={(date: Date) => {
                  setJobInfo({
                    ...jobInfo,
                    startDate: date,
                  });
                  updateErrorFields(invalidData, setInvalidData, "startDate");
                }}
                onFocus={(e:any) => e.target.blur()}
              />
              <div
                className={invalidData.includes("startDate") ? "" : "d-none"}
              >
                <p className="error-msg m-0"> This field is required.</p>
              </div>
            </div>

            <div className="col-md-6 form-group date-picker-cutm d-block d-md-none date-icon-hide">
              <label className="form-label">End Date *</label>
              <ReactDatePicker
                className="form-control"
                showMonthDropdown={true}
                showYearDropdown={true}
                placeholderText="mm/dd/yyyy"
                selected={jobInfo.endDate}
                onChange={(date: Date) => {
                  setJobInfo({
                    ...jobInfo,
                    endDate: date,
                  });
                  updateMultipleErrorFields(invalidData, setInvalidData, [
                    "endDate",
                    "invalid-endDate",
                  ]);
                }}
                onFocus={(e:any) => e.target.blur()}
              />
              <div
                className={
                  invalidData.includes("endDate") ||
                  invalidData.includes("invalid-endDate")
                    ? ""
                    : "d-none"
                }
              >
                <p className="error-msg m-0">{`${
                  invalidData.includes("endDate")
                    ? " This field is required ."
                    : "End date should be greater than start date"
                }`}</p>
              </div>
            </div>

            <div className="col-md-6 form-group date-picker-cutm">
              <label className="form-label">Start Time *</label>
              <ReactDatePicker
                className="form-control"
                showTimeSelect={true}
                showTimeSelectOnly={true}
                placeholderText="--:--"
                selected={jobInfo.startTime}
                dateFormat="h:mm aa"
                timeIntervals={15}
                onChange={(e: any) => {
                  setJobInfo({ ...jobInfo, startTime: e });
                  updateErrorFields(invalidData, setInvalidData, "startTime");
                  console.log("e",e)
                }}
                onFocus={(e:any) => e.target.blur()}
              />

              <div
                className={invalidData.includes("startTime") ? "" : "d-none"}
              >
                <p className="error-msg m-0"> This field is required.</p>
              </div>
            </div>

            <div className="col-md-6 form-group date-picker-cutm d-none d-md-block date-icon-hide">
              <label className="form-label">End Date *</label>
              <ReactDatePicker
                className="form-control"
                showMonthDropdown={true}
                showYearDropdown={true}
                minDate={jobInfo.startDate || new Date()}
                placeholderText="mm/dd/yyyy"
                selected={jobInfo.endDate}
                onChange={(date: Date) => {
                  setJobInfo({
                    ...jobInfo,
                    endDate: date,
                  });
                  updateMultipleErrorFields(invalidData, setInvalidData, [
                    "endDate",
                    "invalid-endDate",
                  ]);
                }}
                onFocus={(e:any) => e.target.blur()}
              />
              <div
                className={
                  invalidData.includes("endDate") ||
                  invalidData.includes("invalid-endDate")
                    ? ""
                    : "d-none"
                }
              >
                <p className="error-msg m-0">{`${
                  invalidData.includes("endDate")
                    ? " This field is required ."
                    : "End date should be greater than start date"
                }`}</p>
              </div>
            </div>

            <div className="col-md-6 form-group date-picker-cutm">
              <label className="form-label">End Time *</label>
              <ReactDatePicker
                className="form-control"
                showTimeSelect={true}
                showTimeSelectOnly={true}
                placeholderText="--:--"
                dateFormat="h:mm aa"
                selected={jobInfo.endTime}
                timeIntervals={15}
                onChange={(e: any) => {
                  setJobInfo({ ...jobInfo, endTime: e });
                  updateErrorFields(invalidData, setInvalidData, "endTime");
                }}
                onFocus={(e:any) => e.target.blur()}
              />
              <div className={invalidData.includes("endTime") ? "" : "d-none"}>
                <p className="error-msg m-0"> This field is required.</p>
              </div>
            </div>
              
            <div className="row col-12">
              <h6 className="mt-3 ">If you are looking for a long term babysitter , please add additional details about what days and times in Note to potential babysitters area</h6>
            </div>

            <div className="row my-3 mx-sm-auto justify-content-center align-item-center mt-5 ">
              <div className="col-md-9 col-12 my-2 mx-sm-auto mx-0 d-flex">
                <div className="col-3"></div>
                <div className="col-4 me-2 ms-0 fw-600 ">Girl or Boy *</div>
                <div className="col-4 mx-2  fw-600 ">Age *</div>
              </div>
              {jobInfo.kids.map((kid, index) => (
                <>
                  <div className="col-md-9 col-12 my-2 mx-sm-auto mx-0 d-flex px-0 girl-boy-select">
                    <div className="col-3 d-flex justify-content-center fw-600 mt-1">
                      Kid #{index + 1}
                    </div>
                    <div className="col-4 me-2 ms-0 form-group">
                      <select
                        id="inputState"
                        className="form-select"
                        name="gender"
                        value={jobInfo.kids[index].gender}
                        onChange={(e) => {
                          let kidsList: IKids[] = jobInfo.kids;
                          kidsList[index] = { ...kid, gender: e.target.value };
                          setJobInfo({
                            ...jobInfo,
                            kids: kidsList,
                          });
                          updateErrorFields(
                            invalidData,
                            setInvalidData,
                            "gender" + index
                          );
                        }}
                      >
                        <option value="" selected disabled>
                          Girl Or Boy
                        </option>
                        <option value="Girl">Girl</option>
                        <option value="Boy">Boy</option>
                      </select>
                      <div
                        className={
                          invalidData.includes("gender" + index) ? "" : "d-none"
                        }
                      >
                        <p className="error-msg m-0">
                          {" "}
                          This field is required.
                        </p>
                      </div>
                    </div>

                    <div className="col-4 mx-md-1 form-group ">
                      <select
                        id="inputState"
                        className="form-select"
                        name="age"
                        value={jobInfo.kids[index].age}
                        onChange={(e) => {
                          let kidList: IKids[] = jobInfo.kids;
                          kidList[index] = {
                            ...kid,
                            age: parseInt(e.target.value),
                          };

                          setJobInfo({
                            ...jobInfo,
                            kids: kidList,
                          });
                          updateErrorFields(
                            invalidData,
                            setInvalidData,
                            "age" + index
                          );
                        }}
                      >
                        <option value="0" selected disabled>
                          Age
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                      </select>
                      <div
                        className={
                          invalidData.includes("age" + index) ? "" : "d-none"
                        }
                      >
                        <p className="error-msg m-0">
                          {" "}
                          This field is required.
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        index >= 1
                          ? "ms-3 p-2 remove-icon-view mt-1 mx-2"
                          : "d-none p-2 remove-icon-view mt-1 mx-2"
                      }
                    >
                      <img
                        src={CrossIcon}
                        className="remove-icon-size"
                        onClick={() => removeKidInput(index)}
                      />
                    </div>
                  </div>
                </>
              ))}

              <div className="col-md-9 col-12 my-2 mx-5 d-flex align-items-center justify-content-center mt-3">
                <button onClick={addNewKidInput} className="btn btn-theme ms-5">
                  Add Kid
                </button>
              </div>
            </div>

            <div className="col-md-11 form-group mx-auto mt-4 mb-md-5 mb-3">
              <label className="form-label">
                Note to potential babysitters *
              </label>
              <textarea
                className="form-control"
                rows={4}
                name="note"
                value={jobInfo.note}
                onChange={(e) => {
                  setJobInfo({
                    ...jobInfo,
                    note: e.target.value,
                  });
                  updateErrorFields(invalidData, setInvalidData, "note");
                }}
              ></textarea>
              <div className={invalidData.includes("note") ? "" : "d-none"}>
                <p className="error-msg m-0"> This field is required.</p>
              </div>
            </div>
            {location.pathname === "/parent-register" ? (
              <div className="row  mt-4 px-0 mx-0">
                <div className="col-12 d-flex ms-md-4 ms-lg-5 term-checkbox ">
                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="smsApproval"
                      value={jobInfo.smsApproval}
                      onChange={(e) => {
                        setJobInfo({
                          ...jobInfo,
                          smsApproval: !jobInfo.smsApproval,
                        });
                        setInvalidData(
                          invalidData.filter((x) => x !== "smsApproval")
                        );
                      }}
                      id="smsApproval"
                    />
                    <label
                      className="form-check-label ms-3 font-16 font-style "
                      htmlFor="smsApproval"
                    >
                      I Agree to receive SMS/Text notifications and
                      commmunication from Highschoolbabysitters.com
                    </label>
                  </div>
                </div>
                <div
                  className={
                    invalidData.includes("smsApproval") ? "" : "d-none"
                  }
                >
                  <p className="error-msg m-0 ms-md-4 ms-lg-5">
                    {" "}
                    Please Accept notifications and commmunication terms to
                    continue.
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row  mt-sm-4 px-0 mx-0">
              <div className="col-12 d-flex ms-md-4 ms-lg-5 term-checkbox ">
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="terms"
                    value={jobInfo.terms}
                    onChange={(e) => {
                      setJobInfo({
                        ...jobInfo,
                        terms: !jobInfo.terms,
                      });
                      setInvalidData(invalidData.filter((x) => x !== "terms"));
                    }}
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label ms-3 font-16 font-style "
                    htmlFor="defaultCheck1"
                  >
                    I Accept your{" "}
                    <a
                      href={`/terms-and-conditions`}
                      className="term-text"
                      target={"_blank"}
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <div className={invalidData.includes("terms") ? "" : "d-none"}>
                <p className="error-msg m-0 ms-md-4 ms-lg-5">
                  {" "}
                  Please Accept terms and conditions to continue.
                </p>
              </div>
            </div>
          </form>
          <div className="d-flex justify-content-center">
            <button className="btn btn-theme my-4 " onClick={formValidation}>
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Post A Job"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobInfoForm;
