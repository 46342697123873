import moment from "moment";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../assets/css/common.scss";
import { handleErrorMessage, isPastDate } from "../helpers/utilities";
import useAuth from "../hooks/useAuth";
import usePaymentServices from "../hooks/web-services-hooks/usePaymentServices";
import { ISubscriptionModel } from "../models/ISubscriptionModel";
import AlertToasts from "../services/notification-services/notifications";
import BSSpinner from "./BSSpinner";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

const ChangeSubscription = () => {
  const [loader, setLoader] = useState<Boolean>(false);
  const [updatePlan, setUpdatePlan] = useState<string>("");
  const [cancelSubscriptionConfirmation, setCancelSubscriptionConfirmation] =
    useState<boolean>(false);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<ISubscriptionModel>({
      _id: "",
      amount: 0,
      createdAt: new Date(),
      endingBalance: 0,
      isActive: false,
      isCancelled: false,
      parentId: "",
      paymentMethodId: "",
      planId: "",
      stripeCustomerId: "",
      subscriptionId: "",
      subscriptionType: "",
      subsriptionEndDate: new Date(),
      subsriptionStartDate: new Date(),
      updatedAt: new Date(),
    });
  const PaymentServices = usePaymentServices();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoader(true);

    PaymentServices.updatePlan(updatePlan)
      .then((res) => {
        if (res.status === 200) {
          AlertToasts.success("Your current plan updated successfully");
          setLoader(false);
          setUpdatePlan("");
          navigate("/");
        }
      })
      .catch((e) => {
        if (updatePlan === "") {
          AlertToasts.error("Please select the plan to update");
        } else {
          handleErrorMessage(e);
        }
        setLoader(false);
      })
      .finally(() => setLoader(false));
  };

  const cancelSubscription = () => {
    setCancelSubscriptionConfirmation(true);
  };

  useEffect(() => {
    PaymentServices.currentPlan(auth._id).then((res) => {
      setSubscriptionDetails(res.data.data[0]);
      if (res.data.data[0].subscriptionType === "Yearly") {
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className="box-outside-view">
        <div className="container bg-white pb-5 px-md-5 rounded padding-top-56">
          <p className="page-subtitle text-center text-uppercase py-md-3">
            Modify your Subscription
          </p>
          {subscriptionDetails.isCancelled ? (
            !isPastDate(subscriptionDetails.subsriptionEndDate) ? (
              <p className="font-16 col-lg-9 col-sm-11 px-xl-4 px-lg-3 px-md-2 px-1 my-md-3 mx-auto">
                <span>
                  {`You do not have any active subscription plan at the moment. As a part of your previous subscription, you will be able to use services till ${moment(
                    subscriptionDetails.subsriptionEndDate
                  ).format("LLL")}`}
                </span>
              </p>
            ) : (
              <p className="scription-modify-desc col-lg-9 col-sm-11 px-xl-4 px-lg-3 px-md-2 px-1 my-md-3 mx-auto">
                No Active Plan{" "}
              </p>
            )
          ) : (
            <p className="scription-modify-desc col-lg-9 col-sm-11 px-xl-4 px-lg-3 px-md-2 px-1 my-md-3 mx-auto">
              Active Plan:{" "}
              <span>
                {subscriptionDetails.subscriptionType === "Yearly"
                  ? `Unlimited Babysitter Matches for $75.00 / Year (Valid till ${moment(
                      subscriptionDetails.subsriptionEndDate
                    ).format("LLL")} )`
                  : `Unlimited Babysitter Matches for $10.00 / Month (Valid till ${moment(
                      subscriptionDetails.subsriptionEndDate
                    ).format("LLL")} )`}
              </span>
            </p>
          )}

          <div className="row mx-0 px-0 mt-lg-2 pt-md-3 pt-1 ctsm-radio justify-content-center mb-5">
            <div className="col-xl-7 col-lg-9 col-md-11 my-3">
              <div className="form-check ">
                <input
                  className="form-check-input col-auto me-md-5  me-2"
                  type="radio"
                  name="flexRadioDefault"
                  value="Monthly"
                  id="flexRadioDefault1"
                  checked={updatePlan === "Monthly"}
                  onChange={(e) => setUpdatePlan(e.target.value)}
                  disabled={
                    !subscriptionDetails.isCancelled &&
                    subscriptionDetails.subscriptionType === "Monthly"
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Unlimited Babysitter Matches for $10.00 / Month, Cancel
                  anytime
                </label>
              </div>
            </div>
            <div className="col-xl-7 col-lg-9 col-md-11 ">
              <div className="form-check ">
                <input
                  className="form-check-input col-auto me-md-5  me-2"
                  type="radio"
                  name="flexRadioDefault2"
                  value="Yearly"
                  id="flexRadioDefault2"
                  checked={updatePlan === "Yearly"}
                  onChange={(e) => setUpdatePlan(e.target.value)}
                  disabled={
                    !subscriptionDetails.isCancelled &&
                    subscriptionDetails.subscriptionType === "Yearly"
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Unlimited Babysitter Matches for $75.00 / Year, Cancel anytime
                </label>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-7 col-12 d-flex px-0 mx-auto mt-md-5 mt-3">
            <button
              className={`btn btn-theme mx-auto ${loader ? "btn-loader" : ""}`}
              type="submit"
              onClick={handleSubmit}
            >
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Update Subscription"
              )}
            </button>
          </div>
          {subscriptionDetails.isCancelled ? (
            <React.Fragment></React.Fragment>
          ) : (
            <div className="col-xl-5 col-lg-7 col-12 d-flex px-0 mx-auto mt-md-4 mt-sm-3 mt-2 mb-3">
              <button
                className="btn btn-theme mx-auto"
                type="submit"
                onClick={cancelSubscription}
              >
                Cancel Subscription
              </button>
            </div>
          )}
        </div>
      </div>

      {cancelSubscriptionConfirmation ? (
        <CancelSubscriptionModal
          handleClose={() => setCancelSubscriptionConfirmation(false)}
          show={cancelSubscriptionConfirmation}
          subscriptionDetails={subscriptionDetails}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ChangeSubscription;
