// import { NavLink } from "react-bootstrap";
// import { useNavigate } from "react-router";

// const Footer = () => {

//   const navigate = useNavigate();

//   return (
//     <div className="row px-0 mx-0">
//       <ul className="col-12 d-flex justify-content-center px-0 mb-0">
//         <li className="footer-link py-3">
//           <NavLink onClick={() => {navigate('/terms-and-conditions')}}>Terms and Conditions</NavLink>
//         </li>
//         <li className="footer-link py-3">
//           <NavLink onClick={() => {navigate('/contact-us')}}>Contact Us</NavLink>
//         </li>
//       </ul>
//     </div>
//   );
// };

// export default Footer;


// start new code from here


import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";
import insta_icon from "../assets/images/instagramlogo.png";
import facebook_icon from "../assets/images/facebooklogo.png";
import "./footer.scss";
import footerlogo from "../assets/images/footer_logo.svg"


const Footer = () => {

  const navigate = useNavigate();

  return (
    
     
    <div className="footer px-0 mx-0 d-lg-flex justify-content-around align-items-center">

     <div className="footer-logo ms-sm-1 me-sm-0 mx-4 my-sm-0 my-4" style={{borderRadius:'10px'}}>
        <img src={footerlogo} />
      </div>
      <ul className="d-flex justify-content-center margin-top-footer px-0 mb-0 footer-links-container">
        <li className="footer-nav footer-spacing">
          <NavLink onClick={() => {navigate('/about')}} className="side_line fw-400">About Us</NavLink>
        </li>
        <li className="footer-nav footer-spacing">
          <NavLink onClick={() => {navigate('/work')}} className="side_line fw-400">How It Works</NavLink>
        </li>
        <li className="footer-nav footer-spacing">
        <NavLink onClick={() => {navigate('/contact-us')}} className="fw-400">Contact Us</NavLink>
        </li>
        <li className="footer-nav footer-spacing">
        <NavLink onClick={() => {navigate('/terms-and-conditions')}} className="side_line fw-400">Terms and Conditions</NavLink>
        </li>
        <li className="footer-nav footer-spacing">
          <NavLink onClick={() => {navigate('/login')}} className="side_line fw-400">Sign In</NavLink>
        </li>
        <li className="footer-nav footer-spacing">
          <NavLink onClick={() => {navigate('/')}} className="fw-400">Sign Up</NavLink>
        </li>
      </ul>
      
      <div className="social-icon">
        <a href="https://www.instagram.com/highschoolbabysitters/" target="_blank">
        <img src={insta_icon} height={'51px'} width={'51px'} className="me-xl-3 me-1"/>
        </a>
        <a href="https://www.facebook.com/people/HighSchool-Babysitterscom/61561342526482/" target="_blank">
        <img src={facebook_icon} height={'51px'} width={'51px'}/>
        </a>
      </div>

    </div>
     );
};

export default Footer;
