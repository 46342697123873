import { useState, useEffect, useRef } from "react";
import email from "../../assets/images/email.svg";
import openEye from "../../assets/images/open-eye.svg";
import closedEye from "../../assets/images/closed-eye.svg";
import "../../assets/css/common.scss";
import { IParentRegistrationModel } from "../../models/IParentRegistrationModel";
import {
  decryptData,
  handleErrorMessage,
  isStrongPassword,
  updateErrorFields,
  updateMultipleErrorFields,
  validateEmail,
  validPasswordErrorDisplay,
} from "../../helpers/utilities";
import PhoneInput from "react-phone-input-2";
import "../../assets/css/common.scss";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useParentServices from "../../hooks/web-services-hooks/useParentServices";
import AlertToasts from "../../services/notification-services/notifications";
import { IAuthModel } from "../../models/IAuthContextModel";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";

type Props = {
  handleStepChange: Function;
  edit?: boolean;
};

type passwordVisibility = {
  password: Boolean;
  currentPassword: Boolean;
  confirmPassword: Boolean;
};

const RegistrationFrom = ({ handleStepChange, edit }: Props) => {
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [visibility, setVisibility] = useState<passwordVisibility>({
    password: false,
    currentPassword: false,
    confirmPassword: false,
  });
  const [input, setInput] = useState<IParentRegistrationModel>({
    firstName: "",
    lastName: "",
    homeAddress: "",
    homeCity: "",
    homeZipCode: "",
    email: "",
    phoneNo: "",
    password: "",
    currentPassword: "",
    confirmPassword: "",
    role: "parent",
  });
  const [captchaVerification, setCaptchaVerification] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const ParentServices = useParentServices();
  const { auth, setAuth } = useAuth();
  let editedField = false;
  let navigate = useNavigate();

  const handleSubmit = () => {
    let parentInput: any = input;

    if (edit) {
      let editedParentInput: any = {};

      Object.keys(auth).forEach((key) => {
        if (
          parentInput[key] &&
          parentInput[key] !== auth[key as keyof typeof auth]
        ) {
          if (key === "phoneNo" && parentInput[key]) {
            let newPhoneNumber = parentInput[key].slice(1);
            if (newPhoneNumber === auth.phoneNo.toString()) {
              delete editedParentInput[key];
              navigate("/")
            } else {
              editedParentInput[key] =
                parentInput[key as keyof typeof auth].slice(1);
            }
          } else {
            editedParentInput[key] = parentInput[key as keyof typeof auth];
          }
          setAuth((oldVal: IAuthModel) => {
            return { ...oldVal, ...editedParentInput };
          });
        }
      });
      setLoader(true);
      if (edit && input.currentPassword?.length) {
        editedParentInput["currentPassword"] = input.currentPassword;
      }
      if (editedField && Object.keys(editedParentInput).length > 0) {
        ParentServices.editRegistration(editedParentInput, auth._id)
          .then((res: any) => {
            if (res.status === 200) {
              AlertToasts.success("Information updated successfully");
              navigate("/");
            }
          })
          .catch((error) => {
            handleErrorMessage(error);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    } else {
      setLoader(true);
      ParentServices.parentStep1Registration({
        ...parentInput,
        phoneNo: parentInput.phoneNo.slice(1),
      })
        .then((res: any) => {
          if (res.status === 200) {
            handleStepChange(1, res.data.data._id);
            sessionStorage.setItem("userId", res.data.data._id);
            sessionStorage.setItem("parentRegistration", JSON.stringify(input));
          } 
          // else if (
          //   res.data.data.completedSteps !== 3 &&
          //   res.data.data.role === input.role
          // ) {
          //   const latestStep: string = res.data.data.completedSteps;
          //   sessionStorage.setItem("userId", res.data.data._id);
          //   sessionStorage.setItem("formStep", latestStep);
          //   handleStepChange(latestStep, res.data.data._id);
          // } else if (res.data.data.role !== input.role) {
          //   sessionStorage.setItem("userId", res.data.data._id);
          //   sessionStorage.setItem("currentStep", res.data.data.completedSteps);
          //   navigate("/register");
          // } else {
          //   if (res.data.data.completedSteps === 3) {
          //     AlertToasts.error(
          //       `Registration already Completed, Login using your credentials.`
          //     );
          //   }
          // }
        })
        .catch((e) => {
          handleErrorMessage(e);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const checkVisibility = (filed: string) => {
    if (filed === "password") {
      setVisibility({ ...visibility, password: !visibility.password });
    }
    if (filed === "confirmPassword") {
      setVisibility({
        ...visibility,
        confirmPassword: !visibility.confirmPassword,
      });
    }
    if (filed === "currentPassword") {
      setVisibility({
        ...visibility,
        currentPassword: !visibility.currentPassword,
      });
    }
  };

  const formValidation = () => {
    let validationResult: boolean = true;
    let step1InvalidFields: string[] = [];

    if (input) {
      Object.keys(input).forEach((key: string) => {
        if (Object.hasOwn(input, key) && !input[key as keyof typeof input]) {
          validationResult = false;
          step1InvalidFields.push(key);
        }
        if (
          edit &&
          (!input.currentPassword?.length ||
            !input.password?.length ||
            !input.confirmPassword?.length)
        ) {
          step1InvalidFields = step1InvalidFields.filter(
            (x) =>
              !["password", "confirmPassword", "currentPassword"].includes(x)
          );
        }
      });
    }

    /**temporary bypassing password fields for edit */
    //Start
    if (edit) {
      if (
        input.currentPassword?.length ||
        input.password?.length ||
        input.newConfirmPassword?.length
      ) {
        if (input.currentPassword?.length && input.password === "") {
          validationResult = false;
          step1InvalidFields.push("password");
        }
        if (edit && input.password !== input.confirmPassword) {
          validationResult = false;
          step1InvalidFields.push("password-mismatch");
        }
        if (edit && input.password?.length && !input.currentPassword?.length) {
          validationResult = false;
          step1InvalidFields.push("currentPassword");
        }
        if(edit && input.password === input.currentPassword){
          validationResult = false;
          step1InvalidFields.push("samePassword");
        }
      } else {
        step1InvalidFields = step1InvalidFields.filter(
          (x) => !["password", "confirmPassword", "currentPassword"].includes(x)
        );

        if (!step1InvalidFields.length) {
          validationResult = true;
        }
      }
    }
    //End

    if (!edit && input.password !== input.confirmPassword) {
      validationResult = false;
      step1InvalidFields.push("password-mismatch");
    }
    if (
      !edit &&
      step1InvalidFields.includes("currentPassword") &&
      !step1InvalidFields.includes("password") &&
      !step1InvalidFields.includes("confirmPassword")
    ) {
      step1InvalidFields = step1InvalidFields.filter(
        (x) => !["currentPassword"].includes(x)
      );
      validationResult = true;
    }

    if (!validateEmail(input.email)) {
      validationResult = false;
      step1InvalidFields.push("email");
    }

    if (input.phoneNo.length !== 11) {
      //Field includes country code sign as well, that makes total length 11
      validationResult = false;
      step1InvalidFields.push("phoneNo");
    }

    if (input.phoneNo.length == 11 && input.phoneNo.charAt(1) == "0") {
      validationResult = false;
      step1InvalidFields.push("invalid-phoneNo");
    }

    if (!input.homeZipCode.length || input.homeZipCode.length > 6) {
      validationResult = false;
      step1InvalidFields.push("homeZipCode");
    }

    setInvalidFields(step1InvalidFields);
    

    if (validationResult) {
      handleSubmit();
    } else {
      window.scrollTo(0, 0); 
      setLoader(false)
    }
  };

  useEffect(() => {
    if (edit && auth._id) {
      setInput({
        ...input,
        confirmPassword: "",
        email: auth.email,
        firstName: auth.firstName,
        lastName: auth.lastName,
        homeAddress: auth.homeAddress,
        homeCity: auth.homeCity,
        homeZipCode: auth.homeZipCode,
        password: "",
        phoneNo: `1${auth.phoneNo}`,
        role: "parent",
      });
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    editedField = true;
  }, [input]);

  return (
    <>
      <div
        className={edit ? "container bg-white pb-5 px-md-5 rounded pt-5" : ""}
      >
        <div className="row text-center mt-5">
          <div className="col-12">
            <h1 className="form-title">
              {edit ? "Edit Your Information" : "REGISTRATION"}
            </h1>
          </div>
          <div className="col-12 text-center">
            <p className="page-discription m-0 p-0">
              The first thing we need to do is get you registered
            </p>
            <p className="page-discription m-0 p-0">as a parent.</p>
          </div>
        </div>

        <form className="row gy-3 my-3 mx-auto" >
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">First Name *</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                value={input.firstName}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (/^[a-zA-Z]*$/.test(inputVal)) {
                    setInput({
                      ...input,
                      firstName: inputVal,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "firstName"
                    );
                  }
                }}
                placeholder="First Name"
              />
              <div
                className={`${
                  invalidFields.includes("firstName") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter First Name</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Last Name *</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                value={input.lastName}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (/^[a-zA-Z]*$/.test(inputVal)) {
                    setInput({
                      ...input,
                      lastName: inputVal,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "lastName"
                    );
                  }
                }}
                placeholder="Last Name"
              />
              <div
                className={`${
                  invalidFields.includes("lastName") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter Last Name</p>
              </div>
            </div>
          </div>

          <div className="col-12 d-sm-block d-none">
            <div className="form-group">
              <label className="form-label">
                Home Address (A different babysitting address can be added
                later) *
              </label>
              <input
                type="text"
                className="form-control"
                name="homeAddress"
                value={input.homeAddress}
                onChange={(e) => {
                  setInput({
                    ...input,
                    homeAddress: e.target.value,
                  });
                  updateErrorFields(
                    invalidFields,
                    setInvalidFields,
                    "homeAddress"
                  );
                }}
                placeholder="Home Address"
              />
              <div
                className={`${
                  invalidFields.includes("homeAddress") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  {" "}
                  Please enter valid Home Address
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 d-block d-sm-none">
            <div className="form-group">
              <label className="form-label">
                Home Address (A different babysitting address can be added
                later) *
              </label>
              <textarea
                rows={3}
                className="form-control"
                name="homeAddress"
                value={input.homeAddress}
                onChange={(e) => {
                  setInput({
                    ...input,
                    homeAddress: e.target.value,
                  });
                  updateErrorFields(
                    invalidFields,
                    setInvalidFields,
                    "homeAddress"
                  );
                }}
                placeholder="Home Address"
              />
              <div
                className={`${
                  invalidFields.includes("homeAddress") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  {" "}
                  Please enter valid Home Address
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">City *</label>
              <input
                type="text"
                className="form-control"
                name="homeCity"
                value={input.homeCity}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (/^[a-zA-Z ]*$/.test(inputVal)) {
                    setInput({
                      ...input,
                      homeCity: inputVal,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "homeCity"
                    );
                  }
                }}
                placeholder="City"
                required
              />
              <div
                className={`${
                  invalidFields.includes("homeCity") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter valid Home City</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code *</label>
              <input
                type="text"
                className="form-control"
                name="homeZipCode"
                value={input.homeZipCode}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (/^[0-9]+$/.test(inputVal) && inputVal.length <= 6) {
                    setInput({
                      ...input,
                      homeZipCode: inputVal,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "homeZipCode"
                    );
                  } else if (!inputVal.length) {
                    setInput({
                      ...input,
                      homeZipCode: "",
                    });
                  }
                }}
                placeholder="Zip Code"
              />
              <div
                className={`${
                  invalidFields.includes("homeZipCode") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter valid Zip Code</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group email-group">
              <label className="form-label">Email *</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={input.email}
                onChange={(e) => {
                  setInput({
                    ...input,
                    email: e.target.value,
                  });
                  updateErrorFields(invalidFields, setInvalidFields, "email");
                }}
                placeholder="example@gmail.com"
                autoComplete="new-email"
              />
              <div className="email-icon">
                <img src={email} />
              </div>
              <div
                className={`${
                  invalidFields.includes("email") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  Please enter valid Email Address
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group cstm-input">
              <label className="form-label">Cell Phone *</label>
              <PhoneInput
                countryCodeEditable={false}
                country={"us"}
                onlyCountries={["us"]}
                value={input.phoneNo}
                onChange={(phone) => {
                  setInput({ ...input, phoneNo: phone });
                  updateMultipleErrorFields(invalidFields, setInvalidFields, [
                    "phoneNo",
                    "invalid-phoneNo",
                  ]);
                }}
              />
              <div
                className={`${
                  invalidFields.includes("phoneNo") ||
                  invalidFields.includes("invalid-phoneNo")
                    ? ""
                    : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  {" "}
                  {invalidFields.includes("phoneNo")
                    ? "Please enter valid Phone Number"
                    : "Contact number should not starts with zero"}
                </p>
              </div>
            </div>
          </div>
          {edit ? (
            <>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Current Password *</label>
                  <input
                    type={visibility.currentPassword ? "text" : "password"}
                    className="form-control"
                    name="currentPassword"
                    value={input.currentPassword}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        currentPassword: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "currentPassword"
                      );
                    }}
                    placeholder="Enter Current Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("currentPassword")}
                  >
                    {visibility.currentPassword ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("currentPassword") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      Please enter valid Current Password
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Choose New Password *</label>
                  <input
                    type={visibility.password ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={input.password}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        password: e.target.value,
                      });
                      updateMultipleErrorFields(
                        invalidFields,
                        setInvalidFields,
                        ["password", "samePassword"]
                      );
                      if (isStrongPassword(e.target.value)) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "weak-password"
                        );
                      }
                    }}
                    onBlur={() => {
                      validPasswordErrorDisplay(
                        input.password,
                        invalidFields,
                        setInvalidFields
                      );
                    }}
                    placeholder="Choose New Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("password")}
                  >
                    {visibility.password ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("password") ||
                      invalidFields.includes("weak-password") ||
                      invalidFields.includes("samePassword") 
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {`${
                        invalidFields.includes("password")
                          ? "Please enter valid Password"
                          : invalidFields.includes("weak-password") ? "Password must have one lowercase, one uppercase, one number, one special character, and must be 8 digits long" : "New password should be different than old password"
                      }`}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Re-type New Password *</label>
                  <input
                    type={visibility.confirmPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmPassword"
                    value={input.confirmPassword}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        confirmPassword: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "confirmPassword"
                      );
                    }}
                    onBlur={() => {
                      if (input.password === input.confirmPassword) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "password-mismatch"
                        );
                      }
                    }}
                    placeholder="Re-type New Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("confirmPassword")}
                  >
                    {visibility.confirmPassword ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("confirmPassword") ||
                      invalidFields.includes("password-mismatch")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {" "}
                      {invalidFields.includes("confirmPassword")
                        ? "Please enter valid confirm password"
                        : invalidFields.includes("password-mismatch")
                        ? "Choose Password and Re-type Password must be the same"
                        : ""}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Choose Password *</label>
                  <input
                    type={visibility.password ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={input.password}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        password: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "password"
                      );
                      if (isStrongPassword(e.target.value)) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "weak-password"
                        );
                      }
                    }}
                    onBlur={() => {
                      validPasswordErrorDisplay(
                        input.password,
                        invalidFields,
                        setInvalidFields
                      );
                    }}
                    placeholder="Choose Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("password")}
                  >
                    {visibility.password ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("password") ||
                      invalidFields.includes("weak-password")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {`${
                        invalidFields.includes("password")
                          ? "Please enter valid Password"
                          : "Password must have one lowercase, one uppercase, one number, one special character, and must be 8 digits long"
                      }`}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Re-type Password *</label>
                  <input
                    type={visibility.confirmPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmPassword"
                    value={input.confirmPassword}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        confirmPassword: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "confirmPassword"
                      );
                    }}
                    onBlur={() => {
                      if (input.password === input.confirmPassword) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "password-mismatch"
                        );
                      }
                    }}
                    placeholder="Re-type Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("confirmPassword")}
                  >
                    {visibility.confirmPassword ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("confirmPassword") ||
                      invalidFields.includes("password-mismatch")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {" "}
                      {invalidFields.includes("confirmPassword")
                        ? "Please enter valid confirm password"
                        : "Choose Password and Re-type Password must be the same"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
        {!edit ? (
          <div className="d-flex justify-content-center mt-5">
            {/*
          // @ts-ignore */}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
              onChange={() => {
                setCaptchaVerification(true);
              }}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="d-flex justify-content-center mt-5">
          <button
            className="btn btn-theme mb-3"
            onClick={formValidation}
            disabled={!edit && !captchaVerification}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <React.Fragment>{edit ? "Update" : "Next"}</React.Fragment>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default RegistrationFrom;
