import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ISteps } from "../models/ISteps";
import AboutForm from "../components/BabySitterRegistration/AboutYou";
import BabySitterRegistrationFrom from "../components/BabySitterRegistration/BabySitterRegistrationFrom";
import SafetyInfoForm from "../components/BabySitterRegistration/SafetyInfoForm";
import { StepIconProps } from "@mui/material/StepIcon";
import "../assets/css/progressbar.scss";
import HowItWorks from "../components/HowItWorks";

const stepsArray: ISteps[] = [
  {
    label: "Registration",
  },
  {
    label: "About you",
  },
  {
    label: "Safety info",
  },
  {
    label: "Babysitting jobs",
  },
];

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#A45BB4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#A45BB4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 12,
    border: 1,
    backgroundColor: "#EFEFEF",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: "#EFEFEF",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#fffff",
    border: "4px solid #A45BB4",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#A45BB4",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {/* {icons[String(props.icon)]} */}
    </ColorlibStepIconRoot>
  );
}

const BSRegistration = () => {
  const [currentFormStep, setCurrentFormStep] = useState<number>(0);
  const [userId, setUserId] = useState<string>("");

  const handleStepChange = (step: number, id?: string) => {
    setCurrentFormStep(step);
    if (id) {
      setUserId(id);
    }
  };

  const stepContent = () => {
    switch (currentFormStep) {
      case 0:
        return (
          <BabySitterRegistrationFrom handleStepChange={handleStepChange} />
        );
      case 1:
        return (
          <AboutForm handleStepChange={handleStepChange} userId={userId} />
        );
      case 2:
        return (
          <SafetyInfoForm handleStepChange={handleStepChange} userId={userId} />
        );
      case 3:
        return <HowItWorks />;
    }
  };

  useEffect(() => {
    const currentStep: string | null = localStorage.getItem("currentStep");
    if (currentStep && !isNaN(parseInt(currentStep))) {
      setCurrentFormStep(parseInt(currentStep));
    }

    const currentUserId = localStorage.getItem("userId");
    if (currentUserId && currentUserId.length) {
      setUserId(currentUserId);
    }

    // Update meta description directly
    document.title = "Highschool Babysitter Jobs Near You";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "HighSchoolBabysitters.com matches highschoolers looking for babysitting jobs with families with small children looking for local babysitters."
      );
    }
  }, []);

  return (
    <>
      <div className="px-3">
        <p className="page-title text-center text-uppercase mt-3 mb-4">
          Sitter Registration
        </p>

        <div className="container bg-white pb-5 px-md-5 rounded steper-top">
          <div className="customize-progress">
            <Stepper
              activeStep={currentFormStep}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {stepsArray.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {step.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {stepContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default BSRegistration;
