import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import React from "react";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      } 
    };
    !auth.token ? verifyRefreshToken() : setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      { isLoading ? <React.Fragment></React.Fragment> : <Outlet />}
    </React.Fragment>
  );
};

export default PersistLogin;
