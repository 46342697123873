import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/common.scss";
import "../assets/css/workpage.scss";
import Arrow from "../assets/images/bottom-arrow.svg";
import jobboard from "../assets/images/jobboard.png";
import AlertToasts from "../services/notification-services/notifications";
import useAuth from "../hooks/useAuth";
import React from "react";

type Props = {
  isStandALone?: boolean;
  role?: string;
};

const babySitterSteps = {
  step1: `Verification emails have been sent to your email address and to your parent/guardian's email address.  Once you and your parent/guardian verify your account, you are fully registered as a babysitter in your area. Once you are fully registered, you will be sent a text and email when a parent posts a babysitting job that you qualify for in your area. Be on the lookout for our texts and emails.`,
  step2: `Once you get a text you will be able to click if you are interested in the babysitting job. Once you say you 
  are interested, the parent will be notified, and you will be added to a list of interested babysitters for the 
  parent to choose from.`,
  step3: `When the parent chooses you for the job, we will introduce you to them through our message board.  
  On the message board is where you can ask them additional question. Once you and your 
  parent/guardian are comfortable with them, you can accept the babysitting job.`,
};

const parentSteps = {
  step1: `Now that you posted your babysitting job, we have notified all babysitters in the area that meet the 
  criteria you put into your babysitter requirements page. We do not give them the babysitting address nor 
  your full name. The local babysitters are asked if they are interested in the job.`,
  step2: `When a babysitter clicks, “I’m interested,” they will show up on your job board as an interested 
  babysitter. You will be able to see their first name, GPA, if they drive, safety training, etc. You should let 
  the number of interested babysitters build up before selecting your top choice. If you are not getting 
  enough interested babysitters, you will want to cancel the job then go into your account and remove 
  some babysitter requirements. Then re-post the job to allow us to notify more eligible babysitters in the 
  area.`,
  step3: `Once you choose your babysitter, you will be directed to pay a finder’s fee. After this we will introduce 
  you to the babysitter, as well as the babysitters’ parents, on our message board. This message board is 
  where you can discuss additional details about the babysitting job directly with the babysitter and their 
  parents.`,
};

const HowItWorks = ({ isStandALone, role }: Props) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [roleBasedContent, setRoleBasedContent] = useState({
    role : role || auth.role,
    stepContent :role === "parent" || auth.role === "parent" ? parentSteps : babySitterSteps 
  })
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={isStandALone ? "box-outside-view" : ""}>
        <div
          className={
            isStandALone ? "container bg-white pb-5 px-md-5 rounded" : ""
          }
        >
          <div className="row mb-5">
            <div className="col-12 mt-5">
              <h1 className="page-subtitle text-center mt-5">How it works</h1>
              <p className="page-sub-desc text-center p-0 m-0">
                Now that you are registered as a {`${roleBasedContent.role === "parent" ? "Parent" : "Babysitter"}`} in your town.{" "}
              </p>
              <p className="page-sub-desc text-center p-0 m-0">
                {" "}
                here is how it works.
              </p>
            </div>
          </div>

          <div className=" d-none d-sm-block">
            <div className="row mx-sm-4 mb-5">
              <div className="col-auto pr-2">
                <div className="list-circle">
                  <h1 className="list-num-text m-0 p-0">1</h1>
                </div>
              </div>
              <div className="col">
                <h2 className="list-title m-0 p-0">Step One</h2>
                <p className="list-description">{roleBasedContent.stepContent?.step1}</p>
              </div>
            </div>
          </div>
          {/* -------------mobile View----------------- */}
          <div className="d-block d-sm-none">
            <div className="row mx-2 mx-sm-4 mb-3">
              <div className="col-12 mx-0">
                <div className="row align-items-center px-0 mx-0">
                  <div className="col-auto list-circle">
                    <h1 className="list-num-text m-0 p-0">1</h1>
                  </div>
                  <div className="ps-2 col-auto">
                    <h2 className="list-title m-0 p-0">Step one</h2>
                  </div>
                </div>
              </div>
              <div className="row mt-2 ">
                <p className="list-description">{roleBasedContent.stepContent.step1}</p>
              </div>
            </div>
          </div>

          <div className="d-sm-block d-none">
            <div className="row mx-sm-4 mb-5">
              <img src={Arrow} className="d-md-block d-none about-page-arrow" />

              <div className="col-auto pr-2">
                <div className="list-circle">
                  <h1 className="list-num-text m-0 p-0">2</h1>
                </div>
              </div>
              <div className="col">
                <h2 className="list-title m-0 p-0">Step two</h2>
                <p className="list-description">{roleBasedContent.stepContent.step2}</p>
              </div>
            </div>
          </div>

          {/* -------------mobile View----------------- */}
          <div className="d-block d-sm-none">
            <div className="row mx-2 mx-sm-4 mb-3">
              <div className="col-12 mx-0">
                <div className="row align-items-center px-0 mx-0">
                  <div className="col-auto list-circle">
                    <h1 className="list-num-text m-0 p-0">2</h1>
                  </div>
                  <div className="ps-2 col-auto">
                    <h2 className="list-title m-0 p-0">Step Two</h2>
                  </div>
                </div>
              </div>
              <div className="row mt-2 ">
                <p className="list-description">{roleBasedContent.stepContent.step2}</p>
              </div>
            </div>
          </div>

          <div className="d-sm-block d-none">
            <div className="row mx-sm-4 mb-5">
              <div className="col-auto pr-2">
                <div className="list-circle">
                  <h1 className="list-num-text m-0 p-0">3</h1>
                </div>
              </div>
              <div className="col ">
                <h2 className="list-title m-0 p-0">Step three</h2>
                <p className="list-description">{roleBasedContent.stepContent.step3}</p>
              </div>
            </div>
          </div>
          {/* -------------mobile View----------------- */}
          <div className="d-block d-sm-none">
            <div className="row mx-2 mx-sm-4 mb-3">
              <div className="col-12 mx-0">
                <div className="row align-items-center px-0 mx-0">
                  <div className="col-auto list-circle">
                    <h1 className="list-num-text m-0 p-0">3</h1>
                  </div>
                  <div className="ps-2 col-auto">
                    <h2 className="list-title m-0 p-0">Step Three</h2>
                  </div>
                </div>
              </div>
              <div className="row mt-2 ">
                <p className="list-description">{roleBasedContent.stepContent.step3}</p>
              </div>
            </div>
          </div>
          {/* {roleBasedContent.role === "sitter" ? (
            <div className="mx-4 mt-2 px-0">
              <img src={jobboard} className="img-fluid" alt="jobboard-img" />
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}  */}

          {isStandALone ? (
            <React.Fragment></React.Fragment>
          ) : (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-theme my-5"
                onClick={() => {
                  AlertToasts.success(
                    "Registerd successfully, Please Login to continue"
                  );
                  navigate("/login");
                  sessionStorage.clear();
                }}
              >
                Login to continue
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
