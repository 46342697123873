import React from 'react'
import SafetyInfoForm from '../components/BabySitterRegistration/SafetyInfoForm'

const EditGuardianInfo = () => {
  return (
    <div>
      <SafetyInfoForm handleStepChange={() => {}} userId={""} edit={true}/>
    </div>
  )
}

export default EditGuardianInfo
