import { toast } from "react-toastify";


class AlertToastsService {
  error = (message: string) => {
    toast.error(message);
  };

  info = (message: string) => {
    toast.info(message, {autoClose:7000});
  };

  success = (message: string) => {
    toast.success(message);
  };

  toast = (message: string) => {
    toast.dark(message);
  };

  warning = (message: string) => {
    this.dark(message, 'warning-toast');
  };
  dark = (message: string, className: string) => {
    toast.dark(message, {
      className: className,
    });
  };
}

const AlertToasts = new AlertToastsService();
export default AlertToasts;
