import React from "react";
import Spinner from "react-bootstrap/Spinner";

const BSSpinner = () => {
  return (
    <>
      <Spinner
        animation="border"
        className="d-flex justify-content-center align-items-center bsspin m-auto p-3"
      />
    </>
  );
};

export default BSSpinner;
