import useAuth from "./useAuth";
import { IAuthModel } from "../models/IAuthContextModel";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import AlertToasts from "../services/notification-services/notifications";
import { publicURLs } from "../helpers/constants";

const BASE_URL = process.env.REACT_APP_API_URL;

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigationOnExpiredSession = () => {
    if(!publicURLs.includes(location.pathname))
    {
      AlertToasts.error("Please Login to continue");
      setAuth({});
      navigate("/login");
    }
  }

  const refresh = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/auth/refreshToken`, {
        withCredentials: true
    });
      if (response.status == 200) {
        setAuth((prev: IAuthModel) => {
          return { ...prev, ...response.data };
        });
        return response.data.token;
      }
    } catch (e) {
      handleNavigationOnExpiredSession();
    }
  };

  return refresh;
};

export default useRefreshToken;
