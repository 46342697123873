import React, { useState, useEffect } from "react";
import email from "../../assets/images/email.svg";
import openEye from "../../assets/images/open-eye.svg";
import closedEye from "../../assets/images/closed-eye.svg";
import "../../assets/css/common.scss";
import {
  CoOrdinates,
  IBSRegistrationModel,
} from "../../models/IBSRegistrationModel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  calculateGeoDistance,
  decryptData,
  handleErrorMessage,
  isSameDate,
  isStrongPassword,
  updateErrorFields,
  updateMultipleErrorFields,
  validateEmail,
  validPasswordErrorDisplay,
} from "../../helpers/utilities";
import AlertToasts from "../../services/notification-services/notifications";
import useRegistrationServices from "../../hooks/web-services-hooks/useRegistrationServices";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { IAuthModel } from "../../models/IAuthContextModel";
import ReactDatePicker from "../ReactDatePicker";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
  handleStepChange: Function;
  edit?: boolean;
};

type passwordVisibility = {
  password: Boolean;
  confirmPassword: Boolean;
  currentPassword: Boolean;
};

const BabySitterRegistrationFrom = ({ handleStepChange, edit }: Props) => {
  //Blocking the dates from current date to 12 years as minimum age of baby sitter is 12
  const currentYear: Date = new Date();
  currentYear.setFullYear(currentYear.getFullYear() - 17);

  const [formInputs, setFormInputs] = useState<IBSRegistrationModel>({
    confirmPassword: "",
    dateOfBirth: null,
    email: "",
    firstName: "",
    gender: "",
    homeAddress: "",
    homeCity: "",
    homeZipCode: "",
    lastName: "",
    password: "",
    phoneNo: "",
    role: "sitter",
    currentPassword: "",
    smsApproval: false,
    step: 1,
  });

  const [visibility, setVisibility] = useState<passwordVisibility>({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [captchaVerification, setCaptchaVerification] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const RegistrationServices = useRegistrationServices();
  const { auth, setAuth } = useAuth();
  let editedField: Boolean = false;
  const navigate = useNavigate();

  const submitStep1 = async () => {
    
    let babySitterInput: any = formInputs;

    if (edit) {
      let editedBabySitterInput: any = {};

      Object.keys(auth).forEach((key) => {
        if (
          babySitterInput[key] &&
          babySitterInput[key] !== auth[key as keyof typeof auth]
        ) {
          if (key === "phoneNo" && babySitterInput[key]) {
            let newPhoneNumber = babySitterInput[key].slice(1);
            if (newPhoneNumber === auth.phoneNo.toString()) {
              delete editedBabySitterInput[key];
              navigate("/")
            } else {
              editedBabySitterInput[key] =
                babySitterInput[key as keyof typeof auth].slice(1);
            }
          } else {
            editedBabySitterInput[key] =
              babySitterInput[key as keyof typeof auth];
          }
          if (
            babySitterInput[key as keyof typeof auth] instanceof Date &&
            !isNaN(babySitterInput[key as keyof typeof auth]) &&
            formInputs.dateOfBirth &&
            isSameDate(formInputs.dateOfBirth, new Date(auth.dateOfBirth))
          ) {
            delete editedBabySitterInput[key];
          }
          setAuth((oldVal: IAuthModel) => {
            return { ...oldVal, ...editedBabySitterInput };
          });
        }
      });
      setLoader(true);
      if (edit && formInputs.currentPassword?.length) {
        editedBabySitterInput["currentPassword"] =
          babySitterInput.currentPassword;
      }
      if (editedField && Object.keys(editedBabySitterInput).length > 0) {
        const coOrdinates: CoOrdinates = await calculateGeoDistance(
          `${formInputs.homeAddress}, ${formInputs.homeCity}, ${formInputs.homeZipCode}`
        );
        RegistrationServices.editRegistration(
          {
            ...editedBabySitterInput,
            ...coOrdinates,
          },
          auth._id
        )
          .then((res: any) => {
            if (res.status === 200) {
              AlertToasts.success("Information updated successfully");
              navigate("/");
            }
          })
          .catch((e) => {
            handleErrorMessage(e);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    } else {
      setLoader(true);
      const coOrdinates: CoOrdinates = await calculateGeoDistance(
        `${formInputs.homeAddress}, ${formInputs.homeCity}, ${formInputs.homeZipCode}`
        // formInputs.homeAddress
      );
      RegistrationServices.babySitterStep1Submit({
        ...babySitterInput,
        ...coOrdinates,
        phoneNo: babySitterInput.phoneNo.slice(1),
      })
        .then((res) => {
          if (res.status === 200) {
            handleStepChange(1, res.data.data._id);
            sessionStorage.setItem(
              "registrationForm",
              JSON.stringify(formInputs)
            );
            sessionStorage.setItem("userId", res.data.data._id);
          }else{
            handleErrorMessage("Something went wrong");
          }
          //  else if (
          //   res.data.data.completedSteps !== 3 &&
          //   res.data.data.role === formInputs.role
          // ) {
          //   const latestStep: string = res.data.data.completedSteps;
          //   sessionStorage.setItem("userId", res.data.data._id);
          //   sessionStorage.setItem("currentStep", latestStep);
          //   handleStepChange(latestStep, res.data.data._id);
          // } else {
          //   if (res.data.data.completedSteps === 3) {
          //     AlertToasts.error(
          //       `Registration already Completed, Login using your credentials.`
          //     );
          //   } else if (res.data.data.role !== formInputs.role) {
          //     sessionStorage.setItem("userId", res.data.data._id);
          //     sessionStorage.setItem("formStep", res.data.data.completedSteps);
          //     navigate("/parent-register");
          //   }
          // }
        })
        .catch((e) => {
          handleErrorMessage(e);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const checkVisibility = (filed: string) => {
    if (filed === "password") {
      setVisibility({ ...visibility, password: !visibility.password });
    }
    if (filed === "confirmPassword") {
      setVisibility({
        ...visibility,
        confirmPassword: !visibility.confirmPassword,
      });
    }
    if (filed === "currentPassword") {
      setVisibility({
        ...visibility,
        currentPassword: !visibility.currentPassword,
      });
    }
  };

  const formValidation = async () => {
    let validationResult: boolean = true;
    let step1InvalidFields: string[] = [];
    const coOrdinates: CoOrdinates = await calculateGeoDistance(
      `${formInputs.homeAddress}, ${formInputs.homeCity},${formInputs.homeZipCode}`
    );
    if (formInputs) {
      Object.keys(formInputs).forEach((key: string) => {
        if (
          Object.hasOwn(formInputs, key) &&
          !formInputs[key as keyof typeof formInputs]
        ) {
          validationResult = false;
          step1InvalidFields.push(key);
        }

        if (
          edit &&
          (!formInputs.currentPassword?.length ||
            !formInputs.password?.length ||
            !formInputs.confirmPassword?.length)
        ) {
          step1InvalidFields = step1InvalidFields.filter(
            (x) =>
              ![
                "password",
                "confirmPassword",
                "currentPassword",
                "weak-password",
              ].includes(x)
          );
        }
      });
    }
    // ----- Checking for valid lat & long --------
    if(formInputs.homeAddress && (coOrdinates.lat == 0 && coOrdinates.lng == 0 )){
      validationResult = false;
      step1InvalidFields.push("invalid-address");
    }

    /**temporary bypassing password fields for edit */
    //Start
    if (edit) {
      if (
        formInputs.currentPassword?.length ||
        formInputs.password?.length ||
        formInputs.confirmPassword?.length
      ) {
        if (edit && formInputs.password !== formInputs.confirmPassword) {
          validationResult = false;
          step1InvalidFields.push("password-mismatch");
        }
        if(edit && formInputs.password === formInputs.currentPassword){
          validationResult = false;
          step1InvalidFields.push("samePassword");
        }
      } else {
        step1InvalidFields = step1InvalidFields.filter(
          (x) =>
            ![
              "password",
              "confirmPassword",
              "currentPassword",
              "weak-password",
            ].includes(x)
        );

        if (!step1InvalidFields.length) {
          validationResult = true;
        }
      }
    }
    //End

    if (!edit && formInputs.password !== formInputs.confirmPassword) {
      validationResult = false;
      step1InvalidFields.push("password-mismatch");
    }
    if (formInputs.phoneNo.length == 11 && formInputs.phoneNo.charAt(1) == "0") {
      validationResult = false;
      step1InvalidFields.push("invalid-phoneNo");
    }

    if (
      !edit &&
      step1InvalidFields.includes("currentPassword") &&
      !step1InvalidFields.includes("password") &&
      !step1InvalidFields.includes("confirmPassword")
    ) {
      step1InvalidFields = step1InvalidFields.filter(
        (x) => !["currentPassword"].includes(x)
      );
      if (!step1InvalidFields.length) {
        validationResult = true;
      }
    }

    if(edit && step1InvalidFields.includes("smsApproval")){
      step1InvalidFields = step1InvalidFields.filter((curVal) => !["smsApproval"].includes(curVal))
      if (!step1InvalidFields.length) {
        validationResult = true;
      }
    }

    if (
      !edit &&
      formInputs.password?.length &&
      !isStrongPassword(formInputs.password)
    ) {
      validationResult = false;
      step1InvalidFields.push("weak-password");
    }

    if (!validateEmail(formInputs.email)) {
      validationResult = false;
      step1InvalidFields.push("email");
    }

    if (formInputs.phoneNo.length !== 11) {
      //Field includes country code sign as well, that makes total length 11
      validationResult = false;
      step1InvalidFields.push("phoneNo");
    }

    if (
      formInputs.homeZipCode.length !== 5 &&
      formInputs.homeZipCode.length !== 6
    ) {
      validationResult = false;
      step1InvalidFields.push("homeZipCode");
    }
    setInvalidFields(step1InvalidFields);

    if (validationResult) {
      submitStep1();
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (edit && auth._id) {
      setFormInputs({
        ...formInputs,
        confirmPassword: "",
        dateOfBirth: new Date(auth.dateOfBirth),
        gender: auth.gender,
        email: auth.email,
        firstName: auth.firstName,
        lastName: auth.lastName,
        homeAddress: auth.homeAddress,
        homeCity: auth.homeCity,
        homeZipCode: auth.homeZipCode,
        password: "",
        phoneNo: `1${auth.phoneNo}`,
        role: "sitter",
      });
    }
  }, []);

  useEffect(() => {
    editedField = true;
  }, [formInputs]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div
        className={edit ? "container bg-white pb-5 px-md-5 rounded pt-5" : ""}
      >
        <div className={"row text-center mt-5"}>
          <div className="col-12">
            <h1 className="form-title">
              {" "}
              {edit ? "Edit Your Information" : "REGISTRATION"}
            </h1>
          </div>
          <div className="col-12 text-center">
            <p className="page-discription m-0 p-0">
              The first thing we need to do is get you registered
            </p>
            <p className="page-discription m-0 p-0">
              as a babysitter in the area.
            </p>
          </div>
        </div>
        <form className="row gy-3 my-3 mx-auto">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">First Name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                value={formInputs.firstName}
                onChange={(e) => {
                  let input = e.target.value;
                  if (/^[a-zA-Z]*$/.test(input)) {
                    setFormInputs({
                      ...formInputs,
                      firstName: input,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "firstName"
                    );
                  }
                }}
                required
              />
              <div
                className={`${
                  invalidFields.includes("firstName") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter First Name</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Last Name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                value={formInputs.lastName}
                onChange={(e) => {
                  let input = e.target.value;
                  if (/^[a-zA-Z]*$/.test(input)) {
                    setFormInputs({
                      ...formInputs,
                      lastName: input,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "lastName"
                    );
                  }
                }}
                required
              />
              <div
                className={`${
                  invalidFields.includes("lastName") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter Last Name</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group date-picker-cutm date-icon-hide">
              <label className="form-label label-Star-UI">
                Date Of Birth *
              </label>
              <ReactDatePicker
                className="form-control"
                minDate={currentYear}
                maxDate={new Date()}
                selected={formInputs.dateOfBirth}
                showYearDropdown={true}
                showMonthDropdown={true}
                dropdownMode={"select"}
                onChange={(date: Date) => {
                  setFormInputs({
                    ...formInputs,
                    dateOfBirth: date,
                  });
                  updateErrorFields(
                    invalidFields,
                    setInvalidFields,
                    "dateOfBirth"
                  );
                }}
                onFocus={(e:any) => e.target.blur()}
                placeholderText="mm/dd/yyyy"
              />
              <p className="mb-0">(Babysitter's age must be under 18)</p>
              <div
                className={`${
                  invalidFields.includes("dateOfBirth") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  {" "}
                  Please select valid Date of Birth
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Gender *</label>
              <select
                id="inputState"
                className="form-select"
                value={formInputs.gender}
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    gender: e.target.value,
                  });
                  updateErrorFields(invalidFields, setInvalidFields, "gender");
                }}
              >
                <option value="" selected disabled>
                  Select Option
                </option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Other">Other</option>
              </select>
              <div
                className={`${
                  invalidFields.includes("gender") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please Select Gender</p>
              </div>
            </div>
          </div>
          <div className="col-12 d-sm-block d-none">
            <div className="form-group">
              <label className="form-label">Home Address *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Home Address"
                value={formInputs.homeAddress}
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    homeAddress: e.target.value,
                  });
                  updateMultipleErrorFields(
                    invalidFields,
                    setInvalidFields,
                    ["homeAddress", "invalid-address"]
                  );
                }}
              />
              <div
                className={`${
                  invalidFields.includes("homeAddress") || invalidFields.includes("invalid-address")? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                {`${invalidFields.includes("homeAddress") ? "Please enter valid Home Address" : "Please enter valid Home Address, City and Zip code"}`}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 d-block d-sm-none">
            <div className="form-group">
              <label className="form-label">Home Address *</label>
              <textarea
                rows={3}
                className="form-control"
                placeholder="Home Address"
                value={formInputs.homeAddress}
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    homeAddress: e.target.value,
                  });
                  updateMultipleErrorFields(
                    invalidFields,
                    setInvalidFields,
                    ["homeAddress", "invalid-address"]
                  );
                }}
              />
              <div
                className={`${
                  invalidFields.includes("homeAddress") || invalidFields.includes("invalid-address")? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  {`${invalidFields.includes("homeAddress") ? "Please enter valid Home Address" : "Please enter valid Home Address, City and Zip code"}`}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">City *</label>
              <input
                type="text"
                className="form-control"
                placeholder="City"
                value={formInputs.homeCity}
                onChange={(e) => {
                  let input = e.target.value;
                  if (/^[a-zA-Z ]*$/.test(input)) {
                    setFormInputs({
                      ...formInputs,
                      homeCity: input,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "homeCity"
                    );
                  }
                }}
              />
              <div
                className={`${
                  invalidFields.includes("homeCity") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter valid Home City</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Zip Code *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Zip Code"
                value={formInputs.homeZipCode}
                onChange={(e) => {
                  let input = e.target.value;
                  if (/^[0-9]+$/.test(input) && input.length <= 6) {
                    setFormInputs({
                      ...formInputs,
                      homeZipCode: input,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "homeZipCode"
                    );
                  } else if (!input.length) {
                    setFormInputs({
                      ...formInputs,
                      homeZipCode: "",
                    });
                  }
                }}
              />
              <div
                className={`${
                  invalidFields.includes("homeZipCode") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0"> Please enter valid Zip Code</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group email-group">
              <label className="form-label">Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="example@gmail.com"
                value={formInputs.email}
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    email: e.target.value,
                  });
                  updateErrorFields(invalidFields, setInvalidFields, "email");
                }}
                autoComplete="new-email"
              />
              <div className="email-icon">
                <img src={email} />
              </div>
              <div
                className={`${
                  invalidFields.includes("email") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">
                  Please enter valid Email Address
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group cstm-input">
              <label className="form-label">Cell Phone *</label>
              <PhoneInput
                countryCodeEditable={false}
                country={"us"}
                onlyCountries={["us"]}
                value={formInputs.phoneNo}
                onChange={(phone) => {
                  setFormInputs({ ...formInputs, phoneNo: phone });
                  updateMultipleErrorFields(invalidFields, setInvalidFields, [
                    "phoneNo",
                    "invalid-phoneNo",
                  ]);
                }}
              />
              <div
                className={`${
                  invalidFields.includes("phoneNo") ||
                  invalidFields.includes("invalid-phoneNo") ? "" : "d-none"
                } `}
              >
                <p className="error-msg m-0">{
                invalidFields.includes("phoneNo")
                    ? "Please enter valid Phone Number"
                    : "Contact number should not starts with zero"}
                </p>
              </div>
            </div>
          </div>
          {edit ? (
            <>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Current Password *</label>
                  <input
                    type={visibility.currentPassword ? "text" : "password"}
                    className="form-control"
                    name="currentPassword"
                    value={formInputs.currentPassword}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        currentPassword: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "currentPassword"
                      );
                    }}
                    placeholder="Enter Current Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("currentPassword")}
                  >
                    {visibility.currentPassword ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("currentPassword") ? "" : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      Please enter valid Current Password
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Choose New Password *</label>
                  <input
                    type={visibility.password ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={formInputs.password}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        password: e.target.value,
                      });
                      updateMultipleErrorFields(
                        invalidFields,
                        setInvalidFields,
                        ["password", "samePassword"]
                      );
                      if (isStrongPassword(e.target.value)) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "weak-password"
                        );
                      }
                    }}
                    onBlur={() => {
                      validPasswordErrorDisplay(
                        formInputs.password,
                        invalidFields,
                        setInvalidFields
                      );
                    }}
                    placeholder="Choose New Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("password")}
                  >
                    {visibility.password ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("password") ||
                      invalidFields.includes("weak-password") ||
                      invalidFields.includes("samePassword")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {`${
                        invalidFields.includes("password")
                          ? "Please enter valid Password"
                          : invalidFields.includes("weak-password") ? "Password must have one lowercase, one uppercase, one number, one special character, and must be 8 digits long" : "New password should be different than old password"
                      }`}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Re-type New Password *</label>
                  <input
                    type={visibility.confirmPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmPassword"
                    value={formInputs.confirmPassword}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        confirmPassword: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "confirmPassword"
                      );
                    }}
                    onBlur={() => {
                      if (formInputs.password === formInputs.confirmPassword) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "password-mismatch"
                        );
                      }
                    }}
                    placeholder="Re-type New Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("confirmPassword")}
                  >
                    {visibility.confirmPassword ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("confirmPassword") ||
                      invalidFields.includes("password-mismatch")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {" "}
                      {invalidFields.includes("confirmPassword")
                        ? "Please enter valid confirm password"
                        : "Choose Password and Re-type Password must be the same"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Choose Password *</label>
                  <input
                    type={visibility.password ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={formInputs.password}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        password: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "password"
                      );
                      if (isStrongPassword(e.target.value)) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "weak-password"
                        );
                      }
                    }}
                    onBlur={() => {
                      validPasswordErrorDisplay(
                        formInputs.password,
                        invalidFields,
                        setInvalidFields
                      );
                    }}
                    placeholder="Choose Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("password")}
                  >
                    {visibility.password ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("password") ||
                      invalidFields.includes("weak-password")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {`${
                        invalidFields.includes("password")
                          ? "Please enter valid Password"
                          : "Password must have one lowercase, one uppercase, one number, one special character, and must be 8 digits long"
                      }`}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group password-group">
                  <label className="form-label">Re-type Password *</label>
                  <input
                    type={visibility.confirmPassword ? "text" : "password"}
                    className="form-control"
                    name="confirmPassword"
                    value={formInputs.confirmPassword}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        confirmPassword: e.target.value,
                      });
                      updateErrorFields(
                        invalidFields,
                        setInvalidFields,
                        "confirmPassword"
                      );
                    }}
                    onBlur={() => {
                      if (formInputs.password === formInputs.confirmPassword) {
                        updateErrorFields(
                          invalidFields,
                          setInvalidFields,
                          "password-mismatch"
                        );
                      }
                    }}
                    placeholder="Re-type Password"
                    autoComplete="new-password"
                  />
                  <div
                    className="password-icon"
                    onClick={() => checkVisibility("confirmPassword")}
                  >
                    {visibility.confirmPassword ? (
                      <img src={closedEye} />
                    ) : (
                      <img src={openEye} />
                    )}
                  </div>
                  <div
                    className={`${
                      invalidFields.includes("confirmPassword") ||
                      invalidFields.includes("password-mismatch")
                        ? ""
                        : "d-none"
                    } `}
                  >
                    <p className="error-msg m-0">
                      {" "}
                      {invalidFields.includes("confirmPassword")
                        ? "Please enter valid confirm password"
                        : "Choose Password and Re-type Password must be the same"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              {!edit ? (
                <div className="row  mt-5 px-0 mx-0">
                  <div className="col-12 d-flex term-checkbox ">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="smsApproval"
                        value={formInputs.smsApproval}
                        onChange={(e) => {
                          setFormInputs({
                            ...formInputs,
                            smsApproval: !formInputs.smsApproval,
                          });
                          setInvalidFields(
                            invalidFields.filter((x) => x !== "smsApproval")
                          );
                        }}
                        id="smsApproval"
                      />
                      <label
                        className="form-check-label ms-3 font-16 font-style "
                        htmlFor="smsApproval"
                      >
                        I Agree to receive SMS/Text notifications and
                        commmunication from Highschoolbabysitters.com
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      invalidFields.includes("smsApproval") ? "" : "d-none"
                    }
                  >
                    <p className="error-msg m-0 ">
                      {" "}
                      Please Accept notifications and commmunication terms to
                      continue.
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </form>

        {!edit ? (
          <div className="d-flex justify-content-center mt-5">
            {/*
          // @ts-ignore */}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
              onChange={() => {
                setCaptchaVerification(true);
              }}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="d-flex justify-content-center mt-5">
          <button
            className="btn btn-theme mb-3"
            onClick={formValidation}
            disabled={!edit && !captchaVerification}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <React.Fragment>{edit ? "Update" : "Next"}</React.Fragment>
            )}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BabySitterRegistrationFrom;
