import moment from "moment";
import { createContext, ReactNode, useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import {
  IChatContextModel,
  IParticipant,
} from "../models/IChatModel";

type ChildrenType = {
  children: ReactNode;
};


const ChatContext = createContext<IChatContextModel>({
  participants: [],
  setParticipants: () => {},
  twilioToken: "",
  setTwilioToken: () => {},
  twilioClient: "",
  setTwilioClient: () => {},
  chatsLoaded: false,
  setChatsLoaded: () => {},
  updateMessagesArray: () => {},
  singleUserMessages: [],
  openUserChat: () => {},
  addNewMessageToConversation: () => {},
  setChannelArray: () => {},
  channelArray: {},
  currentChannel: {},
  currentRoomId: "",
  searchString: "",
  setSearchString: () => {},
  conversationClient: "",
  setConversationClient: () => {},
});

export const ChatProvider = ({ children }: ChildrenType) => {

  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [twilioToken, setTwilioToken] = useState<string>("");
  const [twilioClient, setTwilioClient] = useState<any>("");
  const [chatsLoaded, setChatsLoaded] = useState<boolean>(false);
  const [singleUserMessages, setSingleUserMessages] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string>(""); 
  const [conversationClient, setConversationClient] = useState<any>("");

  let participantConversations = useRef<IParticipant[]>([]);
  let channelArray = useRef<any>({});
  let currentChannel = useRef<any>();
  let currentRoomId = useRef<string>("");

  const {auth} = useAuth();

  const updateMessagesArray = (
    messages: any[],
    room: string,
  ) => {
    if (!participantConversations.current.length) {
      participantConversations.current = participants;
    }
    const participantIndex: number = participantConversations.current.findIndex(
      (w: any) => w.room === room
    );
    if (participantIndex !== -1) {
      participantConversations.current[participantIndex].messages = messages;
      participantConversations.current[participantIndex].channelJoined = true;
      if(channelArray.current[room])
      {
        for (let member of channelArray.current[room].members.values()){
          if(member.state.identity === auth.email && member.state.lastConsumedMessageIndex < (messages.length - 1))
          {
            participantConversations.current[participantIndex].unreadMessages = messages.length - (member.state.lastConsumedMessageIndex + 1);
          }
        }
      }
    }
    if(participantConversations.current.findIndex((w:any) => !w.channelJoined) === -1)
    {
      setChatsLoaded(true);
      setParticipants(participantConversations.current.sort((p1, p2) => new Date(p2.messages[p2.messages.length - 1].timestamp).getTime() - new Date(p1.messages[p1.messages.length - 1].timestamp).getTime()));
    }
  };

  const openUserChat = (room: string, arrangeOnly?: boolean) => {
    let messagesByDate: any = {};
    if(!arrangeOnly)
    {
      currentRoomId.current = room;
    }
    participants.forEach((participant: any) => {
      if(participant.room === room && participant.messages)
      {
        currentChannel.current = channelArray.current[participant.room];
        participant.messages.forEach((message: any) => {
          let dateString;
          const messageDate = moment(message.timestamp);
          const today = moment().startOf('day');
          const yesterday = moment().subtract(1, 'days').startOf('day');

          if (messageDate.isSame(today, 'd')) {
            dateString = "Today";
          } else if (messageDate.isSame(yesterday, 'd')) {
            dateString = "Yesterday";
          } else {
            dateString = (messageDate.format("MMMM DD, YYYY"));
          }
          if((searchString && message.body.toLowerCase().includes(searchString.trim().toLowerCase()) || searchString === ""))
          {
            if(messagesByDate[dateString] && messagesByDate[dateString].length)
            {
              messagesByDate[dateString].push(message);
            }
            else {
              messagesByDate[dateString] = [];
              messagesByDate[dateString].push(message);
            }
          }
        });
      }
    });
    if(room === currentRoomId.current)
    {
      setSingleUserMessages(messagesByDate);
    }
  }

  const addNewMessageToConversation = (message: any, room: string) => {
    let participantList = participants;
    let participantIndex = participantList.findIndex((w) => w.room === room);
    if(participantIndex > -1)
    {
      let currentParticipant = participantList[participantIndex];
      currentParticipant.messages.push(message.state);
      if(currentRoomId.current !== room)
      {
        currentParticipant.unreadMessages++;
      }
      setParticipants([currentParticipant].concat(participants.filter((w) => w.room !== room)));
      openUserChat(room, true);
    }
  }

  const setChannelArray = (room: string, channel: any) => {
    channelArray.current[room] = channel;
  }

  useEffect(() => {
   if(searchString)
   {
    currentRoomId.current = "";
   }
  }, [searchString]);
  

  return (
    <ChatContext.Provider
      value={{
        participants,
        setParticipants,
        twilioToken,
        setTwilioToken,
        twilioClient,
        setTwilioClient,
        chatsLoaded,
        setChatsLoaded,
        updateMessagesArray,
        singleUserMessages,
        openUserChat,
        addNewMessageToConversation,
        channelArray: channelArray.current,
        setChannelArray,
        currentChannel: currentChannel.current,
        currentRoomId: currentRoomId.current,
        searchString,
        setSearchString,
        conversationClient,
        setConversationClient
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContext;
