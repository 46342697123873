import { useEffect, useState } from "react";
import { IPreferencesModel } from "../../models/IPreferencesModel";
import { handleErrorMessage, updateErrorFields } from "../../helpers/utilities";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useParentServices from "../../hooks/web-services-hooks/useParentServices";
import AlertToasts from "../../services/notification-services/notifications";
import React from "react";

type IProps = {
  userId: string;
  handleStepChange: Function;
  edit?: boolean;
};

const PreferencesForm = ({ handleStepChange, userId, edit }: IProps) => {
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [preferenceData, setPreferenceData] = useState<IPreferencesModel>({
    minAge: 0,
    gender: "",
    needDrivingLicense: null,
    pickAndDrop: null,
    step: 2,
    userId,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const { auth } = useAuth();
  const ParentServices = useParentServices();
  let isEditFields: Boolean = false;
  let navigate = useNavigate();

  const handleSubmit = () => {
    setLoader(true);
    let preferenceFormData: any = preferenceData;

    if (edit) {
      if (isEditFields) {
        ParentServices.editPreferences(preferenceData, auth._id)
          .then((res: any) => {
            if (res.status === 200) {
              sessionStorage.setItem(
                "preferenceFormData",
                JSON.stringify(preferenceFormData)
              );
              AlertToasts.success("Preference updated successfully");
              navigate("/");
            }
          })
          .catch((e) => {
            handleErrorMessage(e);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    } else {
      ParentServices.parentStep2Registration(preferenceData)
        .then((res: any) => {
          if (res.status === 200) {
            handleStepChange(2);
            sessionStorage.setItem(
              "preferenceFormData",
              JSON.stringify(preferenceFormData)
            );
          }
        })
        .catch((e) => {
          handleErrorMessage(e);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const formValidation = () => {
    let preferenceFormFileds: string[] = [];
    let validationResult: boolean = true;
    if (preferenceData) {
      Object.keys(preferenceData).forEach((key: string) => {
        if (
          Object.hasOwn(preferenceData, key) &&
          (preferenceData[key as keyof typeof preferenceData] === "" ||
            preferenceData[key as keyof typeof preferenceData] === null ||
            preferenceData[key as keyof typeof preferenceData] === 0)
        ) {
          validationResult = false;
          preferenceFormFileds.push(key);
        }
      });
    }

    if (edit && preferenceFormFileds.includes("__v")) {
      validationResult = true;
    }
    setInvalidFields(preferenceFormFileds);

    if (validationResult) {
      handleSubmit();
    }
    else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (edit) {
      ParentServices.getPreferences(auth._id).then((res: any) => {
        if (res.status === 200) {
          setPreferenceData(res.data.data[0]);
        }
      });
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isEditFields = true;
  }, [preferenceData]);

  return (
    <>
      <div
        className={edit ? "container bg-white pb-5 px-md-5 rounded pt-5" : ""}
      >
        <div className="row text-center mt-5">
          <div className="col-12">
            <h1 className="form-title">
              {edit ? "Edit babysitter preferences" : "babysitter preferences"}
            </h1>
          </div>
          <div className="col-md-6 mx-auto">
            <p className="page-discription">
              Tell us about what type of Babysitter you want
            </p>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 mx-auto">
            <form className="row g-3" autoComplete="off">
              <div className="col-md-6 form-group">
                <label className="form-label">
                  Minimum Age of Babysitter *
                </label>
                <select
                  id="inputState"
                  name="minAge"
                  value={preferenceData.minAge}
                  onChange={(e: any) => {
                    setPreferenceData({
                      ...preferenceData,
                      minAge: parseInt(e.target.value),
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "minAge"
                    );
                  }}
                  className="form-select"
                >
                  <option value="0" selected disabled>
                    Select Option
                  </option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
                <div
                  className={invalidFields.includes("minAge") ? "" : "d-none"}
                >
                  <p className="error-msg m-0"> This field is required</p>
                </div>
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">
                  What Gender Babysitter do you prefer? *
                </label>
                <select
                  id="inputState"
                  name="gender"
                  value={preferenceData.gender}
                  onChange={(e: any) => {
                    setPreferenceData({
                      ...preferenceData,
                      gender: e.target.value,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "gender"
                    );
                  }}
                  className="form-select"
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  <option value="No Preference">No Preference</option>
                  <option value="Female">Girl</option>
                  <option value="Male">Boy</option>
                  <option value="Other">Other</option>
                </select>
                <div
                  className={invalidFields.includes("gender") ? "" : "d-none"}
                >
                  <p className="error-msg m-0"> This field is required</p>
                  <input
                    type="number"
                    hidden
                    name="step"
                    value={preferenceData.step}
                    onChange={(e: any) => {
                      setPreferenceData({
                        ...preferenceData,
                        step: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  Does Your Babysitter Need A Drivers License? *
                </label>
                <select
                  id="inputState"
                  name="needDrivingLicense"
                  value={
                    preferenceData.needDrivingLicense === null
                      ? ""
                      : preferenceData.needDrivingLicense === true
                      ? 1
                      : 0
                  }
                  onChange={(e: any) => {
                    setPreferenceData({
                      ...preferenceData,
                      needDrivingLicense: parseInt(e.target.value) > 0,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "needDrivingLicense"
                    );
                  }}
                  className="form-select"
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <div
                  className={
                    invalidFields.includes("needDrivingLicense") ? "" : "d-none"
                  }
                >
                  <p className="error-msg m-0"> This field is required</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 form-group">
                <label className="form-label">
                  Can You Pick Up/Drop Off Babysitter? *
                </label>
                <select
                  id="inputState"
                  name="pickAndDrop"
                  value={
                    preferenceData.pickAndDrop === null
                      ? ""
                      : preferenceData.pickAndDrop === true
                      ? 1
                      : 0
                  }
                  onChange={(e: any) => {
                    setPreferenceData({
                      ...preferenceData,
                      pickAndDrop: parseInt(e.target.value) > 0,
                    });
                    updateErrorFields(
                      invalidFields,
                      setInvalidFields,
                      "pickAndDrop"
                    );
                  }}
                  className="form-select"
                >
                  <option value="" selected disabled>
                    Select Option
                  </option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <div
                  className={
                    invalidFields.includes("pickAndDrop") ? "" : "d-none"
                  }
                >
                  <p className="error-msg m-0"> This field is required</p>
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-center">
              <button className="btn btn-theme my-5" onClick={formValidation}>
                {loader ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <React.Fragment>{edit ? "Update" : "Next"}</React.Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferencesForm;
