import { getMonth, getYear } from "date-fns";
import { range } from "lodash";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

type Props = {
  className: string,
  placeholderText: string,
  onChange?: Function,
  onFocus?: Function,
  selected?: any,
  minDate?: any,
  maxDate?: any,
  showMonthYearPicker?: boolean,
  showTimeSelect?: boolean,
  showTimeSelectOnly?: boolean,
  timeIntervals?: number,
  showMonthDropdown?: boolean,
  showYearDropdown?: boolean,
  dateFormat?: string,
  dropdownMode?: string
}

const ReactDatePicker = (props: Props) => {
  const [startDate, setStartDate] = useState<any>(new Date());
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    /*
      // @ts-ignore */
    <DatePicker
     {...props}
    />
  );
};
export default ReactDatePicker