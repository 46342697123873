import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();

  const isVerifiedUser = () => {
    if (auth.role === "parent" && auth.isUserVerified) {
      return true;
    } else if (
      auth.role === "sitter" &&
      auth.isBsParentVerified &&
      auth.isUserVerified
    ) {
      return true;
    }
    return false;
  };

  return auth?.token && isVerifiedUser() ? (
    <Outlet />
  ) : auth?.token && !isVerifiedUser() ? (
    <Navigate to="/pending-verification" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
