import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { StepIconProps } from "@mui/material/StepIcon";
import "../assets/css/progressbar.scss";
import { ISteps } from "../models/ISteps";
import RegistrationFrom from "../components/Parentregistration/RegistrationFrom";
import PreferencesForm from "../components/Parentregistration/PreferencesForm";
import JobInfoForm from "../components/Parentregistration/JobInfoForm";
import HowItWorks from "../components/HowItWorks";

const stepsArray: ISteps[] = [
  {
    label: "Registration",
  },
  {
    label: "Preference",
  },
  {
    label: "Post job",
  },
  {
    label: "Interested Sitters",
  },
];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#A45BB4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#A45BB4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 12,
    border: 1,
    backgroundColor: "#EFEFEF",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#EFEFEF",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#fffff",
    border: "4px solid #A45BB4",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#A45BB4",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    ></ColorlibStepIconRoot>
  );
}

const SteperProgressParent = () => {
  const [currentFormStep, setCurrentFormStep] = useState<number>(0);
  const [userId, setUserId] = useState<string>("");

  const handleStepChange = (step: number, id?: string) => {
    setCurrentFormStep(step);
    if (id) {
      setUserId(id);
    }
  };

  useEffect(() => {
    let formStep = localStorage.getItem("formStep");
    if (formStep) {
      setCurrentFormStep(parseInt(formStep));
    }

    let currentUserId = localStorage.getItem("userId");
    if (currentUserId) {
      setUserId(currentUserId);
    }
  }, []);

  const stepContent = () => {
    switch (currentFormStep) {
      case 0:
        return <RegistrationFrom handleStepChange={handleStepChange} />;
      case 1:
        return (
          <PreferencesForm
            handleStepChange={handleStepChange}
            userId={userId}
          />
        );
      case 2:
        return (
          <JobInfoForm handleStepChange={handleStepChange} userId={userId} />
        );
      case 3:
        return <HowItWorks />;
    }
  };

  // Update meta description directly
  document.title = "Do You Need a Local Babysitter Near You?";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "Use HighSchoolBabysitters.com to find local high school babysitters in your area looking for babysitting jobs."
    );
  }

  return (
    <>
      <div>
        <p className="page-title text-center text-uppercase my-5 mb-4">
          Parent Registartion
        </p>
        <div className="container bg-white pb-5 px-md-5 rounded steper-top">
          <div className="customize-progress">
            <Stepper
              activeStep={currentFormStep}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {stepsArray.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {step.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {stepContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SteperProgressParent;
