import React, { useEffect } from "react";
import "../components/home.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Profile from "../views/Profile";
import home_img from "../assets/images/Mapview.gif";
import home_girl from "../assets/images/home_img.svg";
import home_girl_mobile from "../assets/images/home_img_mobile.svg";
import coloradoparent from "../assets/images/coloradoparent.svg";
import coloradoparent_mobile from "../assets/images/coloradoparent_mobile.svg";
import yahoo from "../assets/images/yahoo.svg";
import yahoo_mobile from "../assets/images/yahoo_mobile.svg";
import Nbc from "../assets/images/nbc.svg";
import Nbc_mobile from "../assets/images/nbc_mobile.svg";
import local from "../assets/images/locationbabysitter.png";
import money from "../assets/images/moneybabysitter.png";
import protection from "../assets/images/rightbabysitter.png";
import schedual from "../assets/images/babysittercalender.png";
import invertedcomma from "../assets/images/invertedcomma.svg";
import invertedcomma_mobile from "../assets/images/invertedcomma_mobile.svg";
import childrenplaying from "../assets/images/childrenplaying.svg";
import childrenplaying_mobile from "../assets/images/childrenplaying_mobile.svg";
import bank from "../assets/images/5Bank.svg";
import yahoofinance from "../assets/images/yahoofinance.svg";
import twochild from "../assets/images/twochild.svg";
import twochild_mobile from "../assets/images/twochild_mobile.svg";
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  const isVerifiedUser = () => {
    if (auth.role === "parent" && auth.isUserVerified) {
      return true;
    } else if (
      auth.role === "sitter" &&
      auth.isBsParentVerified &&
      auth.isUserVerified
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    document.title = "Find a Great Babysitter HighSchoolBabysitters.com";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "HighSchoolBabysitters.com connects hardworking, trustworthy teens with parents in need of local babysitters."
      );
    }

    const link = document.createElement("link");
    link.rel = "canonical";
    link.href = "https://highschoolbabysitters.com/home";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <React.Fragment>
      {auth && auth.token && isVerifiedUser() ? (
        <Profile />
      ) : auth && auth.token && !isVerifiedUser() ? (
        <Navigate
          to="/pending-verification"
          state={{ from: location }}
          replace
        />
      ) : (
        <div className="container-fluid mx-0 my-sm-0 my-3 sec-one-title pb-sm-5 pb-1 home-bg">
          <div className="row px-0 mx-0">
            <div className="col-6 mt-5 d-none d-md-block">
              <div className="home-title mb-4">
                <h1>
                  Connect with <span>thousands</span> of high school babysitters
                  across the Nation
                </h1>
              </div>
              <div>
                <button
                  className="btn btn-theme-outline text-sm-center text-start my-1 me-3"
                  onClick={() => {
                    navigate("parent-register");
                  }}
                >
                  Find a babysitter
                </button>

                <button
                  className="btn btn-theme text-sm-center text-start my-1"
                  type="submit"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Register to be a babysitter
                </button>
              </div>
              <div className="col-sm-6 position-relative d-block">
                <div className="ads">
                  <p className="pt-5 as-seen-in pb-sm-0 pb-3">AS SEEN IN:</p>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <img
                      src={coloradoparent}
                      alt="Colorado Parent"
                      className="d-none d-md-block me-5"
                    ></img> */}
                    <a href="https://www.coloradoparent.com/find-reliable-babysitters-with-highschoolbabysitters-com/" target="_blank" className="article-link">
  <img
    src={coloradoparent}
    alt="Colorado Parent"
    className="d-none d-md-block me-5"
  />
</a>
                   <a href="https://www.coloradoparent.com/find-reliable-babysitters-with-highschoolbabysitters-com/" target="_blank" className="article-link">
                    <img
                      src={coloradoparent_mobile}
                      alt="Colorado Parent"
                      className="coloradoparent-image-phone d-block  d-md-none"
                    ></img>
                    </a>
                    <a href="https://finance.yahoo.com/news/highschoolbabysitters-com-colorado-startup-bringing-074400324.html?guccounter=1/" target="_blank" className="article-link">
                    <img
                      src={yahoo}
                      alt="Yahoo Finance"
                      className="yahoo-image-phone d-none d-md-block me-5"
                    ></img>
                      </a>
                      <a href="https://finance.yahoo.com/news/highschoolbabysitters-com-colorado-startup-bringing-074400324.html?guccounter=1/" target="_blank" className="article-link">
                      <img
                      src={yahoo_mobile}
                      alt="Yahoo Finance"
                      className="yahoo-image-phone d-block d-md-none"
                    ></img>
                      </a>
                      <a href="https://www.koaa.com/news/covering-colorado/high-school-ceo-passes-legislation-to-support-colorados-young-entrepreneurs/" target="_blank" className="article-link">
                      <img
                      src={Nbc}
                      alt="NBC"
                      className="nbc-image-phone d-none mb-3 d-md-block"
                    ></img>
                      </a>
                      <a href="https://www.koaa.com/news/covering-colorado/high-school-ceo-passes-legislation-to-support-colorados-young-entrepreneurs/" target="_blank" className="article-link">
                      <img
                      src={Nbc_mobile}
                      alt="NBC"
                      className="nbc-image-phone d-block d-md-none"
                    ></img>
                      </a>
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 home-rel pe-0 me-0 d-none d-md-block">
              <div className="home-arrow">
                <img src={home_girl} className="d-block" alt="Home Arrow" />
                <div className="babysitter-number">
                  <h4 className="number-text m-0">3,000+</h4>
                  <p className="babysitter-across-usa">
                    Babysitters <br /> Across the USA
                  </p>
                </div>
              </div>
              <div className="sec-two-img">
                <img src={home_img} className="girl-img" alt="Girl" />
              </div>
            </div>

            {/* Mobile Devices  */}
            <div className="second-section">
              <div className="container-fluid">
                <div className="col-12  mt-3 ps-4 d-block d-md-none">
                  <div className="home-title mb-4">
                    <h1>
                      Connect with <span>thousands</span> of high school
                      babysitters across the Nation
                    </h1>
                  </div>
                </div>

                <div className="col-12 mt-lg-0 mt-2 mt-0 home-rel pe-0 me-0 d-block d-md-none">
                  <div className="home-arrow ">
                    <img
                      src={home_girl_mobile}
                      className="d-block home-arrow-img"
                      alt="Home Arrow"
                    />
                    <div className="babysitter-number">
                      <h4 className="number-text m-0">3,000+</h4>
                      <p className="babysitter-across-usa">
                        Babysitters <br /> Across the USA
                      </p>
                    </div>
                  </div>
                  <div className="sec-two-img d-flex justify-content-center ">
                    <img src={home_img} className="girl-img" alt="Girl" />
                  </div>
                </div>

                <div className="col-12 ps-3 d-block d-md-none">
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-theme text-sm-center text-start mb-2 mt-1 home-btn1"
                      type="submit"
                      onClick={() => {
                        navigate("/register");
                      }}
                    >
                      Register to be a babysitter
                    </button>
                    <button
                      className="btn btn-theme-outline text-sm-center find-a-babysitter text-start my-1 home-btn2"
                      onClick={() => {
                        navigate("parent-register");
                      }}
                    >
                      Find a babysitter
                    </button>
                  </div>
                </div>

                <div className="col-12 ps-3 d-block d-md-none">
                  <div className="ads">
                    <p className="pt-sm-4 pt-5 as-seen-in pb-sm-0 pb-3">
                      AS SEEN IN:
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                    <a href="https://www.coloradoparent.com/find-reliable-babysitters-with-highschoolbabysitters-com/" target="_blank" className="article-link">
                    <img
                        src={coloradoparent}
                        alt="Colorado Parent"
                        className="coloradoparent-image-phone"
                      ></img>
                      </a>
                      <a href="https://finance.yahoo.com/news/highschoolbabysitters-com-colorado-startup-bringing-074400324.html?guccounter=1/" target="_blank" className="article-link">
                      <img
                        src={yahoo}
                        alt="Yahoo Finance"
                        className="yahoo-image-phone"
                      ></img>
                      </a>
                      <a href="https://www.koaa.com/news/covering-colorado/high-school-ceo-passes-legislation-to-support-colorados-young-entrepreneurs/" target="_blank" className="article-link">
                      <img
                        src={Nbc}
                        alt="NBC"
                        className="nbc-image-phone"
                      ></img>
                      </a>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="second-section">
        <div className="container-fluid px-sm-0 px-2 mt-5">
          <div className="header secondsection-header">
            <h2 className="pb-3">
              Trusted Babysitters From Your Local High School
            </h2>
            <p className="card-description pb-sm-3">
              We connect families with local high school babysitters who are
              ready to help with childcare. Here’s why parents love our service:
            </p>
          </div>
          
          <div className="row mx-sm-0 mx-2">
            <div className="col-xl-3 col-md-6 col-12">
              <div className="grid-card">
                <h4 className="home-card mb-3 mt-2">Local & Familiar</h4>
                <img src={local} alt="Local"></img>
                <p className="card-description mt-3">
                  Babysitters come from your community, so you know exactly
                  who’s caring for your kids.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="grid-card">
                <h4 className="home-card mb-3 mt-2">Affordable Childcare</h4>
                <img src={money}></img>
                <p className="card-description mt-3">
                  High school students provide budget-friendly, quality
                  childcare services.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="grid-card">
                <h4 className="home-card mb-3 mt-2">Parental Oversight</h4>
                <img src={protection}></img>
                <p className="card-description mt-3">
                  Parents approve and monitor all communications, ensuring
                  transparency and safety.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="grid-card">
                <h4 className="home-card mb-3 mt-2">Flexible Schedules</h4>
                <img src={schedual}></img>
                <p className="card-description mt-3">
                  Easily find sitters for after-school care, weekends, or
                  last-minute needs.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-1">
            <button
              className="btn btn-theme see_how_its_work my-4"
              type="submit"
              onClick={()=>{
                navigate('/work')
              }}
            >
              See How It Works
            </button>
          </div>
        </div>
      </div>
      <div className="second-section">
        {/* <div className="container-fluid mt-4 d-none d-md-block">
          <div className="row  d-flex justify-content-between mt-2">
            <div className="col-md-6 col-12 parent-description-padding">
              <img src={invertedcomma} />
              <p className="other-card-description">
                As a parent, I can monitor all my daughter’s communication with
                families. It gives me peace of mind knowing I’m involved every
                step of the way.
              </p>
              <p className="little-description">
                &ndash; Sarah P., Parent of Babysitter
              </p>
            </div>
            <div className="col-md-6 col-12 mt-5 parent-image-padding">
              <img
                className="image-border-radius"
                src={childrenplaying}

              />
            </div>
          </div>
          <div className="row d-flex justify-content-between my-3">
            <div className="col-sm-4 col-md-6 mt-5 parent-description-padding">
              <img
                src={twochild}
                className="image-border-radius"

              />
            </div>
            <div className="col-sm-5 col-md-6 parent-image-padding">
              <img src={invertedcomma} />
              <p className="other-card-description">
                I found a trustworthy sitter from a local high school, and the
                process was so simple. The platform made it easy to find exactly
                what I needed.
              </p>
              <p className="little-description">&ndash; Emily S., Mother of 3</p>
            </div>
          </div>
        </div> */}
        <div className="container-fluid d-none d-md-block">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-5 col-md-6 my-auto">
              <img src={invertedcomma} />
              <p className="other-card-description">
                As a parent, I can monitor all my daughter’s communication with
                families. It gives me peace of mind knowing I’m involved every
                step of the way.
              </p>
              <p className="little-description">
                &ndash; Sarah P., Parent of Babysitter
              </p>
            </div>
            <div className="col-sm-6 mt-5">
              <img
                className="image-border-radius padding-image-card"
                src={childrenplaying}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-between pb-5 mt-sm-1 mb-sm-5 my-3">
            <div className="col-sm-5 mt-5">
              <img src={twochild} className="image-border-radius" />
            </div>
            <div className="col-sm-6 my-auto set-padding-card-col">
              <img src={invertedcomma} />
              <p className="other-card-description">
                I found a trustworthy sitter from a local high school, and the
                process was so simple. The platform made it easy to find exactly
                what I needed.
              </p>
              <p className="little-description">
                &ndash; Emily S., Mother of 3
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* mobile device */}
      <div className="container d-block d-md-none">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5 px-sm-0 px-3 col-md-12">
            <img src={invertedcomma_mobile} />
            <p className="other-card-description mt-2">
              As a parent, I can monitor all my daughter’s communication with
              families. It gives me peace of mind knowing I’m involved every
              step of the way.
            </p>
            <p className="little-description">
              &ndash; Sarah P., Parent of Babysitter
            </p>
          </div>
          <div className="col-lg-5 col-md-12 d-flex justify-content-center">
            <img
              className="image-border-radius "
              src={childrenplaying_mobile}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-between my-sm-5 my-3">
          <div className="col-lg-5 px-sm-0 px-4 col-md-12">
            <img src={invertedcomma_mobile} />
            <p className="other-card-description">
              I found a trustworthy sitter from a local high school, and the
              process was so simple. The platform made it easy to find exactly
              what I needed.
            </p>
            <p className="little-description">&ndash; Emily S., Mother of 3</p>
          </div>
          <div className="col-lg-4 col-md-12  second-portion-text d-flex justify-content-center">
            <img src={twochild_mobile} className="image-border-radius" />
          </div>
        </div>
      </div>

      <div className="m-0 p-0 bg-white">
        <div className="article-section">
          <div className="second-section">
            <div className="container-fluid m-0 p-0">
              <div className="article-title py-3">In The Press</div>

              {/* <div className="row mx-sm-0 mx-2">

                <div className="col-lg-4 col-md-6 mb-4 d-flex">
                  <div className="article-card pt-xl-5 pt-sm-2 pt-2">
                    <img
                      src={coloradoparent}

                      className="read-the-article-image"
                    />
                    <div className="article-content">Find Reliable Babysitters With HighschoolBabysitters.Com</div>
                    <a href="#" className="article-link">READ THE ARTICLE &gt;</a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4 d-flex">
                  <div className="article-card pt-5">
                    <img
                      src={bank}

                      className="read-the-article-image mb-3"
                    />
                    <div className="article-content">High School CEO Passes Legislation To Support Colorado's Young Entrepreneurs</div>
                    <a href="#" className="article-link">READ THE ARTICLE &gt;</a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4 d-flex">
                  <div className="article-card pt-5">
                    <img
                      src={yahoofinance}

                      className="read-the-article-image mb-3"
                    />
                    <div className="article-content">HighSchoolBabysitters.Com - A Colorado Startup Bringing Over 17 Million High Schoolers Into The Online Babysitting Market</div>
                    <a href="#" className="article-link">READ THE ARTICLE &gt;</a>
                  </div>
                </div>
              </div> */}

              <div className="container">
                <div className="row justify-content-center pb-sm-5 pb-3 align-items-stretch">
                  {/* Card 1 */}
                  <div className="col-lg-4 col-md-6 mb-4 In-the-press-card-phone">
                    <div className="card p-4 w-100">
                      <img
                        src={coloradoparent}
                        className="read-the-article-image"
                      />{" "}
                      <p className="card-text article-content">
                        Find Reliable Babysitters With HighschoolBabysitters.Com
                      </p>
                      <a href="https://www.coloradoparent.com/find-reliable-babysitters-with-highschoolbabysitters-com/" target="_blank" className="article-link">
                        Read the article &gt;
                      </a>
                    </div>
                  </div>
                  {/* Card 2 */}
                  <div className="col-lg-4 col-md-6 mb-4 In-the-press-card-phone">
                    <div className="card p-4 w-100">
                      <img src={bank} className="read-the-article-image mb-3" />{" "}
                      <p className="card-text article-content-second">
                        High School CEO Passes Legislation To Support Colorado's
                        Young Entrepreneurs
                      </p>
                      <a href="https://www.koaa.com/news/covering-colorado/high-school-ceo-passes-legislation-to-support-colorados-young-entrepreneurs/" target="_blank" className="article-link">
                        Read the article &gt;
                      </a>
                    </div>
                  </div>
                  {/* Card 3 */}
                  <div className="col-lg-4 col-md-6 mb-4 In-the-press-card-phone">
                    <div className="card p-4 w-100">
                      <img
                        src={yahoofinance}
                        className="read-the-article-image mb-3"
                      />{" "}
                      <p className="card-text article-content-last">
                        HighSchoolBabysitters.Com - A Colorado Startup Bringing
                        Over 17 Million High Schoolers Into The Online
                        Babysitting Market
                      </p>
                      <a href="https://finance.yahoo.com/news/highschoolbabysitters-com-colorado-startup-bringing-074400324.html?guccounter=1/" target="_blank" className="article-link">
                        Read the article &gt;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="second-section">
        <div className="container-fluid d-flex justify-content-center p-sm-5 p-4">
          <div>
            <h1 className="find-babysitter-section-text">
              Find Your Babysitter Today!
            </h1>
            <p className="find-babysitter-section-description fw-400 mx-sm-0 mx-4">
              With HighSchoolBabysitters.com, finding trusted, local care is
              easy and safe.
              <br />
              Start your search today and find the right match for your family!
            </p>
            <div className="d-sm-flex justify-content-center pt-0 pt-sm-3 text-center last-btn">
              <button className="btn btn-theme-outline fw-600 text-sm-center text-start mb-sm-0 mb-3  me-sm-3"  onClick={() => {
                    navigate("parent-register");
                  }}>
                Find a babysitter
              </button>
              <button className="btn btn-theme  fw-600 " type="submit" onClick={() => {
                    navigate("/register");
                  }}>
                Register to be a babysitter
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
