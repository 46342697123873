import { svcconfig } from "../../helpers/api-services";
import useAuth from "../useAuth";
import useAxiosPrivate from "../useAxiosPrivate";

const PAYMENT_URL = process.env.REACT_APP_API_URL + "/payments";

const usePaymentServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const checkOut = (data: any) => {
    let apiURL = PAYMENT_URL;
    switch (data.paymentType) {
      case "oneTime":
        apiURL = apiURL.concat(`/onetimepayment/${auth._id}`);
        break;

      case "monthly":
        apiURL = apiURL.concat(`/monthlysubscription/${auth._id}`);
        break;

      case "yearly":
        apiURL = apiURL.concat(`/yearlysubscription/${auth._id}`);
        break;

      default:
        throw("Invaild Payment Method")
        break;
    }
    return axiosPrivate.post(apiURL, data, { headers: svcconfig(auth.token) });
  };

  const updatePlan = (updatePlan: string) => {
    let apiURL = PAYMENT_URL;
    switch (updatePlan) {
      case "Monthly":
        apiURL = apiURL.concat(`/updateyearlysubscription/${auth._id}`);
        break;

      case "Yearly":
        apiURL = apiURL.concat(`/updatemonthlysubscription/${auth._id}`);
        break;

      default:
        break;
    }
    return axiosPrivate.patch(apiURL, {}, { headers: svcconfig(auth.token) });
  };

  const currentPlan = (id: string) => {
    const apiURL = `${PAYMENT_URL}/getcurrentplan/${id}`;
    return axiosPrivate.get(apiURL, { headers: svcconfig(auth.token) });
  };

  const cancelSubscription = () => {
    const apiURL = `${PAYMENT_URL}/cancelsubscription/${auth._id}`;
    return axiosPrivate.post(apiURL, {}, { headers: svcconfig(auth.token) });
  };

  const paymentMethods = {
    checkOut,
    currentPlan,
    updatePlan,
    cancelSubscription
  };

  return paymentMethods;
};

export default usePaymentServices;
