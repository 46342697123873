import { useNavigate } from "react-router-dom";
//import logo from "../../src/assets/images/logo.svg";
import logo from "../../src/assets/images/Babysitters-logo.svg";
import { Nav, NavDropdown, NavLink, Navbar } from "react-bootstrap";
import useAuth from "../hooks/useAuth";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";
import { useEffect } from "react";
import useBabySitterServices from "../hooks/web-services-hooks/useBabySitterServices";
import { encryptData } from "../helpers/utilities";
import React from "react";

const Header = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const authServices = useAuthServices();
  const BabysitterServices = useBabySitterServices();
  const handleLogOut = async () => {
    try {
      await authServices.userLogOut();
    } catch (e) {
      console.error(e);
    } finally {
      setAuth({});
      navigate("/login");
    }
  };

  useEffect(() => {
    if (auth.token) {
      BabysitterServices.getBabySitterAboutInfo(auth._id)
        .then((res) => {
          localStorage.setItem(
            "profileImage",
            encryptData(res.data.data.profilePicture)
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [auth.token]);

  return (
    <div className="row mx-0 px-0 bg-white">
      <Navbar
        collapseOnSelect
        expand="lg"
        className="col-12 px-lg-0 px-sm-3 d-lg-flex navbar-light"
      >
        <div className="col-md-3 col-auto  px-0 text-center">
          <img
            src={logo}
            alt="brand-logo"
            className="web-logo"
            onClick={() => navigate("/")}
          />
          {!auth.token ? (
            <></>
          ) : (
            <div
              className="d-lg-none d-block cursor-pointer"
              onClick={() => navigate("/")}
            >
              <div className="user-name header-edit-username">
                <h1 className="m-0 pt-1 ">{`${auth.firstName[0]}`}</h1>
              </div>
            </div>
          )}
        </div>
        {/* <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="navbar-nav col-lg-8 d-lg-flex justify-content-end px-0 ">
            <li className="nav-item d-flex justify-content-center">
              <Nav.Link
                href="#"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Nav.Link>
            </li>
            <li className="nav-item d-flex justify-content-center">
              <Nav.Link
                href="#"
                onClick={() => {
                  navigate("/about");
                }}
              >
                About Us
              </Nav.Link>
            </li>
            {/* {
              !auth.token ?
              <React.Fragment>

              </React.Fragment>
              : */}
            <li className="nav-item d-flex justify-content-center">
              <Nav.Link
                href="#"
                onClick={() => {
                  navigate("/work");
                }}
              >
                How it Works
              </Nav.Link>
            </li>
            {/* } */}
          </ul>
          {!auth.token ? (
            <div className="col-lg-4 col-12 nav-btn-section d-flex justify-content-center  ">
              <button
                type="submit"
                onClick={() => {
                  navigate("/login");
                }}
                className="btn sign-in-btn my-2 my-sm-0 mx-lg-2 mx-3"
              >
                <Nav.Link href="#"> Sign In</Nav.Link>
              </button>
              <div className="dropdown">
                <button
                  className="btn btn-register my-2 my-sm-0 mx-lg-2 mx-3 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sign Up
                </button>
                <ul className="dropdown-menu">
                  <div className="dropdown-menu-arrow"></div>
                  <li>
                    <Nav.Link
                      href="#"
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/register");
                      }}
                    >
                      Babysitter Registration
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link
                      href="#"
                      className="dropdown-item"
                      onClick={() => {
                        navigate("parent-register");
                      }}
                    >
                      Parent Registration
                    </Nav.Link>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div className="col-lg-4 nav-btn-section d-lg-flex justify-content-center align-item-center">
                <div
                  className="user-name me-2 d-lg-block d-none cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <h1 className="m-0 font-32">{`${auth.firstName[0]}`}</h1>
                </div>
                <div className="d-flex justify-content-center d-lg-block d-none">
                  <button
                    className="btn btn-register my-2 my-sm-0 mx-lg-2 mx-0"
                    type="submit"
                    onClick={handleLogOut}
                  >
                    Log out
                  </button>
                </div>
                <div className="d-flex justify-content-center d-lg-none d-block">
                  <button
                    className="btn btn-register my-2 my-sm-0 mx-lg-2 mx-0"
                    type="submit"
                    onClick={handleLogOut}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </>
          )}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
