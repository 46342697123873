import React, { useEffect, useState } from "react";
import logo from "../../src/assets/images/logo.svg";
import openEye from "../assets/images/open-eye.svg";
import closedEye from "../assets/images/closed-eye.svg";
import { NavLink } from "react-bootstrap";
import "../assets/css/auth.scss";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import {
  decryptData,
  encryptData,
  handleErrorMessage,
} from "../helpers/utilities";
import useAuthServices from "../hooks/web-services-hooks/useAuthServices";

const Login = () => {
  type Credentials = {
    email: string;
    password: string;
  };

  const { setAuth, persist, setPersist } = useAuth();
  const authServices = useAuthServices();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [authState, setAuthState] = useState<Credentials>({
    email: "",
    password: "",
  });
  const [validation, setValidation] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const savedCredentials: string = decryptData(
      localStorage.getItem("_credentials") || ""
    );
    const isSaved = localStorage.getItem("persist");
    if (isSaved && savedCredentials) {
      setAuthState(JSON.parse(savedCredentials));
      setPersist(true);
    }
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    if (authState.email.trim().length && authState.password.trim().length) {
      authServices
        .userLogin({ ...authState })
        .then((res) => {
          setAuth(res.data);
          localStorage.setItem(
            "_credentials",
            encryptData(JSON.stringify(authState))
          );
          setAuthState({ email: "", password: "" });
          navigate(from, { replace: true });
        })
        .catch((e) => {
          console.error(e);
          handleErrorMessage(e);
          setValidation("");
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (!authState.email.trim().length && !authState.password.length) {
      setValidation("Please enter valid email address & password");
      setLoader(false);
    } else if (!authState.email.trim().length) {
      setValidation("Please enter valid email address");
      setLoader(false);
    } else {
      setValidation("Please enter password");
      setLoader(false);
    }
  };

  // Update meta description directly
  document.title = "Sign In to HighSchoolBabysitters.com";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "Parents and local high school aged babysitters can log into their HighSchoolBabysitters.com account here."
    );
  }

   // Create and append the canonical link
   const link = document.createElement("link");
   link.rel = "canonical";
   link.href = "https://highschoolbabysitters.com/login";
   document.head.appendChild(link);

  return (
    <div className="row mx-auto px-0 py-4 d-flex justify-content-center boxes">
      <div className="col-sm-12 col-11 login-box px-sm-5 px-3 mt-4 box-bg h-auto w-auto">
        <div className="logo">
          <img className=" d-none d-md-block" src={logo} alt="Brand-logo" />
        </div>

        <h1>Welcome back</h1>
        {validation ? (
          <p className="error-msg text-center"> {validation}</p>
        ) : (
          <p className="subtitle">Please enter your details.</p>
        )}
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter your email address"
              value={authState.email}
              onChange={(e) =>
                setAuthState({ ...authState, email: e.target.value })
              }
            />
          </div>
          <div className="form-group password-group">
            <input
              type={visible ? "text" : "password"}
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              value={authState.password}
              onChange={(e) =>
                setAuthState({ ...authState, password: e.target.value })
              }
            />
            <div
              className="login-password-icon"
              onClick={() => setVisible(!visible)}
            >
              {visible ? <img src={closedEye} /> : <img src={openEye} />}
            </div>
          </div>

          <div className="col-12 d-flex forgot-line">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                value="Remember me"
                checked={persist}
                onChange={(e) => {
                  setPersist(e.target.checked);
                  if (e.target.checked) {
                    localStorage.setItem("persist", "true");
                  } else {
                    localStorage.removeItem("persist");
                    localStorage.removeItem("_credentials");
                  }
                }}
              />
              <label
                className="form-check-label px-1"
                htmlFor="inlineCheckbox2"
              >
                Remember me
              </label>
            </div>
            <NavLink
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot password
            </NavLink>
          </div>
          <button type="submit" className="btn btnlogin">
            {loader ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Login"
            )}
          </button>
          <p className="d-flex signup-link">
            Don’t have an account?
            <NavLink
              onClick={() => {
                navigate("/");
              }}
            >
              Sign up
            </NavLink>
          </p>
          <div className="d-flex justify-content-end">
            <span className="text-align-center version-text">
              V. {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
